import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { icons } from '@app/_core/icons';
@Injectable()
export class IconService {
  constructor(
		private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {}

  registerIcons() {
    /**
     * Loop over all the custom icon names and add them to the registry
     */
    icons?.forEach(({name, fileName}) => {
      this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/img/icons/${fileName}`));
    });
  }
}
