<div id="certificate" #certificate>
  <h2>{{ 'frontpage.dashboard_tabs_personal_label' | translate }}</h2>
  <table>
    <tr>
      <td>{{ 'certificates.first_name' | translate }}:</td>
      <td>{{ documents.certificate.content.graduate.firstName }}
        <span class="current" *ngIf="isNewFirstName">
          ({{ 'certificates.current_registry' | translate }}
          {{ documents.certificate.content.currentOwnerData.firstName }})</span>
        </td>
    </tr>
    <tr>
      <td>{{ 'certificates.last_name' | translate }}:</td>
      <td>{{ documents.certificate.content.graduate.lastName }}
        <span class="current" *ngIf="isNewLastName">
          ({{ 'certificates.current_registry' | translate }}
          {{ documents.certificate.content.currentOwnerData.lastName }})</span>
      </td>
    </tr>
    <tr>
      <td>{{ 'finaldocument.idcode' | translate }}:</td>
      <td>{{ documents.certificate.content.graduate.idCode | idCode }}
        <span class="current" *ngIf="isNewIDCode">
          ({{ 'certificates.current_registry' | translate }}
          {{ documents.certificate.content.currentOwnerData.idCode | idCode }})</span>
      </td>
    </tr>
  </table>
  <h2>{{ 'finaldocuments.data' | translate }}</h2>
  <table>
    <tr>
      <td>{{ 'finaldocuments.number' | translate }}:</td>
      <td>{{ documents.certificate.content.registrationNumber }}</td>
    </tr>
    <tr>
      <td>{{ 'finaldocuments.date_of' | translate }}:</td>
      <td>{{ issueDate }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.duplicateCertificateNumber">
      <td>{{ 'finaldocuments.duplicate_number' | translate }}:</td>
      <td>{{ documents.certificate.content.duplicateCertificateNumber }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.duplicateIssueDate">
      <td>{{ 'finaldocuments.date_of_duplicate' | translate }}:</td>
      <td>{{ documents.certificate.content.duplicateIssueDate }}</td>
    </tr>
  </table>
  <ng-container *ngIf="documents.certificate.content.educationalInstitutions?.length">
    <h2>{{ 'finaldocuments.institution_data' | translate }}</h2>
    <table>
      <tr>
        <td>{{ 'finaldocuments.institution_name' | translate }}:</td>
        <td>{{ eduInst }}</td>
      </tr>
    </table>
  </ng-container>
  <h2>{{ 'finaldocuments.curriculum_data' | translate }}</h2>
  <table>
    <tr>
      <td>{{ 'finaldocuments.curriculum_name' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.curriculumName }}</td>
    </tr>
    <tr>
      <td>{{ 'finaldocuments.curriculum_code' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.curriculumCode }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.academicDegree">
      <td>{{ 'finaldocuments.degree_name' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.academicDegree }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.levelOfEducation">
      <td>{{ 'finaldocuments.level_of_education' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.levelOfEducation }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.accreditationDecision">
      <td>{{ 'finaldocuments.accreditation_decision' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.accreditationDecision }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.accreditationEndDate">
      <td>{{ 'finaldocuments.accreditation_end_date' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.accreditationEndDate }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.rightToProvideEducationDecision">
      <td>{{ 'finaldocuments.right_to_provide_education_decision' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.rightToProvideEducationDecision }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.rightToProvideEducationEndDate">
      <td>{{ 'finaldocuments.right_to_provide_education_end_date' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.rightToProvideEducationEndDate }}</td>
    </tr>
    <tr *ngIf="documents.certificate.content.studies.typeOfTraining">
      <td>{{ 'finaldocuments.type_of_training' | translate }}:</td>
      <td>{{ documents.certificate.content.studies.typeOfTraining }}</td>
    </tr>
    <tr *ngIf="documents.transcript?.content?.studies?.creditPoint">
      <td>{{ 'finaldocuments.credit_point' | translate }}:</td>
      <td>{{ documents.transcript.content.studies.creditPoint }}</td>
    </tr>
    <tr *ngIf="documents.transcript?.content?.studies?.officialLengthOfProgramme">
      <td>{{ 'finaldocuments.length_of_programme' | translate }}:</td>
      <td>{{ documents.transcript.content.studies.officialLengthOfProgramme }}</td>
    </tr>
    <tr *ngIf="documents.supplement?.content?.studies?.creditPoint">
      <td>{{ 'finaldocuments.credit_point' | translate }}:</td>
      <td>{{ documents.supplement.content.studies.creditPoint }}</td>
    </tr>
    <tr *ngIf="documents.supplement?.content?.studies?.officialLengthOfProgramme">
      <td>{{ 'finaldocuments.length_of_programme' | translate }}:</td>
      <td>{{ documents.supplement.content.studies.officialLengthOfProgramme }}</td>
    </tr>
    <tr *ngIf="documents.certificate?.content?.qualificationWithinCurrentFramework">
      <td>{{ 'finaldocuments.qualification_within_framework' | translate }}:</td>
      <td>{{ documents.certificate.content.qualificationWithinCurrentFramework }}</td>
    </tr>
    <tr *ngIf="documents.supplement?.content?.levelOfQualification?.europeanQualificationFrameworkLevel">
      <td>{{ 'finaldocuments.european_qualification_level' | translate }}:</td>
      <td>{{ documents.supplement.content.levelOfQualification.europeanQualificationFrameworkLevel }}</td>
    </tr>
  </table>
</div>
