<ul #content *ngIf="list && list.length > 0">
	<ng-container *ngFor="let item of list; let i = index; let last = last">
		<li
			class="list__item {{ addonClass }}"
			[ngClass]="{
            'list__item--highlighted': item.highlighted,
            'overflow-hidden': type === 'news'
        }"
		>
			<div class="list__item__wrapper">
				<div class="list__item__titlebox">
					<div [class]="type === 'news' ? 'col-8 xs-hide' : 'col-grow'">
						<div class="list__item__head" *ngIf="item.head">
							{{ returnEntityString(item.head) }}
						</div>
						<div class="flex flex-center flex-wrap list__item__titleWrapper">
							<a
								[routerLink]="item?.url?.path || item.url || item.path"
								attr.id="listItem-{{ i }}"
								class="list__item__title"
								[attr.data-test-id]="'list-item-link-' + ((item?.url?.path || item?.url || item?.path) | slugify)"
							>
								<h2>{{ item.title }}</h2>
							</a>
						</div>
						<div
							class="author"
							*ngIf="(item.author || item.created) && type === 'news'"
							[attr.aria-current]="isCurrentDate(item.created * 1000) ? 'date' : undefined"
						>
							{{ item.author || item.fieldAuthor }} {{ item.created*1000 |
							date:"dd.MM.yyyy" }}
						</div>
						<div class="tags" *ngIf="item.tags">
							<labels [data]="item.tags" type="default"></labels>
						</div>
						<div class="description" *ngIf="item.shortDescription">
							{{ item.shortDescription | ellipsis:320 }}
						</div>

						<labels
							*ngIf="item.fixedLabel?.length"
							[data]="item.fixedLabel"
							type="orange"
						>
						</labels>
					</div>
					<div *ngIf="compare" class="col--compare sm-hide">
						<compare [id]="item.nid" [sessionStorageKey]="compare"></compare>
					</div>

					<div
						class="col-4 xs-12 pointer list__item__image"
						*ngIf="item.image && !item.video"
					>
						<ng-container *ngIf="(item.image | arrayFirst) as Image">
							<img
								[class.lastImg]="last"
								src="{{ Image.derivative?.url || Image.url }}"
								alt=""
								width="100%"
								height="100%"
								[routerLink]="item?.url?.path || item.url || item.path"
							/>
						</ng-container>
					</div>

					<ng-container *ngIf="item.video && item.video.videoId">
						<button
							*ngIf="!clickedVideos[i]"
							class="col-4 xs-12 pointer list-item__video-button"
							(click)="clickedVideos[i] = true"
							attr.aria-label="{{ 'generic.youtube_video' | translate }}: {{ item.video.videoDescription }}"
						>
							<div class="list__item__video__thumbnail">
								<img
									*ngIf="item.video.videoThumbnail"
									[class.lastImg]="last"
									src="{{ item.video.videoThumbnail }}"
									width="100%"
									alt=""
								/>
								<i
									class="icon youtube-play"
								></i>
								<div class="triangle" aria-hidden="true"></div>
							</div>
						</button>

						<div *ngIf="clickedVideos[i]" class="col-4 xs-12 list__item__video">
							<div class="aspect">
								<iframe
									attr.aria-label="{{ 'generic.youtube_video' | translate }}"
									[src]="item.video.videoEmbed +'?autoplay=1&mute=1' | iframe "
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>
					</ng-container>
				</div>

				<ng-container *ngIf="type === 'news'">
					<div class="xs-show col-12 resp-fields">
						<a
							[routerLink]="item.url?.path || item.url || item.path"
							class="mb-0"
							[attr.data-test-id]="'list-item-link-' + ((item?.url?.path || item?.url || item?.path) | slugify)"
						>
							<h3 class="title">{{ item.title }}</h3>
						</a>
						<div class="author" [attr.aria-current]="isCurrentDate(item.created * 1000) ? 'date' : undefined">
							{{ item.author || item.fieldAuthor }} {{ item.created*1000 |
							date:"dd.MM.yyyy" }}
						</div>
					</div>
				</ng-container>

				<ng-container
					*ngIf="item.sidebar?.length && item.sidebar?.[0]?.fieldOskaField?.length"
				>
					<div class="inline__items sm-hide">
						<ng-container
							*ngFor="let oskaField of item.sidebar[0].fieldOskaField; let ind = index; let last = last;"
						>
							<ng-container *ngIf="ind < 2 && oskaField">
								<span
									aria-hidden="true"
									class="bar"
									*ngIf="oskaField.title && ind !== 0"
									>|</span
								>
								<div class="item item--inline">
									<a
										[href]="oskaField.path"
										tabindex="0"
										attr.aria-label="{{ 'oska.title_field' | translate }} {{ oskaField.title }}"
										[attr.data-test-id]="'list-item-oska-link-' + (oskaField?.path | slugify)"
										>{{ oskaField.title }}</a
									>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>

				<div *ngIf="type === 'mainProfession'" class="sm-show inline__items">
					<ng-container *ngIf="item.fillingBar">
						<ng-container
							*ngIf="item.fillingBar?.length && item.fillingBar[0].value
									&& item.fillingBar[0].value > 0 && item.fillingBar[0].value < 6"
						>
							<span class="item item--expanded"
								>{{ 'oska.competition' | translate | ucfirst }}:
								<span class="dark"
									>{{ getCompetitionLabel(item.fillingBar[0].value) | translate
									}}</span
								>
							</span>
						</ng-container>
						<ng-container *ngIf="item.indicator?.length">
							<ng-container
								*ngFor="let elem of indicatorValues(item.indicator); let ind = index;"
							>
								<ng-container *ngIf="elem.oskaId && elem.oskaId == 1">
									<span class="item item--expanded"
										>{{ 'oska.fieldNumberEmployed' | translate }}:
										<span class="dark">{{ elem.value | localeNumber }}</span>
									</span>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>

				<div
					*ngIf="type === 'school'"
					class="sm-show inline__items inline__items--floating"
				>
					<ng-container
						*ngFor="let fieldName of footerFields[type]; let i = index"
					>
						<ng-container *ngIf="item[fieldName]">
							<span role="presentation" class="bar" *ngIf="i>0">|</span>
							<span
								class="item"
								*ngIf="fieldName === 'webpage' && item[fieldName]"
							>
								<a
									attr.aria-label="{{ 'event.webpage' | translate }} {{ item[fieldName] | removeProtocol }}"
									href="{{ item[fieldName] | url }}"
									target="_blank"
									[attr.data-test-id]="'list-item-webpage-link-' + (item?.[fieldName] | url | slugify)"
									>{{ item[fieldName] | removeProtocol }}</a
								>
							</span>
							<span class="item" *ngIf="fieldName === 'email'">
								<a
									attr.aria-label="{{'event.email' | translate}} {{ item[fieldName] }}"
									href="mailto:{{ item[fieldName]}}"
									[attr.data-test-id]="'list-item-email-link-' + (item?.[fieldName] | slugify)"
									>{{ item[fieldName]}}</a
								>
							</span>
							<span class="item" *ngIf="fieldName === 'phone'">
								<a
									attr.aria-label="{{'event.phone' | translate}} {{ item[fieldName] }}"
									href="tel:{{ item[fieldName] }}"
									[attr.data-test-id]="'list-item-phone-link-' + (item?.[fieldName] | slugify)"
									>{{ item[fieldName] }}</a
								>
							</span>
						</ng-container>
					</ng-container>
				</div>

				<div
					*ngIf="type !== 'news'"
					[class]="type === 'school' || type === 'mainProfession' ? 'inline__items sm-hide' : 'inline__items'"
				>
					<ng-container
						*ngFor="let fieldName of footerFields[type]; let i = index"
					>
						<ng-container *ngIf="item[fieldName]?.length">
							<span role="presentation" class="bar" *ngIf="i>0">|</span>
							<span class="item" *ngIf="fieldName === 'duration'"
								>{{ item.duration | monthsToYears }}</span
							>
							<span
								class="item"
								*ngIf="fieldName === 'studyProgrammeLevel' ||
                       fieldName === 'fieldSchoolName'"
								>{{ item[fieldName] }}</span
							>
							<span class="item" *ngIf="fieldName === 'address' || fieldName === 'fieldSchoolLocation'"
								>{{ item[fieldName]?.[0]?.fieldAddress }}</span
							>
							<span class="item" *ngIf="fieldName === 'educationalInstitution'"
								>{{ item[fieldName]?.[0]?.title }}</span
							>
							<ng-container *ngIf="fieldName === 'teachingLanguage'">
								<span class="item" *ngIf="isArray((item[fieldName]))">
									<ng-container
										*ngFor="let item of (item[fieldName]); let i = index;"
									>
										<span>
											<span *ngIf="i > 0">, </span>
											<span *ngIf="i === 0">{{ item | ucfirst }}</span>
											<span *ngIf="i !== 0">{{ item }}</span>
										</span>
									</ng-container>
								</span>
								<span class="item" *ngIf="!isArray(item[fieldName])">
									{{ item[fieldName] | ucfirst }}
								</span>
							</ng-container>
							<span
								class="item"
								*ngIf="fieldName === 'webpage' && item[fieldName]"
							>
								<a
									attr.aria-label="{{'event.webpage' | translate}} {{ item[fieldName] | removeProtocol }}"
									href="{{ item[fieldName] | url }}"
									target="_blank"
									[attr.data-test-id]="'list-item-webpage-link-' + (item?.[fieldName] | url | slugify)"
									>{{ item[fieldName] | removeProtocol }}</a
								>
							</span>
							<span class="item" *ngIf="fieldName === 'email'">
								<a
									attr.aria-label="{{'event.email' | translate}} {{ item[fieldName]}}"
									href="mailto:{{ item[fieldName]}}"
									[attr.data-test-id]="'list-item-email-link-' + (item?.[fieldName] | slugify)"
									>{{ item[fieldName]}}</a
								>
							</span>
							<span class="item" *ngIf="fieldName === 'phone'">
								<a
									attr.aria-label="{{'event.phone' | translate}} {{ item[fieldName]}}"
									href="tel:{{ item[fieldName]}}"
									[attr.data-test-id]="'list-item-phone-link-' + (item?.[fieldName] | slugify)"
									>{{ item[fieldName]}}</a
								>
							</span>

							<ng-container
								*ngIf="fieldName === 'fillingBar' && !item.fieldProfession"
							>
								<ng-container
									*ngIf="(item[fieldName]?.length && item[fieldName][0]?.value) || item.indicator?.length"
								>
									<ng-container
										*ngIf="item[fieldName][0].value > 0 && item[fieldName][0].value < 6"
									>
										<span class="item"
											>{{ 'oska.competition' | translate | ucfirst }}:
											<span class="dark"
												>{{ getCompetitionLabel(item[fieldName][0].value) |
												translate }}</span
											></span
										>
									</ng-container>
									<ng-container *ngIf="item.indicator?.length">
										<ng-container
											*ngFor="let elem of indicatorValues(item.indicator); let ind = index;"
										>
											<ng-container *ngIf="elem.oskaId">
												<span
													class="bar"
													*ngIf="(
														item[fieldName]?.length && item[fieldName][0]?.value &&
														item[fieldName][0].value > 0 && item[fieldName][0].value < 6
													) || ind === 1"
													>|</span
												>
												<span class="item"
													>{{ elem.oskaId == 1 ? ('oska.fieldNumberEmployed' |
													translate) : (elem.oskaIndicator | ucfirst) }}:
													<span *ngIf="elem.oskaId == 1" class="dark"
														>{{ elem.value | localeNumber }}</span
													>
													<span *ngIf="elem.oskaId == 3" class="dark"
														>{{ elem.value | currency:"EUR" }}
													</span>
												</span>
											</ng-container>
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>

			<div
				class="list__item__picto-wrapper"
				*ngIf="item?.fieldPictogram?.[0] && item.highlighted"
			>
				<picto
					tabindex="-1"
					aria-hidden="true"
					[img]="item.fieldPictogram[0].url"
				></picto>
			</div>
		</li>
	</ng-container>
	<!--/list repeat-->
</ul>

<htm-modal
	*ngIf="compare"
	id="compare"
	[modalTitle]="translationsPerType[compare].title | translate"
	size="medium"
>
	<ng-template id="compare">
		<div
			innerHTML="{{ translationsPerType[compare].content | translate }}"
		></div>
	</ng-template>
</htm-modal>

<alerts global id="compare"></alerts>
