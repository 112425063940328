<htm-swiper [config]="config" [resolveElements]="false" #usefulSwiper>
  <div class="swiper-wrapper" aria-roledescription="carousel" aria-live="polite" id="carousel-content">
    <ng-container *ngFor="let item of data; let i = index; let l = count">
      <a class="swiper-slide slides__instance"
        *ngIf="item.link.routed"
        [routerLink]="item.link.path"
        role="group"
        aria-roledescription="slide"
        [attr.data-test-id]="'carousel-link-' + (item?.link?.path | slugify)"
        [attr.aria-label]="'slide ' + (i + 1) + ' of ' + l">
        <div class="slides__elem">
          <div class="slides__elem__image">
            <img [src]="item.image.url" alt="" />
          </div><!--/slides__elem__image-->
          <div class="slides__elem__body">
            <h3 class="slides__elem__title"  [attr.aria-label]="item.title" [id]="'described-by-' + (item.title | slugify)" >{{ item.title }}</h3>
            <p class="slides__elem__text">{{ item.content }}</p>
            @if (!item?.hideLink) {
            <a class="slides__elem__link"
              [routerLink]="item.link.path"
              [attr.aria-describedby]="'described-by-' + (item.title | slugify)">{{ item.link.title }}</a>
            }
          </div><!--/slides__elem__body-->
        </div><!--/slides__elem-->
      </a><!--/slides__instance-->

      <a class="swiper-slide slides__instance"
        *ngIf="!item.link.routed"
        [href]="item.link.path"
        role="group"
        [attr.data-test-id]="'carousel-link-' + (item?.link?.path | slugify)"
        aria-roledescription="slide"
        [attr.aria-label]="'slide ' + (i + 1) + ' of ' + l">
        <div class="slides__elem">
          <div class="slides__elem__image">
            <img [src]="item.image.url" alt="" />
          </div><!--/slides__elem__image-->
          <div class="slides__elem__body">
            <h3 class="slides__elem__title"  [attr.aria-label]="item.title" [id]="'described-by-' + (item.title | slugify)">{{ item.title }}</h3>
            <p class="slides__elem__text">{{ item.content }}</p>
            <a class="slides__elem__link"
              [href]="item.link.path"
              [attr.aria-describedby]="'described-by-' + (item.title | slugify)">{{ item.link.title }}</a>
          </div><!--/slides__elem__body-->
        </div><!--/slides__elem-->
      </a><!--/slides__instance-->
    </ng-container>
  </div>
  <div class="swiper-pagination" aria-hidden="true" tabindex="-1"></div>
</htm-swiper>
<div class="slides__arrow slides__arrow--left" aria-hidden="true" tabindex="-1" data-test-id="carousel-main-backwards-button">
  <icon glyph="chevron-left" size="large"></icon>
</div>
<div class="slides__arrow slides__arrow--right" aria-hidden="true" tabindex="-1" data-test-id="carousel-main-forwards-button">
  <icon glyph="chevron-right" size="large"></icon>
</div>
