<ng-container *ngIf="status == 'vote'">
  <h3 attr.aria-label="{{ 'feedback_heading' | translate }}">
    <b>{{ 'feedback_heading' | translate }}</b>
  </h3>
  <div class="options">
    <button (click)="vote(true)" attr.aria-label="{{ 'feedback_yes' | translate }}" data-test-id="feedback-positive-button">
      <icon glyph="thumbs-up" bg="true"></icon>
      {{ 'feedback_yes' | translate }}
    </button>
    <button (click)="vote(false)" class="negative"
            attr.aria-label="{{ 'feedback_no' | translate }}"
            data-test-id="feedback-negative-button">
      <icon glyph="thumbs-down" bg="true"></icon>
      {{ 'feedback_no' | translate }}
    </button>
  </div><!--/options-->
</ng-container>

<ng-container *ngIf="status == 'add_comment'">
  <h3 role="alert" *ngIf="!values.vote">
    <b>{{ 'feedback_how_to_improve' | translate }}</b>
  </h3>
  <h3 role="alert" *ngIf="values.vote">
    <b>{{ 'feedback_add_comment' | translate }}</b>
  </h3>
  <div class="comment">
    <div tabindex="0" class="formItem">
      <formItem
        *ngIf="values.vote"
        type="textarea"
        [(ngModel)]='values.comment'
        attr.aria-label="{{ 'feedback_comment' | translate }}"
        height="120"
        [error]="feedbackError"
        [errorMessage]="'feedback_comment_error' | translate"
        testId="feedback-comment"
        [title]="'feedback_comment' | translate"></formItem>
      <formItem
        *ngIf="!values.vote"
        type="textarea"
        [(ngModel)]='values.comment'
        attr.aria-label="{{ 'feedback_comment_required' | translate }}"
        height="120"
        [error]="feedbackError"
        [errorMessage]="'feedback_comment_error' | translate"
        testId="feedback-comment"
        [title]="'feedback_comment_required' | translate"></formItem>
    </div><!--/formItem-->
    <button htm-button theme="plain" (click)="cancel()"
      data-test-id="feedback-cancel-button"
            attr.aria-label="{{ 'feedback_cancel' | translate }}">{{ 'feedback_cancel' | translate }}</button>
    <button htm-button (click)="sendVote()"
      data-test-id="feedback-send-button"
            attr.aria-label="{{ 'feedback_send' | translate }}">{{ 'feedback_send' | translate }}</button>
  </div><!--/comment-->
</ng-container>

<ng-container *ngIf="status == 'response'">
  <div class="response">
    <h3 role="alert">
      <b>{{ 'feedback_thanks' | translate }}</b>
    </h3>
  </div><!--/response-->
</ng-container>
