<line [type]="5" [absoluteContainer]="false"></line>
<div class="footer__wrapper">
	<div class="footer__content">
		<div class="footer__column" *ngIf="footerData?.first as columnData">
			<span class="footer__column-title">{{'footer.contact' | translate}}</span>
			<div class="footer__column-contents">
				<div
					class="footer__contact-contents"
					[innerHTML]="columnData.textarea"
				></div>
				<a
					class="footer__email-link"
					[href]="'mailto:' + columnData?.email"
					data-test-id="footer-email-link"
					>{{columnData?.email}}</a
				>
				<div>
					<span>{{columnData?.phone}}</span>
					<span>&nbsp;{{columnData?.open_times}}</span>
				</div>
			</div>
		</div>
		<div class="footer__column" *ngIf="footerLinks() as links">
			<span class="footer__column-title">{{'footer.other' | translate}}</span>
			<div class="footer__column-contents">
				<ng-container *ngFor="let link of links">
					<a
						[href]="link?.link"
						*ngIf="link?.routed === 'false'"
						target="_blank"
						rel="noreferrer"
						[attr.data-test-id]="'footer-link-' + (link?.link | slugify)"
					>
						{{link?.title}}
					</a>
					<a
						[routerLink]="link?.link"
						*ngIf="link?.routed === 'true'"
						[attr.data-test-id]="'footer-link-' + (link?.link | slugify)"
					>
						{{link?.title}}
					</a>
				</ng-container>
			</div>
		</div>
		<div class="footer__column">
			<span class="footer__column-title" *ngIf="footerData?.third?.length">
				{{'footer.socials' | translate}}</span
			>
			<div class="footer__column-contents">
				<div class="footer__socials" *ngIf="footerData?.third?.length">
					<ng-container *ngFor="let social of footerData?.third">
						<a
							[href]="social?.url"
							[attr.data-test-id]="'footer-social-link-' + social?.label"
							[attr.aria-label]="social?.label"
							target="_blank"
							rel="noreferrer"
						>
							<icon [glyph]="social?.icon"></icon>
						</a>
					</ng-container>
				</div>
				<div class="footer__info">
					<div>{{'frontpage.version' | translate}}&nbsp;{{version}}</div>
					<div innerHtml="{{ 'sidemenu.support_text' | translate }}"></div>
				</div>
			</div>
		</div>
	</div>
	<ng-container
		[ngTemplateOutlet]="footerService?.logoTemplate?.()?.template"
		[ngTemplateOutletContext]="{inViewport}"
		*ngIf="footerService?.logoTemplate?.()?.template"
	></ng-container>
</div>
