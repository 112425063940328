import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
enum TooltipSymbol {
  'error' = '!',
  'info' = '?',
}

@Component({
  selector: 'tooltip',
  templateUrl: 'tooltip.template.html',
  styleUrls: ['tooltip.styles.scss'],
  standalone: true,
  imports: [MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
  @Input() placement: string = 'right';
  @Input() type: string = 'info';
  @Input() content: string;

  public tooltipSymbol = TooltipSymbol;
}
