<ng-container *ngIf="active()">
	<ng-container *ngIf="loading">
		<ul aria-hidden="true">
			<li>{{ 'autocomplete.loading' | translate }}</li>
		</ul>
	</ng-container>
	<ng-container *ngIf="!loading && searched && !data.length">
		<ul aria-hidden="true">
			<li>{{ 'autocomplete.no_result' | translate }}</li>
		</ul>
	</ng-container>
	<ng-container *ngIf="!loading && data.length">
		<ul class="autocomplete__results" role="listbox" id="autocompleteResults">
			<ng-container *ngFor="let item of data; let i = index;">
				<li role="option" (click)="onClick(item)" (keypress.enter)="onClick(item)" attr.id="option-{{i}}"
						[class.autocomplete__active]="i == activeItem" [attr.aria-selected]="i == activeItem">
					{{ item | parseInAdds }}
				</li>
			</ng-container>
		</ul>
	</ng-container>
</ng-container>
