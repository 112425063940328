import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { GetBaseInputValue } from '../../models/base-input.types';


@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class BaseInputComponent<T = unknown> implements ControlValueAccessor {
  // Purely to support filters directive
  type?: string;
  propagateOnChange: (...args: unknown[]) => void = undefined;
  propagateOnBlur: (...args: unknown[]) => void = undefined;
  @Input() title?: string;
  @Input() ariaLabel?: string;
  @Input() name: string;
  @Input() required = false;
  @Input() excludeFromSearch?: boolean;
  @Input() placeholder?: string;
  @Input() search = true;
  @Input() error?: boolean;
  @Input() errorMessage?: string;
  @Input() testId?: string;
  private innerDisabled?: boolean;
  @Input()
  public get disabled(): boolean {
    return this.innerDisabled;
  }
  public set disabled(value: boolean) {
    this.innerDisabled = value;
  }

  public writeValue(value: T): void {
    this.propagateOnChange(value);
  }

  public registerOnChange(fn: (...args: unknown[]) => void): void {
    // Basic implementation
    this.propagateOnChange = fn;
  }

  public registerOnTouched(fn: (...args: unknown[]) => void): void {
    // Basic implementation
    this.propagateOnBlur = fn;
  }

  public getValue(): GetBaseInputValue<T> {
    return {} as GetBaseInputValue<T>;
  }

  public setDisabledState(isDisabled: boolean): void {
    // Basic implementation
    this.disabled = isDisabled;
  }

  /**
   * This method exists purely to support filters directive
   *
   * @param value Whatever value the base input uses
   */
  public setValue(value: T): void {
    this.writeValue(value);
  }
}
