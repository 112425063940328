<div
	class="language-switcher"
	[class.language-switcher--header]="!isInSidemenu"
	[class.language-switcher--sidemenu]="isInSidemenu"
	*ngIf="isVisible"
>
	<ng-container *ngIf="!isInSidemenu">
		<button
			class="language-switcher__toggle language-switcher__toggle--header"
			[attr.aria-label]="
      ('frontpage.selected' | translate) +
      ' ' +
      (languageData?.activeLang?.label | translate | lowercase) +
      ' ' +
      ('frontpage.language' | translate)
    "
			[attr.aria-haspopup]="'listbox'"
			(click)="toggleDropdown()"
			data-test-id="header-language-switcher-toggle"
		>
			<span>
				{{(mappedLangCodes?.[languageData?.activeLang?.code] ||
				languageData?.activeLang?.code) | uppercase}}
			</span>
		</button>
		<div
			class="language-switcher__content language-switcher__content--header"
			tabindex="-1"
			role="listbox"
			[attr.aria-label]="'frontpage.choose_language' | translate"
			[attr.aria-expanded]="false"
			[class.language-switcher__content--open]="menuOpen"
		>
			<ng-container
				*ngFor="let language of languageData?.availableLanguages; index as idx"
			>
				<a
					(click)="changeLanguage(language.code)"
					(keydown.enter)="changeLanguage(language.code)"
					(keydown.space)="changeLanguage(language.code)"
					[attr.aria-label]="
          idx === languageData?.availableLanguages?.length - 1
            ? ('frontpage.last' | translate) +
              ' ' +
              (language.label | translate)
            : (language.label | translate)
        "
					role="option"
					tabindex="0"
					[attr.data-test-id]="'header-language-' + language.code"
					[class.language-switcher__lang--active]="languageData?.activeLang?.code === language?.code"
					[attr.aria-current]="languageData?.activeLang?.code === language?.code"
				>
					<span class="language-switcher__lang">
						{{language?.label | translate}}
					</span>
				</a>
			</ng-container>
		</div>
	</ng-container>

	<ng-container *ngIf="isInSidemenu">
		<div
			class="language-switcher__sidemenu-wrapper"
			[class.language-switcher__sidemenu-wrapper--open]="menuOpen"
		>
			<button
				class="language-switcher__toggle language-switcher__toggle--sidemenu"
				[attr.aria-label]="
      ('frontpage.selected' | translate) +
      ' ' +
      (languageData?.activeLang?.label | translate | lowercase) +
      ' ' +
      ('frontpage.language' | translate)
    "
				(click)="toggleDropdown()"
				data-test-id="sidemenu-language-switcher-toggle"
				[attr.aria-expanded]="menuOpen"
				aria-controls="sidemenu-language-choices"
				aria-haspopup="true"
			>
				<icon glyph="globe"></icon>
				{{ (languageData?.activeLang?.label | translate) + ' ' +
				('frontpage.language' | translate) }}
				<icon
					class="language-switcher__sidemenu-chevron"
					glyph="chevron-right"
				></icon>
			</button>
			<ul class="language-switcher__sidemenu-choices" id="sidemenu-language-choices" *ngIf="menuOpen">
				<ng-container
					*ngFor="let language of languageData?.availableLanguages; index as idx"
				>
					<li *ngIf="language?.code !== languageData?.activeLang?.code">
						<a
							(click)="changeLanguage(language.code)"
							(keydown.enter)="changeLanguage(language.code)"
							(keydown.space)="changeLanguage(language.code)"
							role="option"
							tabindex="0"
							[attr.data-test-id]="'sidemenu-language-' + language.code"
							class="language-switcher__sidemenu-lang"
						>
							{{ (swapTranslationPrefix(language?.label)  | translate) + ' ' +
							('frontpage.language' | translate) }}
						</a>
					</li>
        </ng-container>
			</ul>
		</div>
	</ng-container>
</div>
