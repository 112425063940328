import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatMomentDateModule,
	MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputWrapperComponent } from './components/input-wrapper/input-wrapper.component';

const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'DD.MM.YYYY',
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY',
	},
};
@NgModule({
	declarations: [InputDateComponent, InputWrapperComponent],
	imports: [
		CommonModule,
		MatDatepickerModule,
		MatInputModule,
		MatFormFieldModule,
		MatMomentDateModule,
		ReactiveFormsModule,
		FormsModule,
	],
	exports: [InputDateComponent],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'et' },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	],
})
export class FormInputsModule {}
