import { DarkModeService } from '../../../_services/dark-mode.service';
import { ChangeDetectionStrategy, Component } from "@angular/core";


@Component({
  selector: 'dark-mode-toggle',
  styleUrls: ['dark-mode-toggle.styles.scss'],
  templateUrl: 'dark-mode-toggle.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkModeToggleComponent {
  darkModeAllowed = this.darkModeService.darkModeAllowed;
  darkModeEnabled = this.darkModeService.darkModeEnabled;
  constructor(private darkModeService: DarkModeService) {};

  handleToggle(): void {
    this.darkModeService.toggleState();
  }
}
