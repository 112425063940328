<div class="share__wrapper"
  >
  <button
  class="share__indicator"
  tabindex="-1"
  aria-hidden="true"
  data-test-id="share-button"
  >
    <icon glyph="share-2"></icon>
  </button>
  @if (linkText()) {
    <span class="share__link-text">{{linkText()}}</span>
  }
</div>

<button
	ripple
	[matTooltip]="'tooltip.copy' | translate"
	matTooltipPosition="right"
	(focus)="hostFocused()"
	(blur)="hostBlurred(false, $event);"
	class="share__link"
	tabindex="0"
	(click)="copyLinkToClipboard('click')"
	(touchstart)="copyLinkToClipboard('touchstart')"
	data-test-id="share-clipboard-button"
	attr.aria-label="{{ 'social.share_link' | translate }}, {{ 'tooltip.copy' | translate }}"
>
	<icon glyph="link"></icon>
</button>
@if (isFocused() || isHovered()) {

<div class="share__options">
	<button
		ripple
		[matTooltip]="'tooltip.share_fb' | translate"
		matTooltipPosition="right"
		(focus)="hostFocused()"
		(blur)="hostBlurred(false, $event);"
		(click)="share($event, 'facebook'); hostBlurred()"
		class="share__option"
		tabindex="0"
		role="link"
		data-test-id="share-facebook-button"
		attr.aria-label="{{ 'tooltip.share_fb' | translate }}"
	>
		<icon glyph="facebook"></icon>
	</button>
	<button
		ripple
		[matTooltip]="'tooltip.share_twitter' | translate"
		matTooltipPosition="right"
		(focus)="hostFocused()"
		(blur)="hostBlurred(false, $event)"
		(click)="share($event, 'twitter'); hostBlurred()"
		class="share__option"
		tabindex="0"
		role="link"
		data-test-id="share-twitter-button"
		attr.aria-label="{{ 'tooltip.share_twitter' | translate }}"
	>
		<icon glyph="twitter"></icon>
	</button>
</div>
<!--/share__options-->
}

<div
	class="share__overlay"
	(click)="hostBlurred(true)"
	*ngIf="isTouchDevice() && isHovered()"
></div>
