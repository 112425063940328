<aside *ngIf="!isLoading()">
	<div class="space">
		<button htm-button theme="icon" attr.aria-label="{{ 'button.close' | translate }} {{
			'frontpage.main_menu' | translate
    }}" (click)="closeSidemenu()" #sidemenuCloser>
			<span class="button--label">
				<icon class="large" glyph="x" size="large" aria-hidden="true"></icon>
			</span>
		</button>
	</div>
	<ng-container *ngIf="(data | async) as data">
		<div class="loader" *ngIf="!data?.length">
			<loader></loader>
		</div>
		<nav class="sidemenu__wrapper">
			<ng-container *ngIf="data?.length">
				<sidemenu-item [items]="data" [type]="'category'" (hideToggle)="hideOthers($event)"></sidemenu-item>
			</ng-container>
			<div class="sidemenu__separator"></div>
			<language-switcher
			[languageData]="languageService.languageSwitcherData$ | async"
			(languageChangeEvent)="languageService.handleLanguageChange($event)"
			[isInSidemenu]="true"
			></language-switcher>
		</nav>
	</ng-container>
	<dark-mode-toggle></dark-mode-toggle>
</aside>
