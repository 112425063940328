import { Location, registerLocaleData } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptorsFromDi,
	withJsonpSupport,
} from '@angular/common/http';
import localeEt from '@angular/common/locales/et';
import {
	LOCALE_ID,
	NgModule,
	inject,
	provideAppInitializer,
} from '@angular/core';
import {
	BrowserModule
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@app/_modules/translate';
import { AppComponent } from '@app/app.component';
import { AssetsModule } from './_assets';
import { AlertsComponent } from './_assets/alerts';
import { AuthInterceptor } from './_interceptors';
import { BlobErrorHttpInterceptor } from './_interceptors/blob-error-interceptor';
import { DedrupalizeInterceptor } from './_interceptors/dedrupalize.interceptor';
import { FourOhFourInterceptor } from './_interceptors/four-oh-four.interceptor';
import { LanguageService, languageServiceInitializer } from './_services';
import { IconService } from './_services/Icon.service';
import { TitleService } from './_services/TitleService';
import { AmpService } from './_services/ampService';
import { NotificationsService } from './_services/notifications.service';
import { RoutesModule } from './app.routes';
import { DigiSignOverlayComponent } from './components/digi-sign/digi-sign-overlay/digi-sign-overlay.component';
import { FooterComponent } from './components/footer/footer.component';
import { GlobalAlertComponent } from './components/global-alert/global-alert.component';
import { HeaderComponent } from './components/header/header/header.component';
import { LoaderComponent } from './components/loader';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SkipToContentComponent } from './components/skip-to-content/skip-to-content.component';
import { DarkModeModule } from './modules/dark-mode/dark-mode.module';
// We do not need a short month names at all!
localeEt[5][1] = localeEt[5][2].map((item) => {
	return item.charAt(0).toUpperCase() + item.slice(1);
});

registerLocaleData(localeEt);

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		RoutesModule,
		AssetsModule.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot(),
		SkipToContentComponent,
		HeaderComponent,
		DarkModeModule,
		FooterComponent,
		AlertsComponent,
		LoaderComponent,
		DigiSignOverlayComponent,
		GlobalAlertComponent,
		NavigationComponent,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'et-EE' },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: FourOhFourInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BlobErrorHttpInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DedrupalizeInterceptor,
			multi: true,
		},
		AmpService,
		TitleService,
		Location,
		{ provide: 'googleTagManagerId', useValue: 'G-GY5JMZHEYQ' },
		IconService,
		provideAppInitializer(() => {
			const initializerFn = languageServiceInitializer(inject(LanguageService));
			return initializerFn();
		}),
		NotificationsService,
		provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
	],
})
export class AppModule {}
