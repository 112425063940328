import {
	DestroyRef,
	Directive,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@app/_services';

@Directive({
	selector: '[authGate]',
	standalone: true,
})
export class AuthGateDirective {
	private isViewCreated = false;
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authService: AuthService,
		private destroyRef: DestroyRef
	) {
		this.authService.isAuthenticated
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((isAuthenticated) => {
        // Render if not already rendered and user is authenticated
				if (!this.isViewCreated && isAuthenticated) {
					this.viewContainer.createEmbeddedView(this.templateRef);
					this.isViewCreated = true;
				} else if (this.isViewCreated && !isAuthenticated) {
					this.viewContainer.clear();
					this.isViewCreated = false;
				}
			});
	}
}
