import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { DarkModeToggleComponent } from "./dark-mode-toggle/dark-mode-toggle.component";
import { IconComponent } from "@app/components/icon/icon.component";
import { SvgDarkModeSuffixPipe } from './svg-dark-mode-suffix.pipe';
import { TranslateModule } from '@app/_modules/translate';
@NgModule({
	declarations: [DarkModeToggleComponent, SvgDarkModeSuffixPipe],
	imports: [CommonModule, IconComponent, TranslateModule],
	exports: [DarkModeToggleComponent, SvgDarkModeSuffixPipe],
})
export class DarkModeModule {}

