import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[tag]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TagComponent {
  @HostBinding('attr.active') @Input() active: boolean = false;
}
