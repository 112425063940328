<htm-header-inner
  [menuOpen]="menuOpen$ | async"
  [theme]="theme$ | async"
  [contents]="headerContentsTemplate"
  (menuToggleEvent)="toggleMenu()"
>
</htm-header-inner>

<ng-template #headerContentsTemplate>
  <div class="header__search-wrapper">
    <htm-header-search></htm-header-search>
  </div>
  <div class="header__inner-assets">
    <language-switcher
    [languageData]="languageService.languageSwitcherData$ | async"
    (languageChangeEvent)="languageService.handleLanguageChange($event)"
    ></language-switcher>
    <header-notifications *authGate></header-notifications>
    <htm-header-auth></htm-header-auth>
  </div>
</ng-template>
