<div id="certificate" #certificate>

  <section id="duplicate-info" *ngIf="document.content.duplicateCertificateNumber">
    <p>Lõputunnistuse nr. {{document.content.duplicateCertificateNumber}}</p>
    <p>duplikaat {{document.content.duplicateIssueDate }}</p>
  </section>

  <img alt="Eesti vapp" src="../../../assets/img/vapp.svg" id="coat-of-arms">

  <h1 id="certificate-title">{{ document.content.documentName.toUpperCase() }}</h1>

  <ng-container *ngIf="document.type === 'GRADUATION_DOCUMENT_TYPE:BASIC_EDUCATION_CERTIFICATE' || document.type === 'GRADUATION_DOCUMENT_TYPE:GENERAL_EDUCATION_CERTIFICATE'">

  <p>{{ document.content.registrationNumber }}</p>

  <section id="info">
    <p class="info-badge" *ngIf="document.status === 'CERT_DOCUMENT_STATUS:INVALID'">Kehtetu</p>
  </section>
  
  <section id="graduate">
    <p class="bold">{{ document.content.graduate.firstName }} {{ document.content.graduate.lastName }}</p>
    <p>(ees- ja perekonnanimi)</p>
  </section>

  <section id="identity">
    <p class="bold">
      {{ document.content.graduate.idCode | idCode }}
      <ng-container *ngIf="!document.content.graduate.idCode && document.content.graduate.dateOfBirth">
        {{ document.content.graduate.dateOfBirth | date: 'dd.MM.yyyy' }}
      </ng-container>
    </p>
    <p>
      <ng-container *ngIf="document.content.graduate.idCode">
        (isikukood)
      </ng-container>
      <ng-container *ngIf="!document.content.graduate.idCode && document.content.graduate.dateOfBirth">
        (sünnikuupäev)
      </ng-container>
    </p>
  </section>

  <p>on lõpetanud</p>

  <section id="institution">
    <p class="bold">{{ document.content.educationalInstitution.locationToPrintout }}</p>
    <p class="bold">{{ document.content.educationalInstitution.nameToPrintOut }}</p>
    <p class="bold">{{ document.type === 'GRADUATION_DOCUMENT_TYPE:BASIC_EDUCATION_CERTIFICATE' ? document.content.studies.curriculumNameToPrintOut : document.content.studies.programme }}</p>
    <p class="bold">{{ document.content.studies.honors }}</p>
  </section>

  <p>õppenõukogu otsusega{{ document.content.councilDecision.registrationNumber !== 'XXX' ? ' nr ' + document.content.councilDecision.registrationNumber : '' }}</p>
  <p>{{ document.content.councilDecision.issueDateToPrintOut }}</p>

  </ng-container>

</div>