import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@app/_modules/translate';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { AppPipes } from '@app/_pipes';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
  ],
  imports: [
    RouterModule,
    TranslateModule,
    CommonModule,
    IconComponent,
    AppPipes,
  ],
  exports: [
    BreadcrumbsComponent,
  ],
})
export class BreadcrumbsModule { }
