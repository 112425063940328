<ng-container *ngFor="let indicator of data; let last = last;">
	<ng-container
		*ngIf="Number(indicator.oskaId) === 1 && indicator.icon > 0 && indicator.icon < 12 && indicator.value"
	>
		<div class="d-flex">
			<h2
				attr.aria-label="{{ 'oska.fieldNumberEmployed' | translate }}: {{ indicator.value }}"
			>
				<span
					>{{ 'oska.fieldNumberEmployed' | translate }}: {{ indicator.value |
					localeNumber }}</span
				>
			</h2>
			<div
        class="sidebar-facts__tooltip"
        matTooltipPosition="right"
        matTooltip="{{ (theme === 'field' ? 'oska.fieldNumberEmployed_explanation' : 'oska.professionNumberEmployed_explanation') | translate }}"
        tabindex="0"
        [attr.aria-label]="(theme === 'field' ? 'oska.fieldNumberEmployed_explanation' : 'oska.professionNumberEmployed_explanation') | translate"
        role="note"
        >
        i
      </div>
		</div>
		<ng-container *ngIf="theme === 'field'; else otherExplanation">
			<span
				role="textbox"
				attr.aria-label="{{ 'oska.compared_to_other_fields' | translate }}: {{numToESTtext(indicator.icon)}} kümnest"
			>
			</span>
		</ng-container>
		<ng-template #otherExplanation>
			<span
				role="textbox"
				attr.aria-label="{{ 'oska.compared_to_other_professions' | translate }}: {{numToESTtext(indicator.icon)}} kümnest"
			>
			</span>
		</ng-template>
		<div class="list" aria-hidden="true">
			<div class="list__icon" *ngFor="let item of createArr(indicator.icon)">
				<img
					alt="{{ 'sidebar.icon.peopleColored' | translate }}"
					src="/assets/img/person_filled.svg"
				/>
			</div>
			<div
				class="list__icon"
				*ngFor="let elem of createArr(11 - indicator.icon)"
			>
				<img
					alt="{{ 'sidebar.icon.people' | translate }}"
					src="/assets/img/person.svg"
				/>
			</div>
		</div>
		<hr aria-hidden="true" *ngIf="!last" />
	</ng-container>

	<ng-container
		*ngIf="Number(indicator.oskaId) === 2 && indicator.icon > 0 && indicator.icon < 6"
	>
		<h2 attr.aria-label="{{ 'oska.numberEmployedChange' | translate }}:">
			{{ 'oska.numberEmployedChange' | translate }}:
		</h2>
		<ul>
			<li>
				<div
					class="icon__wrapper {{ trendingValues[indicator.icon - 1].class }}"
				>
					<icon
						[glyph]="trendingValues[indicator.icon - 1].icon"
						bg="true"
					></icon>
				</div>
				<span
					role="text"
					attr.aria-label="{{trendingValues[indicator.icon - 1].text | translate}}"
					>{{trendingValues[indicator.icon - 1].text | translate}}</span
				>
			</li>
		</ul>
		<hr aria-hidden="true" *ngIf="!last" />
	</ng-container>

	<ng-container
		*ngIf="Number(indicator.oskaId) === 3 && indicator.icon > 0 && indicator.icon < 21"
	>
		<h2
			attr.aria-label="{{ 'oska.wage' | translate }}: {{ indicator.value }} €"
		>
			{{ 'oska.wage' | translate }}: {{ indicator.value | localeNumber }} €
		</h2>
		<div class="list label__tag--pointer">
			<div
				class="list__icon"
				aria-hidden="true"
				*ngFor="let item of createArr((indicator.icon - (indicator.icon % 2)) / 2)"
			>
				<img
					alt="euromüntide ikoon (värvitud)"
					src="/assets/img/coin_full.svg"
				/>
			</div>
			<div
				class="list__icon"
				aria-hidden="true"
				*ngFor="let item of createArr(indicator.icon % 2)"
			>
				<img
					alt="euromüntide ikoon (pooleldi värvitud)"
					src="/assets/img/coin_half.svg"
				/>
			</div>
			<div
				class="list__icon"
				aria-hidden="true"
				*ngFor="let elem of createArr((20 - indicator.icon) / 2)"
			>
				<img alt="euromüntide ikoon" src="/assets/img/coin.svg" />
			</div>
		</div>
		<hr aria-hidden="true" *ngIf="!last" />
	</ng-container>

	<ng-container
		*ngIf="Number(indicator.oskaId) === 4 && indicator.icon > 0 && indicator.icon < 6"
	>
		<h2 attr.aria-label="{{ 'oska.graduation_to_jobs_rate' | translate }}">
			{{ 'oska.graduation_to_jobs_rate' | translate }}:
		</h2>
		<ul>
			<li>
				<div
					class="icon__wrapper {{graduatesToJobsValues[indicator.icon - 1].class}}"
				>
					<icon glyph="circle" bg="true"></icon>
				</div>
				<span
					role="text"
					attr.aria-label="{{graduatesToJobsValues[indicator.icon - 1].text | translate}}"
					>{{graduatesToJobsValues[indicator.icon - 1].text | translate}}</span
				>
			</li>
		</ul>
		<hr aria-hidden="true" *ngIf="!last" />
	</ng-container>
</ng-container>
