<ul [attr.aria-label]="'labels.tags' | translate" *ngIf="data && data.length">
	<ng-container *ngFor="let label of data;">
		<li
			[ngStyle]="!type && childStyles"
			*ngIf="label.entityLabel || label.value || label"
		>
			{{ (label.entityLabel || (label.value ? label.value : label)) | translate }}
		</li>
	</ng-container>
</ul>
