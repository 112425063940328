import { NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@app/_modules/translate';

@Component({
	selector: 'labels',
	templateUrl: './labels.template.html',
	styleUrls: ['./labels.styles.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgStyle, NgIf, TranslateModule, NgFor]
})
export class LabelsComponent {
	@Input() data: Object[];
	@Input() border: string = '#c7c7c9';
	@Input() background: string = '#ffffff';
	@Input() type: string;

	private childStyles = {
		'--label-border-inner': `var(--label-border, .0675rem solid ${this.border})`,
		'--label-bg-inner': `var(--label-bg, ${this.background})`,
	};

	@HostBinding('class') get hostClasses(): string {
		return this.type;
	}

	ngOnChanges() {
		if (this.data) {
			const data = Array.isArray(this.data) ? this.data : [this.data];
			this.data = data.map((item) => (item['entity'] ? item['entity'] : item));

			if (!this.type) {
				this.childStyles['--label-border-inner'] = `var(--label-border, .0675rem solid ${this.border})`;
				this.childStyles['--label-bg-inner'] = `var(--label-bg, ${this.background})`;
			}
		}
	}
}
