<div *ngIf="translatedContent" class="content"
  attr.id="wrapper-{{id}}">
  <div class="sr-only" [innerHTML]="translatedContent"></div>
  <div *ngIf="active"
    class="content__background"
    aria-hidden="true">
  </div>
  <div class="content__inner"
    aria-hidden="true"
    [class.show]="show"
    #moreContent
    innerHTML="{{ translatedContent }}">
  </div>
</div>
<p class="more" *ngIf="active" aria-hidden="true">
  <button theme="plain" htm-button (click)="changeState()" [attr.data-test-id]="'more-block-toggle-button-' + this.content">
      <ng-container *ngIf="!show">{{ 'button.show_more' | translate }}</ng-container>
      <ng-container *ngIf="show">{{ 'button.show_less' | translate }}</ng-container>
  </button>
</p>
