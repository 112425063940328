<div class="unpadded">
	<more-block
		id="e-tunnistused-main"
		content="abitekst.tunnistused"
	></more-block>
</div>
<accordion>
	<accordion-item [title]="'certificates.view_own_certificates' | translate">
		<ng-template>
			<more-block
				id="enda-tunnistused"
				content="abitekst.enda_tunnistused"
			></more-block>

			<button
				htm-button
				routerLink="{{ '/töölaud/tunnistused' | translatePath }}"
				fragment="lõputunnistused"
				data-test-id="certificate-final-documents-manage-certificates-link"
				*ngIf="isLoggedIn"
			>
				{{ "certificates.manage_certificates" | translate }}
			</button>
			<button
				htm-button
				(click)="logIn('/töölaud/tunnistused#lõputunnistused')"
				data-test-id="certificate-final-documents-certificates-login-link"
				*ngIf="!isLoggedIn"
			>
				{{ "generic.login_to_haridusportaal" | translate }}
			</button>
		</ng-template>
	</accordion-item>

	<accordion-item [title]="'certificates.access_with_idcode' | translate">
		<ng-template>
			<more-block
				id="sinu-id-tunnistused"
				content="abitekst.sinu_id_tunnistused"
			></more-block>

			<ng-container *ngIf="isLoggedIn; else logInButton">
				<loader
					*ngIf="loading.certificatesById; else certificatesbyIdRef"
				></loader>
				<ng-template #certificatesbyIdRef>
					<scrollableContent>
						<table
							htm-table
							id="certificatesByIdTable"
							*ngIf="certificatesById && certificatesById.length > 0"
						>
							<thead>
								<td></td>
								<th>{{ "certificates.owner_name" | translate }}</th>
								<th>{{ "certificates.issuer" | translate }}</th>
								<th>{{ "certificates.certificate_type" | translate }}</th>
							</thead>
							<tbody>
								<tr *ngFor="let certificate of certificatesById">
									<td>
										<a
											[routerLink]="
												'/tunnistused/lõpudokumendid/' + certificate.id
											"
											[attr.data-test-id]="'certificate-final-documents-certificate-link-' + certificate.id"
										>
											{{ "button.open" | translate }}
										</a>
									</td>
									<td>{{ certificate.ownerName }}</td>
									<td>{{ certificate.issuerName }}</td>
									<td>{{ certificate.typeName }}</td>
								</tr>
							</tbody>
						</table>
					</scrollableContent>
					<p
						*ngIf="!certificatesById || certificatesById.length === 0"
						class="text-center"
					>
						{{ "certificates.no_access" | translate }}
					</p>
				</ng-template>
			</ng-container>

			<ng-template #logInButton>
				<button htm-button (click)="logIn('/tunnistused/lõpudokumendid')"
					data-test-id="certificate-final-documents-access-with-id-login-link">
					{{ "certificates.login_to_view" | translate }}
				</button>
			</ng-template>
		</ng-template>
	</accordion-item>
	<accordion-item [title]="'certificates.view_with_accesscode' | translate">
		<ng-template>
			<more-block
				id="ligipääsukoodiga-tunnistused"
				content="abitekst.ligipääsukoodiga_tunnistused"
			></more-block>

			<loader
				*ngIf="
					loading.certificatesByAccessCode;
					else certificatesbyAccessCodeRef
				"
			></loader>

			<ng-template #certificatesbyAccessCodeRef>
				<form
					[formGroup]="accessFormGroup"
					(submit)="getCertificateByAccessCode()"
				>
					<formItem
						type="text"
						formControlName="certificateNr"
						[title]="'certificates.document_nr_field' | translate"
						[ariaLabel]="'certificates.document_nr_field' | translate"
						[error]="
							accessFormGroup.controls.certificateNr.invalid &&
							accessFormGroup.controls.certificateNr.dirty
						"
						[errorMessage]="'errors.field_required' | translate"
						testId="certificate-final-documents-certificate-nr-field"
					>
					</formItem>
					<formItem
						type="text"
						formControlName="accessCode"
						[title]="'certificates.access_code_field' | translate"
						[ariaLabel]="'certificates.access_code_field' | translate"
						[error]="
							accessFormGroup.controls.accessCode.invalid &&
							accessFormGroup.controls.accessCode.dirty
						"
						[errorMessage]="'errors.field_required' | translate"
						testId="certificate-final-documents-access-code-field"
					>
					</formItem>
					<button htm-button type="submit" theme="default"
						data-test-id="certificate-final-documents-access-code-submit-button">
						{{ "button.search" | translate }}
					</button>
				</form>
			</ng-template>
			<alerts small="true" id="certificatesByAccessCode"></alerts>
		</ng-template>
	</accordion-item>
	<accordion-item [title]="'certificates.disclosed' | translate">
		<ng-template>
			<more-block
				id="avalikustatud-tunnistused"
				content="abitekst.avalikustatud_tunnistused"
			></more-block>
			<alerts class="m-bottom-1" small id="certificatesByDisclosure"></alerts>
			<loader
				*ngIf="
					loading.certificatesByDisclosure;
					else certificatesByDisclosureRef
				"
			></loader>
			<ng-template #certificatesByDisclosureRef>
				<form
					[formGroup]="disclosureFormGroup"
					(submit)="getCertificateByDisclosure()"
					id="disclosureAccordion"
				>
					<div class="row m-bottom-1">
						<div class="col-6">
							<formItem
								type="text"
								formControlName="firstName"
								[title]="'certificates.first_name' | translate"
								[error]="
									disclosureFormGroup.controls.firstName.invalid &&
									disclosureFormGroup.controls.firstName.dirty
								"
								[errorMessage]="'errors.field_required' | translate"
								[ariaLabel]="'certificates.first_name' | translate"
								testId="certificate-final-documents-first-name-field"
							>
							</formItem>
						</div>
						<div class="col-6">
							<formItem
								type="text"
								formControlName="lastName"
								[title]="'certificates.last_name' | translate"
								[error]="
									disclosureFormGroup.controls.lastName.invalid &&
									disclosureFormGroup.controls.lastName.dirty
								"
								[errorMessage]="'errors.field_required' | translate"
								[ariaLabel]="'certificates.last_name' | translate"
								testId="certificate-final-documents-last-name-field"
							>
							</formItem>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<formItem
								type="text"
								formControlName="idCode"
								[title]="'finaldocument.idcode' | translate"
								[error]="
									disclosureFormGroup.controls.idCode.invalid &&
									disclosureFormGroup.controls.idCode.dirty
								"
								[errorMessage]="'errors.field_required' | translate"
								[ariaLabel]="'finaldocument.idcode' | translate"
								testId="certificate-final-documents-id-code-field"
							>
							</formItem>
						</div>
					</div>
					<button htm-button type="submit" theme="default"
						data-test-id="certificate-final-documents-disclosure-search-button">
						{{ "button.search" | translate }}
					</button>
				</form>
				<scrollableContent>
					<table
						htm-table
						id="certificatesByDisclosureTable"
						*ngIf="
							certificatesByDisclosure && certificatesByDisclosure.length > 0
						"
					>
						<thead>
							<th>{{ "certificates.issuer" | translate }}</th>
							<th>{{ "certificates.name_and_idcode" | translate }}</th>
							<th>{{ "certificates.issuing_date" | translate }}</th>
							<td></td>
						</thead>
						<tbody>
							<tr *ngFor="let certificate of certificatesByDisclosure">
								<td>{{ certificate.issuerName }}</td>
								<td>
									<p>{{ certificate.ownerName }}</p>
									<p>{{ certificate.ownerIdCode }}</p>
								</td>
								<td>{{ certificate.issued | date: "dd.MM.yyyy" }}</td>
								<td>
									<a
										[routerLink]="
											'/tunnistused/lõpudokumendid/' + certificate.id
										"
										[queryParams]="{ avalikustatud: true }"
										[attr.data-test-id]="'certificate-final-documents-published-certificate-open-link-' + certificate.id"
									>
										{{ "button.open" | translate }}
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</scrollableContent>
			</ng-template>
		</ng-template>
		<ng-template #logInButton>
			<button htm-button (click)="logIn('/tunnistused/lõpudokumendid')"
					data-test-id="certificate-final-documents-generic-login-link">
				{{ "certificates.login_to_view" | translate }}
			</button>
		</ng-template>
	</accordion-item>
</accordion>
