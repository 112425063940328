<div class="sectors__list-body" *ngIf="data" areaModal>
	<div class="row sectors--row" *ngIf="data.length">
		<ng-container *ngFor="let elem of data; let i = index">
			<div
				#content
				class="md-4 xs-6 sectors--col"
				[ngClass]="{
					'col-3': !reducedColumnSet,
					'col-4': reducedColumnSet
				}"
			>
				<div
					class="sector__wrapper"
					(click)="modalOpen(i)"
					[class.active]="i == modal.index"
					[id]="'block_' + i"
					(keydown)="handleItemBlur($event)"
					[attr.data-item-index]="'' + i"
				>
					<span class="sector__wrapper--pic">
						<img
							*ngIf="elem.fieldOskaFieldPicture | arrayFirst as IEntry"
							alt=""
							[title]="IEntry.title"
							[src]="IEntry.url"
						/>
						<img
							alt=""
							title="{{ 'errors.img_missing' | translate }}"
							*ngIf="!(elem.fieldOskaFieldPicture | arrayFirst)"
							src="../../assets/img/1208x680.png"
						/>
						<span class="overlay"></span>
					</span>
					<button
						attr.aria-label="{{ elem.title }}"
						class="sector__wrapper--title"
						[id]="'block_button_' + i"
						[attr.data-item-button-index]="'' + i"
					>
						<span class="sector__title">{{ elem.title }}</span>
					</button>
				</div>
			</div>
			<div class="col-12" *ngIf="modal && modal.position == i" (keydown)="storeEventTabState($event)" (focusout)="handleModalBlur($event)"  #blockModal>
				<div
					class="modal links-{{ links }}"
					id="modal"
					attr.aria-label="{{ 'oskaProfessions.card_opened' | translate }}"
				>
					<div class="modal__header">
						<div class="modal__header__col">
							<h1>
								<a
									id="dropdown-list-modal-title"
									[routerLink]="modal.path"
									[attr.data-modal-index]="'' + modal.index"
									>{{ modal.title }}</a
								>
							</h1>
						</div>
						<!--/modal__header__col-->
						<div class="modal__header__col">
							<button
								htm-button
								theme="icon"
								attr.aria-label="{{ 'oskaProfessions.card_close' | translate }}"
								(click)="modalClose()"
							>
								<icon glyph="x" size="medium"></icon>
							</button>
						</div>
						<!--/modal__header__col-->
					</div>
					<div class="modal__content">
						<div class="row">
							<div class="col-8 xs-12">
								<h2 *ngIf="!excludeTitle">
									{{ 'oskaProfessions.general_info' | translate }}
								</h2>
								<p *ngIf="modal.fieldIntroduction">
									{{ modal.fieldIntroduction }}
								</p>
								<a
									aria-describedby="dropdown-list-modal-title"
									[routerLink]="modal.path"
									>{{ 'button.read_on' | translate }}</a
								>
							</div>
							<!--/col-->
							<div class="col-4 xs-12">
								<div class="separator xs-hide"></div>

								<hr class="xs-show" aria-hidden="true" />

								<div class="modal__padding">
									<ng-container
										*ngIf="
											!(modal.fieldOskaFieldPicture | arrayFirst) &&
											!modal.videoUrl
										"
									>
										<div
											class="modal__image"
											[ngStyle]="{
												'background-image': 'url(../../assets/img/1208x680.png)'
											}"
										>
											<img
												alt=""
												title="{{ 'errors.img_missing' | translate }}"
												src="../../assets/img/1208x680.png"
											/>
										</div>
										<!--/modal__image-->
									</ng-container>

									<ng-container
										*ngIf="
											(modal?.fieldOskaFieldPicture | arrayFirst)?.url &&
											!modal.videoUrl
										"
									>
										<ng-container
											*ngIf="
												modal?.fieldOskaFieldPicture | arrayFirst as Picture
											"
										>
											<div
												class="modal__image"
												[ngStyle]="{
													'background-image': 'url(' + Picture.url + ')'
												}"
											>
												<img
													[src]="Picture.url"
													alt=""
													[title]="Picture.title"
												/>
											</div>
											<!--/modal__image-->
										</ng-container>
									</ng-container>

									<ng-container *ngIf="modal.videoUrl">
										<div class="modal__video">
											<iframe
												attr.aria-label="{{
													'generic.youtube_video' | translate
												}}"
												title="{{ 'generic.youtube_video' | translate }}"
												[src]="modal.videoUrl | iframe"
												allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
												allowfullscreen
											></iframe>
										</div>
										<!--/modal__video-->
									</ng-container>
								</div>
								<!--/modal__padding-->
							</div>
							<!--/col-->
						</div>
						<hr aria-hidden="true" />

						<ng-container *ngIf="(modal.list || modal.subentities)?.length > 0">
							<h2>{{ 'oska.professions_and_jobs' | translate }}</h2>
							<ul>
								<li *ngFor="let item of modal.list || modal.subentities">
									<a
										*ngIf="item?.title"
										[routerLink]="item?.path"
										[title]="item?.title"
									>
										{{ item.title }}
									</a>
								</li>
							</ul>
						</ng-container>

						<ng-container
							*ngIf="modal.fieldLinks?.length"
						>
							<ul>
								<li *ngFor="let item of modal.fieldLinks">
									<a
										*ngIf="item?.title"
										[href]="
											item.fieldWebpageLink
												? item.fieldWebpageLink[0]?.uri
												: item?.path
										"
										[title]="item?.title"
										target="_blank"
									>
										{{ item.title }}
									</a>
								</li>
							</ul>
						</ng-container>

					</div>
					<!--/modal__content-->
				</div>
			</div>
		</ng-container>
	</div>
</div>
<div class="sectors__list-body" *ngIf="errMessage && !data">
	<div class="text-center actions__error">
		<p role="alert">{{ 'errors.request' | translate }}</p>
	</div>
</div>
