<ng-container *ngIf="mappedData">
  <ng-container *ngFor="let keyVal of orderedKeys">
    <ng-container *ngIf="keyVal !== 'fieldBlocks' && keyVal !== 'fieldEhisLinks'">
      <block theme="sidebar" *ngIf="mappedData[keyVal] && collection[keyVal] && !(keyVal === 'fieldJobs' && !mappedData[keyVal].length) && !mappedData[keyVal]?.conditionalRender">
        <block-title *ngIf="!titleLess[collection[keyVal]]" [ngClass]="'type-'+[collection[keyVal]]">
          <h2>{{ (keyVal === 'event' ? 'event.label' : keyVal) | translate }}</h2>
          <div *ngIf="keyVal === 'notifications' && mappedData.notifications.unread > 0" class="unreadMessages">
            {{mappedData.notifications.unread}}
          </div>
        </block-title>
        <block-content [ngSwitch]="collection[keyVal]">
          <sidebar-gdpr *ngSwitchCase="'gdpr'" [data]="mappedData[keyVal]"></sidebar-gdpr>
          <sidebar-notifications *ngSwitchCase="'notifications'" [data]="mappedData[keyVal]"></sidebar-notifications>
          <sidebar-links *ngSwitchCase="'links'" [data]="mappedData[keyVal]"></sidebar-links>
          <sidebar-categories *ngSwitchCase="'categories'" [data]="mappedData[keyVal]"></sidebar-categories>
          <sidebar-contact *ngSwitchCase="'contact'" [data]="mappedData[keyVal]" [type]="type"></sidebar-contact>
          <sidebar-actions *ngSwitchCase="'actions'" [data]="isArray(mappedData[keyVal]) ? mappedData[keyVal] : [mappedData[keyVal]]"></sidebar-actions>
          <sidebar-articles *ngSwitchCase="'articles'" [data]="mappedData[keyVal]"></sidebar-articles>
          <sidebar-articles *ngSwitchCase="'news'" [data]="mappedData[keyVal]"></sidebar-articles>
          <sidebar-data *ngSwitchCase="'data'" [data]="mappedData[keyVal]"></sidebar-data>
          <sidebar-location *ngSwitchCase="'location'" [data]="mappedData[keyVal]"></sidebar-location>
          <sidebar-facts *ngSwitchCase="'facts'" [data]="mappedData[keyVal]" [theme]="type"></sidebar-facts>
          <sidebar-progress *ngSwitchCase="'progress'" [data]="mappedData[keyVal]"></sidebar-progress>
          <sidebar-register *ngSwitchCase="'register'" [data]="mappedData[keyVal]" [pageData]="data"></sidebar-register>
          <sidebar-events *ngSwitchCase="'events'" [data]="mappedData[keyVal]"></sidebar-events>
          <sidebar-finaldocument-access *ngSwitchCase="'finalDocumentAccess'" [data]="mappedData[keyVal]"></sidebar-finaldocument-access>
          <sidebar-finaldocument-history *ngSwitchCase="'finalDocumentHistory'" [data]="mappedData[keyVal]"></sidebar-finaldocument-history>
          <sidebar-finaldocument-download *ngSwitchCase="'finalDocumentDownload'" [data]="mappedData[keyVal]"></sidebar-finaldocument-download>
          <sidebar-download-file *ngSwitchCase="'downloadFile'" [data]="mappedData[keyVal]"></sidebar-download-file>
        </block-content>
      </block>
      <ng-container *ngIf="mappedData[keyVal]?.conditionalRender">
        <sidebar-download-file *ngIf="collection[keyVal] === 'downloadFile'" [data]="mappedData[keyVal]"></sidebar-download-file>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(keyVal === 'fieldBlocks' || keyVal === 'fieldEhisLinks') && mappedData[keyVal]">
      <sidebar-links [data]="mappedData[keyVal]"></sidebar-links>
    </ng-container>
  </ng-container>
  <feedback *ngIf="feedbackNid" [nid]="feedbackNid"></feedback>
</ng-container>
