<ng-container *ngIf="type === 1">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1280 79"
		style="enable-background: new 0 0 1280 79"
		xml:space="preserve"
	>
		<path
			class="st0"
			d="M0,79h1280V18c-17.5,7-49,21-121,11.6S822.9-0.2,648,5C502.2,9.3,349.7,41.6,206,29.6c-91.3-7.5-155.9-2.1-206,6.8"
		/>
	</svg>
</ng-container>

<ng-container *ngIf="type === 2">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1280 120"
		style="enable-background: new 0 0 1280 120"
		xml:space="preserve"
	>
		<path
			class="st0"
			d="M1280,119H616L0,120V47V26.5C44.7,9.9,94.5,5,142.6,2.5c121.1-6.2,242.3,0.4,363.5,6.9
		c58.8,3.2,117.6,6.4,176.4,8.1c145.5,4.3,267.9,0.3,398.4-4c63.3-2.1,128.5-4.2,199.1-5.5v39L1280,119z"
		/>
	</svg>
</ng-container>

<ng-container *ngIf="type === 3">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1216 254.3"
		style="enable-background: new 0 0 1216 254.3"
		xml:space="preserve"
	>
		<path
			class="st0"
			d="M982.3,3.8c-29.5-0.7-73.6-1.8-88.4-1.9c-6.7-0.1-275-3-350.7-1.5c-58.8,1.2-117.6,3.3-176.4,5.5
		c-121.2,4.5-242.4,9-363.5,4.7c-1.1,0-2.2-0.1-3.3-0.1v243.8h1216V7.9c-8.1,0-16.2-0.1-24.3-0.2C1121.9,6.8,1052.1,5.3,982.3,3.8z"
		/>
	</svg>
</ng-container>

<ng-container *ngIf="type === 4">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1149 274"
		style="enable-background: new 0 0 1149 274"
		xml:space="preserve"
	>
		<path
			class="st0"
			d="M1094,0c-9,0.4-17.9,0.7-26.9,1.1C1014.2,3,961.2,4.8,908.3,6.9c-42.6,1.7-85.2,3.5-127.8,5.5
	c-77.7,3.5-155.5,4.8-233.4,3.9c-88.1-1.1-176.2-3.2-264.3-5C196.7,9.4,110.6,7.3,24.5,5.7C16.3,5.6,8.1,5.7,0,6v268h1149
	c0-91.3,0-182.7,0-274C1130.7,0,1112.3,0,1094,0z"
		/>
	</svg>
</ng-container>

<ng-container *ngIf="type === 5">
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 59" fill="none">
		<path d="M1280 1.25384C1109.38 -4.64868 938.59 11.9414 767.7 16.736C551.6 22.8487 335.5 10.0503 119.3 6.32542C79.2 5.61865 37.53 5.56134 0 17.7102V59H1280V1.25384Z" fill="#383A45"/>
	</svg>
</ng-container>
