
import { Component, Input, HostBinding, ElementRef, input } from '@angular/core';
import { RippleService } from '@app/_services';

@Component({
  selector: '[htm-button]',
  template: '<span class="button--label"><ng-content></ng-content></span>',
  styleUrls: ['./button.styles.scss'],
  host: {
    '(mousedown)': 'animateRipple($event)',
    '(keydown.enter)' : 'animateRipple($event)',
  },
  standalone: true
})

export class ButtonComponent {
  @Input() class: string;
  @Input() theme: string = 'default';
  @Input() noPadding?: boolean;
  disabledClass = input();

  constructor(
    private ripple: RippleService,
    // Provide access if button is retrieved via viewchild etc
    public buttonRef: ElementRef
  ) {}


  @HostBinding('class') get hostClasses(): string {
    const classes = [
      this.theme ? `button--${this.theme}`: undefined,
      this.class,
      this.noPadding ? 'button--no-padding' : undefined,
      this.disabledClass() ? 'button--disabled' : undefined
    ]?.filter(Boolean);
    return classes?.join(' ');
  }

  animateRipple($event) {
    if (this.theme !== 'plain') this.ripple.animate($event, 'light');
  }
}
