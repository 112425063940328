<ng-container *ngIf="{value: loginStatus | async};let loginStatus">
  <div class="header__auth-wrapper">
    <button
      *ngIf="loginStatus?.value; else loggedOut"
      theme="plain"
      (click)="toggleAuthMenu()"
      htm-button
      class="header__auth-menu-toggle"
      #authMenuToggle
      data-test-id="header-auth-menu-toggle-button"
    >
      <span
        class="label--logged"
        [class.enterprise]="auth?.user?.role?.current_role?.type !== 'natural_person'"
      >
        <icon
          aria-hidden="true"
          glyph="user"
          class="icon-user medium"
        ></icon>
        <span class="header__auth-user">
          {{ 'button.role' | translate }}:
          <b>
            <ng-container
              *ngIf="auth?.user?.role?.current_role?.type === 'natural_person';else juridicalPerson"
            >
              <ng-container
                *ngIf="auth?.user?.firstname && auth?.user?.lastname"
                >{{ auth?.user?.firstname }} {{ auth?.user?.lastname
                }}</ng-container
              >
              <ng-container
                *ngIf="!auth?.user?.firstname && !auth?.user?.lastname"
                >{{ auth?.user?.username }}
              </ng-container>
            </ng-container>
            <ng-template #juridicalPerson>
              {{ auth?.user?.role?.current_role?.data?.nimi }}
            </ng-template>
          </b>
        </span>
        <icon
          aria-hidden="true"
          glyph="chevron-down"
          class="icon-chevron medium"
        ></icon>
      </span>
    </button>
    <div *ngIf="authMenuOpen" class="header__logout" #authMenu>
      <button htm-button theme="logout" (click)="handleLogout()" data-test-id="header-auth-logout-button">
        {{ 'button.logout' | translate }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #loggedOut>
  <button
    id="headerLogin"
    htm-button
    theme="plain"
    attr.aria-label="{{ 'button.login' | translate }}"
    data-test-id="header-auth-login-button"
    class="header__login"
    [class.active]="modalService.modalOpened['login']"
    (click)="openLoginModal($event)"
    #loginButton
  >
    <icon
      aria-hidden="true"
      glyph="user"
      class="icon-user medium"
    ></icon>
    <span class="header__login-text">{{ "button.login" | translate }}</span>
  </button>
</ng-template>

<htm-modal
  id="login"
  modalTitle="{{ 'button.login' | translate }}"
  [bottomAction]="false"
>
  <ng-template id="login">
    <alerts id="login-modal" small></alerts>
    <loader *ngIf="loading"></loader>
    <div class="login__methods">
      <div class="login__wrapper" *ngIf="!loading">
        <ng-container *ngIf="!mobileIdChallengeId; else mobileIdWrapper">
          <ng-container
            *ngFor="let method of authMethods; let first = first"
          >
            <labeled-separator
              *ngIf="!first"
              type="login"
              label="{{ 'label.or' | translate }}"
            ></labeled-separator>
            <div class="login__method" *ngIf="method === 'harid'">
              <button (click)="harIdLogin()" htm-button data-test-id="header-auth-harid-login-button">
                {{ "login_harid" | translate }}
              </button>
            </div>
            <div class="login__method" *ngIf="method === 'tara'">
              <button (click)="taraLogin()" htm-button data-test-id="header-auth-tara-login-button">
                {{ "login_tara" | translate }}
              </button>
            </div>
            <div class="login__method" *ngIf="method === 'mobile_id'">
              <div>
                <h2>{{ "login_mobile" | translate }}</h2>
              </div>
              <div>
                <form [formGroup]="mobileIdForm">
                  <formItem
                    ariaLabel="{{ 'dashboard.contactPhone' | translate }}"
                    formControlName="phoneNumber"
                    title="{{ 'dashboard.contactPhone' | translate }}"
                    testId="header-auth-mobile-id-phone-input"
                  >
                  </formItem>
                </form>
              </div>
              <div>
                <button (click)="mobileIdLogin()" htm-button data-test-id="header-auth-mobile-id-login-button">
                  {{ "button.login" | translate }}
                </button>
              </div>
            </div>
            <form
              class="login__method"
              *ngIf="method === 'basic'"
              [formGroup]="loginForm"
            >
              <div>
                <formItem
                  ariaLabel="{{ 'login.username' | translate }}"
                  type="text"
                  formControlName="username"
                  browserAutocomplete="username"
                  title="{{ 'login.username' | translate }}"
                  testId="header-auth-basic-login-username-input"
                >
                </formItem>
              </div>
              <div>
                <formItem
                  ariaLabel="{{ 'login.password' | translate }}"
                  type="password"
                  formControlName="password"
                  browserAutocomplete="password"
                  title="{{ 'login.password' | translate }}"
                  testId="header-auth-basic-login-password-input"
                >
                </formItem>
              </div>
              <div>
                <button
                  (blur)="modalService.focusLock()"
                  htm-button
                  (click)="basicLogin()"
                  data-test-id="header-auth-basic-login-submit-button"
                >
                  {{ "button.login" | translate }}
                </button>
              </div>
            </form>
          </ng-container>
        </ng-container>
        <ng-template #mobileIdWrapper>
          <div class="mobile-handshake">
            <p>{{ 'login.mobile_id_check' | translate }}</p>
            <h1>{{ this.mobileIdChallengeId }}</h1>
            <p class="mb-0">{{ 'login.mobile_id_pin1' | translate }}</p>
            <p class="mt-0">{{ 'login.mobile_id_waiting' | translate }}</p>
            <button (click)="mobileIdCancel()" htm-button theme="plain" data-test-id="header-auth-mobile-id-cancel-button">
              {{ 'button.cancel' | translate }}
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</htm-modal>
