<h1>{{ "frontpage.document_check" | translate }}</h1>
<div class="introduction" innerHtml="{{ 'frontpage.document_check_body' | translate }}"></div>
<!--/introduction-->
<form class="certificate-document-check__form" [formGroup]="model" (submit)="submit()">
	<formItem required name="document_id" type="text" formControlName="document_id"
		testId="certificate-document-check-document-id-field"
		title="{{ 'frontpage.document_id' | translate }}"
		[error]="documentError()"
		[errorMessage]="documentError()"
		>
	</formItem>
	<div class="certificate-document-check__fields">
		<formItem name="id_code" type="text" formControlName="id_code"
			testId="certificate-document-check-id-code-field"
			title="{{ 'frontpage.document_owner_id' | translate }}"
			[error]="idError()"
			[errorMessage]="idError()"
			[disabled]="model.controls.id_code.disabled"/>
		<input-date name="date" [minDate]="minDate" formControlName="date"
			testId="certificate-document-check-date-field"
			title="{{ 'frontpage.document_date' | translate }}"
			[error]="dateError()"
			[errorMessage]="dateError()"
			[validate]="true"
			[disabled]="model.controls.date.disabled"/>
	</div>
<button htm-button type="submit" theme="default"
		data-test-id="certificate-document-check-submit-button">
	{{ "button.search" | translate }}
</button>
</form>
<div #scrollTarget class="footer">
	<loader *ngIf="loading"></loader>
	<alerts small="true" id="documentCheck"></alerts>
</div>
