import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	HostBinding,
	HostListener,
	OnInit,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import {
	AuthService,
	LanguageService,
	ModalService,
	SettingsService,
	SidemenuService,
} from '@app/_services';
import { environment } from '@env/environment';
import { IMenuItem } from './sidemenu.model';

@Component({
	selector: 'sidemenu',
	templateUrl: './sidemenu.template.html',
	styleUrls: ['./sidemenu.styles.scss'],
})
export class MenuComponent implements OnInit {
	public isVisible: boolean;
	public readerVisible = false;
	public version: any = environment.VERSION;

	private initialSub = false;
	private focusBounce: any;
	private visibilityBounce: any;
	protected data = this.sidemenuService.$data;
	protected isLoading = this.sidemenuService.isLoading;

	@ViewChild('sidemenuCloser', { static: false, read: ElementRef })
	private closeBtn: ElementRef;

	@HostBinding('class') get hostClasses(): string {
		const visibilityString = this.isVisible ? ' is-visible' : '';
		const isAnyModalOpen = Object.values(
			this.modalService.modalOpened ?? {}
		)?.some((modal) => modal);
		const modalsString = isAnyModalOpen ? ' sidemenu--modal-open' : '';
		return `sidemenu${visibilityString}${modalsString}`;
	}

	@HostBinding('style.visibility') get readerVisbility(): string {
		return this.readerVisible ? 'visible' : 'hidden';
	}

	constructor(
		private sidemenuService: SidemenuService,
		private settings: SettingsService,
		private auth: AuthService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		// Being used in template
		private languageService: LanguageService,
		private modalService: ModalService,
		private destroyRef: DestroyRef
	) {}

	public closeSidemenu(): void {
		this.sidemenuService.close();
	}

	private subscribeToLanguage(): void {
		this.settings.activeLang$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.sidemenuService.languageWasChanged.set(true);
					this.sidemenuService.getData();
				},
				complete: () => this.sidemenuService.languageWasChanged.set(false),
			});
	}

	private subscribeToRouter(): void {
		this.router.events
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					if (!event.url.endsWith('#content') && this.initialSub) {
						this.sidemenuService.resetPageFocus();
					}
					this.sidemenuService.makeActive();
					this.cdr.markForCheck();
				}
			});
	}

	private subscribeToAuth(): void {
		this.auth.isAuthenticated
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.sidemenuService.getData();
			});
	}

	private subscribeToService(): void {
		this.sidemenuService.isVisibleSubscription
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.isVisible = value;
				clearTimeout(this.visibilityBounce);
				if (!value && this.initialSub) {
					this.sidemenuService.wasClosed.set(true);
				}
				if (value) {
					this.readerVisible = true;
					clearTimeout(this.focusBounce);
					if (this.initialSub && !this.sidemenuService.initialAutoOpen()) {
						this.focusBounce = setTimeout(
							() => this.closeBtn.nativeElement.focus(),
							100
						);
					}
				} else {
					this.visibilityBounce = setTimeout(
						() => (this.readerVisible = false),
						200
					);
				}
				// Ignore the initial state
				this.initialSub = true;
				if (this.sidemenuService.initialAutoOpen()) {
					this.sidemenuService.initialAutoOpen.set(false);
				}
			});
	}

	/**
	 * Closes all other first-level menus except the one that was opened.
	 *
	 * @param $event `IMenuItem` that was opened
	 */
	public hideOthers($event: IMenuItem): void {
		const newMenu = this.sidemenuService.$data.value;
		newMenu?.forEach((item) => {
			if (item !== $event && item?.expanded) {
				item.expanded = false;
			}
		});
		this.sidemenuService.setData(newMenu);
	}

	public ngOnInit(): void {
		this.subscribeToAuth();
		this.subscribeToService();
		this.subscribeToRouter();
		this.subscribeToLanguage();
	}
}
