<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'default'">
    <ng-container *ngFor="let item of chartData; let last = last;">
      <h2>{{ item.graphTitle | titleCase }}</h2>
      <google-chart [data]="item"></google-chart>
      <i class="graph__source" *ngIf="item.graphSource">
        {{ 'oska.graph_source' | translate }}: {{ item.graphSource | titleCase }}
      </i>
      <hr *ngIf="!last" aria-hidden="true">
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'filter'">
    <ng-container *ngFor="let item of data; let last = last;">

      <h3>{{ item.graphTitle | titleCase }}</h3>
      <div class="graph__text" [innerHTML]="item.graphText"></div>

      <ng-container *ngIf="item && item.filters">
        <div class="formItems" [ngClass]="{'formItems--3': wide, 'formItems--2': !wide}">
          <ng-container *ngFor="let select of item.filters; let i = index">

            <div *ngIf="select.options.length > 1">
              <ng-container *ngIf="select.multiple && filters[item.id]">
                <formItem
                  type="multi-select"
                  (onChange)="getGraphData(item.id)"
                  [(ngModel)]="filters[item.id][select.key]"
                  [title]="select.key"
                  [testId]="'chart-filters-select-' + select.key"
                  [options]="select.options">
                </formItem>
              </ng-container>
              <ng-container *ngIf="!select.multiple && filters[item.id]">
                <formItem
                  (onChange)="getGraphData(item.id)"
                  type="select"
                  [(ngModel)]="filters[item.id][select.key]"
                  [title]="select.key"
                  [testId]="'chart-filters-select-' + select.key"
                  [options]="select.options">
                </formItem>
              </ng-container>
            </div><!--/col-->
          </ng-container>
        </div><!--/row-->

        <div class="chart-wrapper" [class.chart-wrapper--shorter__bottom]="item.graphSource">

          <ng-container *ngIf="filtersData[item.id]">
            <div class="chart-wrapper__loader" *ngIf="filtersData[item.id].loading">
              <loader></loader>
            </div><!--/chart-wrapper__loader-->

            <google-chart
              [data]="filtersData[item.id]"
              *ngIf="filtersData[item.id].dataTable && !filtersData[item.id].loading"
            ></google-chart>

          </ng-container>
        </div><!--/chart-wrapper-->

      </ng-container>
      <i class="graph__source" *ngIf="item.graphSource">
        {{ 'oska.graph_source' | translate }}: {{ item.graphSource | titleCase }}
      </i>
      <hr *ngIf="!last" aria-hidden="true">

    </ng-container>

  </ng-container>

</ng-container>
