<ng-container *ngIf="videoArray">
  <div class="video__entity" [class.video__entity--responsive]="responsive" *ngFor="let video of videoArray; let i = index">
    <div class="video-container" *ngIf="embeddedInputs && embeddedInputs.length && embeddedInputs[i]" [innerHtml]="embeddedInputs[i]"></div>
    <div class="video-container" *ngIf="embedFailed && video.videoId">
      <iframe *ngIf="video.finalUrl" attr.aria-label="{{ 'generic.youtube_video' | translate }}" title="{{ 'generic.youtube_video' | translate }}"
        [src]="video.finalUrl" allowfullscreen (load)="onLoad.emit()" [tabindex]="untabbable ? -1 : undefined" [attr.aria-hidden]="untabbable"></iframe>
    </div>
    <div *ngIf="video.videoDescription && embedFailed" class="video-alt-text">{{video.videoDescription}}</div>
    <div *ngIf="video.videoDescription && embeddedInputs.length && embeddedInputs[i]" class="video-alt-text">{{video.videoDescription}}</div>
  </div><!--/video__entity-->
</ng-container>
