import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SettingsService } from '@app/_services';
import FieldVaryService from '@app/_services/FieldVaryService';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'relatedEvents',
	templateUrl: 'relatedEvents.template.html',
	styleUrls: ['relatedEvents.styles.scss'],
})
export class RelatedEventsComponent implements OnInit, OnDestroy {
	@Input() nid: number;
	@Input() groupID: number;
	public data = [];
	public loading: boolean = true;
	private subscription: Subscription = new Subscription();
	private routerSub: Subscription;

	constructor(
		private http: HttpClient,
		private settings: SettingsService,
		private router: Router
	) {}

	private parseResponse(response): void {
		this.data = response['entities']?.map((item) => FieldVaryService(item)) || [];
	}

	private getData(): void {
		this.loading = true;
		const params = {
			_format: 'json',
			content_type: 'similarEvents',
			nid: this.nid,
			groupID: this.groupID,
			lang: this.settings.currentAppLanguage,
		};

		this.subscription = this.http
			.get(`${this.settings.url}/api/list`, { params })
			.subscribe((response) => {
				this.parseResponse(response);
				this.loading = false;
			});
	}

	ngOnInit() {
		this.getData();
		this.routerSub = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				document.querySelector('.app-content').scrollTop = 0;
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.routerSub.unsubscribe();
	}
}
