import {ChangeDetectorRef, Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@app/_services';

/**
 * @deprecated Use feedback-new in new views
 */
@Component({
  selector: 'feedback',
  templateUrl: 'feedback.template.html',
  styleUrls: ['feedback.styles.scss'],
})
export class FeedbackComponent {

  @Input() nid: number | string;
  public feedbackError: boolean = false;
  public status: String = 'vote';
  public values: Object = {};

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private settings: SettingsService,
  ) {	}

  public cancel(): void {
    this.values['vote'] = false;
    this.values['comment'] = '';
    this.status = 'vote';
    this.feedbackError = false;
  }

  public vote(flag: boolean): void {
    this.values['vote'] = flag;
    if (flag) {
      this.status = 'add_comment';
    } else {
      this.status = 'add_comment';
    }
  }

  public sendVote() {

    if (!this.values['vote']) {
      this.feedbackError = !this.values['comment'] || this.values['comment'] === '';
    }

    if (this.feedbackError) {
      return false;
    }

    this.status = 'response';

    const data = {
      nid: this.nid,
      type: this.values['vote'] ? 1 : 0,
      message: this.values['comment'] || '',
    };

    const subscribe = this.http.post(`${this.settings.url}/feedback?_format=json`, data).subscribe(
      (response) => {
        subscribe.unsubscribe();
      });
  }

}
