import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, ModalService } from '@app/_services';
import { getLangCode } from "@app/_core/router-utility";
import { TitleService } from '@app/_services/TitleService';
import { TranslateService } from '@app/_modules/translate/translate.service';

@Component({
  selector: 'notFound',
  templateUrl: 'notFound.template.html',
  styleUrls: ['notFound.styles.scss'],
})
export class NotFoundComponent implements OnInit {

  public redirectUrl: string;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private authService: AuthService,
    private titleService: TitleService,
    private translateService: TranslateService,
	) {
    this.redirectUrl = this.route.snapshot.queryParamMap.get('redirect');
  }

  ngOnInit() {
    this.titleService.setTitle(this.translateService.get('not_found.title'));
    setTimeout(() => {
			if (this.redirectUrl) {
        if (this.authService.isLoggedIn()) {
          this.authService.useRedirectUrl(true);
          return;
        }
				globalThis?.sessionStorage?.setItem('redirectUrl', this.redirectUrl);
        this.authService.triggerLoginModal();
			}
		});

		this.router.events.subscribe({
			next: (event) => {
				if (event instanceof NavigationEnd) {
					this.loading = true;
					setTimeout(() => this.loading = false)
				}
			},
		});
  }

  action() {
    if (this.redirectUrl) {
      this.modalService.open('login');
      sessionStorage.setItem('redirectUrl', this.redirectUrl);
    } else {
			getLangCode() === 'et' ? this.router.navigate(['/']) : this.router.navigate([`/${getLangCode()}`])
    }
  }
}
