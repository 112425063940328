import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { AssetsModule } from "@app/_assets";
import { findTranslation, translatePath } from "@app/_core/router-utility";
import { TranslateModule } from "@app/_modules/translate";
import { AnalyticsService, ModalService } from "@app/_services";
import { ButtonComponent } from "@app/components/button/button.component";
import { IconComponent } from "@app/components/icon/icon.component";
import { Observable, Subject, filter, map, takeUntil } from "rxjs";

@Component({
	selector: "htm-header-search",
	templateUrl: "header-search.template.html",
	styleUrls: ["header-search.styles.scss"],
	standalone: true,
	imports: [AssetsModule, AsyncPipe, NgIf, TranslateModule, IconComponent, ButtonComponent, FormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSearchComponent implements OnDestroy {
	destroy$ = new Subject<void>();
	searchEnabled$ = this.getSearchEnabled();
	searchTerm = "";
	constructor(private router: Router, private analytics: AnalyticsService, protected modalService: ModalService) {}
	ngOnDestroy(): void {
		this.destroy$.next();
	}
	getSearchEnabled(): Observable<boolean> {
		const searchTranslation = findTranslation("otsing");
		// Hide search on search pages
		return this.router.events.pipe(
			// Filter out unnecessary events
			filter((event) => {
				return event instanceof NavigationEnd;
			}),
			// Convert event into the boolean we want
			map((event: NavigationEnd) => {
				const pathSplit = event?.url?.split("?")?.[0]?.split("/");
				// Check whether our current path matches search translations
				const isSearchEnabled =
					searchTranslation?.includes(pathSplit?.[1]) ||
					searchTranslation?.includes(pathSplit?.[2]);
				return !isSearchEnabled;
			}),
			// Kill subscription when component is destroyed
			takeUntil(this.destroy$)
		);
	}

	searchRoute(term = this.searchTerm) {
		const url = `${translatePath("/otsing")}?term=${term}`;
		this.searchTerm = "";
		this.analytics.trackEvent("homeSearch", "submit", term);
		this.router.navigateByUrl(url);
	}
}
