import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlSegment } from '@angular/router';
import { LanguageCodes } from '@app/_services';
import { findSegmentLanguageCode, getLangCode } from './router-utility';
let prevSegments = [] as UrlSegment[];

export const routeRepair: CanMatchFn = (_, segments) => {
	if (!segments.length) return true;
	try {
		const sameSegments = segments.every(
			(value, i) => prevSegments?.[i]?.path === value?.path
		);
		if (sameSegments) return true;

		const router = inject(Router);
		const expectedLangCode = findSegmentLanguageCode(segments?.[0]?.path);
		const currentLangCode = getLangCode();
		if (expectedLangCode && expectedLangCode !== currentLangCode) {
			const joinedSegments = segments.join('/');
			const newLangPrefix =
				expectedLangCode === LanguageCodes.ESTONIAN
					? undefined
					: `/${expectedLangCode}/`;
			prevSegments = segments;
			return router.parseUrl(`${newLangPrefix ?? ''}${joinedSegments}`);
		}
	} catch (e) {
		console.error('Error during route parsing/repair', e);
	}
	return true;
};
