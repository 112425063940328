import { whitelistedQueryParams } from './models/query-params-data';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { GenericObject } from './models/interfaces/utils.model';
import { TranslateService } from '@app/_modules/translate/translate.service';

export function focus(id: string) {
	setTimeout(() => {
		const elem = document.getElementById(id);
		if (elem) {
			document.getElementById(id).focus();
		}
	}, 0);
}

export function arrayOfLength(len) {
	return Array(parseInt(len, 10))
		.fill(0)
		.map((x, i) => i);
}

export function create2DArray(
	rows: number = 2,
	columns: number = 3,
	filler: string | number = '',
): string[][] {
	return Array(rows)
		.fill('')
		.map(() => Array(columns).fill(filler));
}

export function parseUnixDate(input) {
	const tmpDate = new Date(input * 1000);
	const year = tmpDate.getFullYear();
	const month = tmpDate.getMonth();
	const day = tmpDate.getDate();
	return new Date(year, month, day, 0, 0).getTime();
}

export function paramsExist(route: ActivatedRoute): boolean {
	return !!Object.values(route.snapshot.queryParams).filter((val) => val)
		?.length;
}

export function scrollElementIntoView(selector: string): void {
	setTimeout(() => {
		document.querySelector(selector)?.scrollIntoView({ behavior: 'smooth' });
	});
}

export function slugifyTitle(title: string): string {
	if (!title) return '';
	return title
		.toLowerCase()
		.replace(/span/g, '')
		.replace(/<a href=".+?>/g, '')
		.replace(/<\/a>/g, '')
		.replace(/ /g, '-')
		.replace(/[^a-z0-9üõöä]+/gim, '-');
}

/**
 * @description Parses date object into string
 * @param date Date object
 * @returns string in the format DD.MM.YYYY
 */
export const parseDateToString = (date: Date): string => {
	if (!date?.getTime()) {
		return '';
	}
	const day = date.getDate()?.toString()?.padStart(2, '0');
	const month = (date.getMonth() + 1)?.toString()?.padStart(2, '0');
	// Turn date into format DD.MM.YYYY, adding padding if necessary.
	return `${day}.${month}.${date.getFullYear()}`;
};

/**
 * @description Parses string into date object
 * @param stringInput in the format DD.MM.YYYY
 * @returns string in the format DD.MM.YYYY
 */
export const parseStringIntoDate = (stringInput: string): Date =>
	moment(stringInput, 'DD.MM.YYYY').toDate();

/**
 * Finds an entity from objects
 * @param obj - key:value object
 * @param path - eq. name or name.name2 or name.name2.name3
 * @returns - value of given path
 */
export const findFromObj = <ReturnType = any>(
	obj: GenericObject,
	path: string,
): ReturnType => {
	return `${path}`
		?.replace(/\[|\]\.?/g, '.')
		?.split('.')
		?.filter((pathSegment: string) => pathSegment)
		?.reduce((acc: GenericObject, val: string) => {
			// Handle array access
			if (Array.isArray(acc)) {
				// Remove brackets
				const index = val?.replace(/[[\]]/g, '');
				if (!Number.isNaN(parseInt(index))) {
					return acc?.[index];
					// Magic functionality for defining the specific characters to join array by
				} else if (index?.startsWith('join')) {
					const joinString = index?.split(':')?.[1];
					return acc?.join(joinString);
				}
			}
			return acc?.[val];
		}, obj);
};

/**
 *
 * @param data Any generic object
 * @param value string path for the value
 * @param alternateField alternate path to check for value
 * @param alternateFields alternate paths to check for value
 * @param interchangeableWith alternate key for first level object, likely not relevant in most cases
 * @returns found value or undefined
 */
export const getUncertainDataField = ({
	data,
	value,
	alternateField = '',
	alternateFields = [],
	interchangeableWith = '',
}: {
	data: GenericObject;
	value: string;
	alternateField?: string;
	alternateFields?: string[];
	interchangeableWith?: string;
}): unknown => {
	const pathArray = value?.split('.');
	const contentPrefix = pathArray?.[0];
	const contentSuffix = pathArray?.[pathArray?.length - 1];
	// Value could be in different object
	const interchangeableValue = interchangeableWith
		? value?.replace(contentPrefix, interchangeableWith)
		: undefined;
	// Value could be named differently, eg: language/languages - ???
	const alternateValue = alternateField
		? value?.replace(contentSuffix, alternateField)
		: undefined;
	const alternateValues = alternateFields.map((field) =>
		field?.replace(contentSuffix, field),
	);
	// Combine two variants
	const interchangeableAlternateValue =
		interchangeableValue && alternateField
			? interchangeableValue?.replace(contentSuffix, alternateField)
			: undefined;
	const foundValue = [
		value,
		interchangeableValue,
		alternateValue,
		...alternateValues,
		interchangeableAlternateValue,
	]
		?.filter(Boolean)
		?.reduce((acc, curr) => {
			if (!acc) {
				return findFromObj(data, curr);
			}

			return acc;
		}, undefined);
	return foundValue;
};

export const takeFirstValue = <T = any, R = any>(keyIndexed: T) =>
	keyIndexed && (Object.values(keyIndexed ?? {})?.[0] as R);

export const mergeArrays = <T = any>(input1: T[], input2: T[]) =>
	[...(input1 || []), ...(input2 || [])].filter(
		(entry, index, arr) => arr.indexOf(entry) === index,
	);

export const mergeObjectOfArrays = <T = any>(input1: T, input2: T) =>
	Object.entries(input2).reduce((result, [key, values]) => {
		if (!values?.length) return result;
		result[key] = mergeArrays(result[key], values);
		return result;
	}, input1);

export const slugifyString = (input: string): string => {
	return typeof input === 'string'
		? input
				.toLowerCase()
				.trim()
				.replace(/[^\w\-]+/g, ' ')
				.replace(/\s+/g, '-')
		: input;
};

export const removeFalsyValuesFromObj = (obj: GenericObject) => {
	return Object.entries(obj ?? {})?.reduce((acc, [key, val]) => {
		if (val) {
			if (!Array.isArray(val) || val.length > 0) {
				acc[key] = val;
			}
		}
		return acc;
	}, {});
};

/**
 * Runs a simple first level check for whether objects are same
 * @param params First obj
 * @param params Second obj
 * @returns Whether objects are the same
 */
export const simpleAreObjectsSame = (
	firstObj: GenericObject,
	secondObj: GenericObject,
): boolean => {
	const firstObjEntries = Object.entries(firstObj ?? {});
	const secondObjEntries = Object.entries(secondObj ?? {});
	if (firstObjEntries?.length > secondObjEntries?.length) {
		return firstObjEntries?.every(
			([key, val]) => secondObjEntries?.[key] === val,
		);
	}
	return secondObjEntries?.every(
		([key, val]) => firstObjEntries?.[key] === val,
	);
};

export const monthsToYears = (value: number, translate: TranslateService) => {
	const monthOverflow = value % 12;
	const years = Math.floor(value / 12);
	if (monthOverflow === 1 && years === 1) {
		return `${years} ${translate.get('time.duration_year_singular')}
      ${monthOverflow} ${translate.get('time.duration_month_singular')}`;
	}
	if (monthOverflow > 1 && years === 1) {
		return `${years} ${translate.get('time.duration_year_singular')}
      ${monthOverflow} ${translate.get('time.duration_month_plural')}`;
	}
	if (monthOverflow > 1 && years > 1) {
		return `${years} ${translate.get('time.duration_year_plural')}
      ${monthOverflow} ${translate.get('time.duration_month_plural')}`;
	}
	if (monthOverflow === 0 && years > 1) {
		return `${years} ${translate.get('time.duration_year_plural')}`;
	}
	if (monthOverflow > 0 && years === 0) {
		return `${monthOverflow} ${translate.get('time.duration_month_plural')}`;
	}
	return `${years} ${translate.get('time.duration_year_singular')}`;
};

export const isDateInPast = (date: string) => {
	const dateArr = date.split('.');
	const formattedDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
	return (
		new Date(formattedDate).setHours(0, 0, 0, 0) <
		new Date().setHours(0, 0, 0, 0)
	);
};

export const titleCase = (input: string) => {
	if (!input) {
		return;
	}
	return input.substring(0, 1).toUpperCase() + input.substring(1);
};

/**
 * @description method to use when you need to check if you have any filters active etc
 * @param params Any single level params object
 * @returns boolean value for whether there are any keys that aren't whitelisted
 */
export const hasNonWhitelistedQueryParams = (params: GenericObject) => {
	const keys = Object.keys(params ?? {});
	for (const key of keys) {
		if (!whitelistedQueryParams.has(key)) {
			return true;
		}
	}
	return false;
};
