<button
  htm-button
  theme="plain"
  attr.aria-label="{{ (menuOpen ? 'button.close' : 'button.open') | translate }} {{
    'frontpage.main_menu' | translate
    }}"
  class="hamburger"
  [class.active]="menuOpen"
  (click)="handleMenuToggle()"
  data-test-id="header-hamburger"
>
  <div class="toggle" aria-hidden="true">
    <div class="bar first"></div>
    <div class="bar middle"></div>
    <div class="bar last"></div>
  </div>
</button>
<a
  class="logo"
  [class.logo--dark]="theme === 'dark'"
  aria-label="edu.ee"
  data-test-id="header-logo"
  [routerLink]="indexLink"
  >
  <img
    aria-hidden="true" 
    [src]="'/assets/img/' + (theme === 'dark' ? 'logo-dark-mode.svg' : 'logo_blue.svg')"
    alt="edu.ee logo" 
  />
</a>
<div class="header__assets">
  <ng-container [ngTemplateOutlet]="contents"></ng-container>
</div>