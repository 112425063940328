<ng-template #overlayTemplate>
	<div
		class="digi-sign-overlay__wrapper"
		[class.digi-sign-overlay__wrapper--expanded]="isExpanded()"
	>
		<button
			class="digi-sign-overlay__expand-button"
			[class.digi-sign-overlay__expand-button--expanded]="isExpanded()"
			htm-button
			theme="plain"
			(click)="toggleExpanded()"
			[attr.aria-expanded]="isExpanded()"
			aria-controls="digi-sign-overlay-contents"
			data-test-id="digi-sign-overlay-expand-button"
		>
			@if (!isExpanded()) {
				<icon
					[glyph]="isExpanded() ? 'chevron-down' : 'chevron-up'"
					aria-hidden="true"
				></icon>
			}
			<span>{{
				(isExpanded() ? 'digi_sign.hide_signing' : 'digi_sign.open') | translate
			}}</span>
			@if (!isExpanded()) {
				<span
					>({{
						('digi_sign.files_waiting' | translate).replace(
							'%count%',
							digiSignService.addedItems()?.length
						)
					}})</span
				>
			} @else {
				<icon
					[glyph]="isExpanded() ? 'chevron-down' : 'chevron-up'"
					aria-hidden="true"
				></icon>
			}
		</button>
		@if (isExpanded()) {
			<div class="digi-sign-overlay__title">
				<span>{{ 'digi_sign.sign_and_download' | translate }}</span>
				<icon
					glyph="help-circle"
					[matTooltip]="'digi_sign.sign_and_download_help' | translate"
					matTooltipPosition="below"
				></icon>
			</div>
		}
		<div id="digi-sign-overlay-contents" class="digi-sign-overlay__contents">
			@if (isExpanded()) {
				<ng-container [ngTemplateOutlet]="expandedView" />
			}
		</div>
	</div>
</ng-template>

<ng-template #expandedView>
	<div
		class="digi-sign-overlay__list"
		id="digi-sign-overlay-list"
		[attr.aria-expanded]="isMobileListCollapsed()"
	>
		@for (group of groups(); track group.typeName; let i = $index) {
			@if (
				(breakpointService.isMobile() && isMobileListCollapsed() && i === 0) ||
				!breakpointService.isMobile() ||
				!isMobileListCollapsed() ||
				groups().length === 1
			) {
				<div
					class="digi-sign-overlay__group"
					[class.digi-sign-overlay__group--collapsed]="isMobileListCollapsed()"
				>
					<span class="digi-sign-overlay__group__title"
						>{{ group.label | translate }} ({{ group.items.length }}):</span
					>
					@for (item of group.items; track item.id; let isLast = $last) {
						<ng-container
							[ngTemplateOutlet]="itemTemplate"
							[ngTemplateOutletContext]="{ item, isLast }"
						/>
					}
				</div>
			}
		}
		@if (groups().length > 1 && isMobileListCollapsed()) {
			<button
				class="digi-sign-overlay__mobile-list-toggle"
				[class.digi-sign-overlay__mobile-list-toggle--expanded]="
					!isMobileListCollapsed()
				"
				type="button"
				(click)="$event.preventDefault(); isMobileListCollapsed.set(false)"
				[title]="'digi_sign.view_more' | translate"
			>
				<icon glyph="chevron-down" [ariaHidden]="true" />
			</button>
		}
	</div>
	@if (downloading()) {
		<loader />
	} @else {
		<button
			class="digi-sign-overlay__download-button"
			htm-button
			(click)="downloadItems()"
		>
			{{ 'digi_sign.download' | translate }}
		</button>
	}
	<button
		class="digi-sign-overlay__cancel-button"
		htm-button
		theme="plain"
		(click)="clearList()"
	>
		<icon glyph="trash-2" />
		<span>
			{{ 'digi_sign.cancel' | translate }}
		</span>
	</button>
</ng-template>

<ng-template #itemTemplate let-item="item" let-isLast="isLast">
	<div class="digi-sign-overlay__item">
		<p class="digi-sign-overlay__item-name">{{ item.name }}</p>
		<button
			class="digi-sign-overlay__remove-button"
			htm-button
			theme="plain"
			(click)="removeItem(item)"
			[attr.data-test-id]="'digi-sign-overlay-remove-item-button-' + item?.id"
			[attr.aria-label]="'digi_sign.remove_study' | translate"
		>
			<icon glyph="x" aria-hidden="true"></icon>
		</button>
	</div>
	<div
		class="digi-sign-overlay__separator"
		*ngIf="!isLast && digiSignService.addedItems()?.length > 1"
	></div>
</ng-template>
