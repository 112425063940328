@if (!hideHeader) {
	<div class="unpadded">
		<more-block id="1" content="abitekst.tunnistused"></more-block>
	</div>
	<h2>{{ 'frontpage.certificates_data' | translate }}</h2>
}
<accordion>
	<accordion-item [title]="accordionSections[0].title"
		(click)="dataController(accordionSections[0].id)">
		<ng-template>
			<loader *ngIf="!error && !graduationCertificates"></loader>
			<p *ngIf="graduationCertificates && graduationCertificates.length < 1"
				class="text-center text--substrong--head mb-0">
				{{ 'frontpage.dashboard_tabs_graduation_certificates_no_results' | translate }}
			</p>
			<scrollableContent>
				<table htm-table *ngIf="!error && graduationCertificates && graduationCertificates.length > 0">
					<thead>
						<tr>
							<th>{{ 'certificates.institution_name' | translate }}</th>
							<th>{{ 'certificates.document_name' | translate }}</th>
							<th>{{ 'certificates.issuing_date' | translate }}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let certificate of graduationCertificates">
							<td>{{ certificate.issuerName }}</td>
							<td>{{ certificate.typeName }}</td>
							<td>
								{{ certificate.status === 'CERTIFICATE_STATUS:VALID' ? (certificate.issued | date: 'dd.MM.yyyy') : ('generic.invalid' | translate) }}
							</td>
							<td>
								<a [routerLink]="((this.usesNewDetailViewLink() ? '/minu-andmed/õpingud' : '/töölaud/tunnistused/lõputunnistused') | translatePath) + '/' + certificate.id"
								attr.aria-label="{{ 'button.open' | translate }} {{ certificate.issuerName }} {{ certificate.typeName }}"
								[attr.data-test-id]="'certificates-graduation-certificates-link-' + certificate.id"
								[state]="{from: linkState}"
								>{{ 'button.view_more' | translate }}</a>
							</td>
						</tr>
					</tbody>
				</table>
			</scrollableContent>
		</ng-template>
	</accordion-item>
	<accordion-item [title]="accordionSections[1].title"
	(click)="dataController(accordionSections[1].id)">
		<ng-template>
			<loader *ngIf="!error && !professionalCertificates"></loader>
			<p *ngIf="error && !certificateErr" class="text-center text--substrong--head mb-0">
				{{ 'frontpage.dashboard_tabs_certificates_error_technical' | translate }}
			</p>
			<p *ngIf="error && certificateErr" class="text-center text--substrong--head mb-0">
				{{ certificateErr }}
			</p>
			<p *ngIf="professionalCertificates && professionalCertificates.length < 1"
				class="text-center text--substrong--head mb-0">
				{{ 'frontpage.dashboard_tabs_certificates_no_results' | translate }}
			</p>
			<table htm-table *ngIf="!error && professionalCertificates && professionalCertificates.length > 0">
				<tbody>
					<tr *ngFor="let certificate of professionalCertificates">
						<td>{{ certificate.nimi | titlecase }}</td>
						<td>{{ certificate.valjaantud }}</td>
						<td class="text-center">
							<a [routerLink]="('tunnistused' | translateWord) + '/' + certificate.registrinumber"
								attr.aria-label="{{ 'button.view_more' | translate }} {{ certificate.nimi}}"
								[attr.data-test-id]="'certificates-professional-certificate-link-' + certificate.registrinumber"
								[state]="{certificate:certificate}">{{ 'button.view_more' | translate }}</a>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-template>
	</accordion-item>
	<accordion-item [title]="accordionSections[2].title"
	(click)="dataController(accordionSections[2].id)">
		<ng-template>
			<loader *ngIf="!examResultsErr && !errRequest && !examResults"></loader>
			<ng-container *ngIf="examResultsErr || errRequest">
				<p class="text-center text--substrong--head mb-0" *ngIf="examResultsErr">
					{{ examResultsErr }}
				</p>
				<p class="text-center text--substrong--head mb-0" *ngIf="errRequest">
					{{ 'errors.examinations_data_request' | translate }}
				</p>
			</ng-container>
			<table htm-table *ngIf="!examResultsErr && !errRequest && examResults">
				<thead>
					<tr>
						<th>{{'frontpage.eksamiNimetus' | translate}}</th>
						<th>{{'frontpage.aasta' | translate}}</th>
						<th aria-hidden="true"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let session of examResults">
						<td>{{ session.nimi }}</td>
						<td>{{ session.oppeaasta }}</td>
						<td class="text-center">
							<a [routerLink]="('tunnistused' | translateWord) + '/' + session.testsessioon_id"
								attr.aria-label="{{ 'button.view_more' | translate }} {{ session.nimi}}"
								[attr.data-test-id]="'certificates-exam-result-link-' + session.testsessioon_id"
								[state]="{exams: session.oppeaasta}">{{ 'button.view_more' | translate }}</a>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-template>
	</accordion-item>
</accordion>
