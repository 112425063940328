import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'input-wrapper',
	templateUrl: './input-wrapper.template.html',
	styleUrls: ['./input-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWrapperComponent {
  @Input() title?: string;
  @Input() error: boolean;
  @Input() errorMessage?: string;
  @Input() disabled: boolean;
  @Input() filledField?: boolean;
  @Input() isFocused = false;

  get containerClasses(): string {
    const classes = ['input-wrapper__container'];
    if (this.error) {
      classes.push('input-wrapper__container--error');
    }
    if (this.disabled) {
      classes.push('input-wrapper__container--disabled');
    }

    return this.isFocused ?
      `input-wrapper__container--focused ${classes.join(' ')}` :
      `${classes.join(' ')}`;
  }
}
