<loader *ngIf="data.loading"></loader>
<ul *ngIf="data.list.length > 0">
  <li *ngFor="let item of data.list | slice:0:3">
    <a [routerLink]="'/töölaud/teavitused/' + item.id" class="message" [class.unread]="!item.read"
      [attr.data-test-id]="'sidebar-notifications-item-' + item?.id">
      <span>{{item.subject}}</span>
      <p>{{item.sentAt | date:"dd.MM.yyyy"}} {{'notifications_block.time' | translate}} {{item.sentAt | date:"HH:mm"}}</p>
    </a>
  </li>
</ul>
<ul class="no-messages" *ngIf="data.list.length === 0 && !data.loading">
  <li>
    {{ 'frontpage.dashboard_no_notifications' | translate }}
  </li>
</ul>
<div class="bottom" [hidden]="data.list.length === 0">
  <a routerLink="{{ '/töölaud/teavitused' | translatePath }}"
    data-test-id="sidebar-notifications-link"
  >{{ 'frontpage.dashboard_view_all_notifications' | translate }}</a>
</div>
