<div class="noResults" *ngIf="!list?.length && !loading">
  {{ noResultStringByType | translate }}
</div>
<div role="status">
  <label-count *ngIf="listItemCount && list?.length" [count]="listItemCount">{{ 'search.results_of' | translate }}: </label-count>
  @if (!list?.length && !loading) {
    <span class="sr-only">
      {{ noResultStringByType | translate }}
    </span>
  }
</div>
<listItems
  *ngIf="list?.length"
  [type]="type"
  [list]="list"
  [compare]="compare"
></listItems>
<loader *ngIf="loading"></loader>
<loader class="loadMore" *ngIf="loadingMore"></loader>
<div class="loadMore" *ngIf="!loading && !loadingMore && canLoadMore">
  <button htm-button (click)="loadMore()" data-test-id="list-load-more-button">
    <span>{{ 'button.load_more' | translate }}</span>
    <span *ngIf="listItemCount && list?.length && (listItemCount - list.length > 0)"> ({{ listItemCount - list.length }})</span>
  </button>
</div><!--/loadMore-->


