<table htm-table *ngIf="table" class="studyProgramme">
  <thead>
    <tr>
      <th>{{ table[0].label | translate }}</th>
      <th>{{ table[0].value }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of table; let i = index;" >
      <tr *ngIf="i > 0">
        <td>{{ table[i].label | translate }}</td>
        <td><b>{{ table[i].value | capitalize }}</b></td>
      </tr>
    </ng-container>
  </tbody>
</table>
