<div class="text-center">
	<button
		style="width: 100%"
		[disabled]="data.certificate?.status === 'CERT_DOCUMENT_STATUS:INVALID'"
		(click)="openNewAccessModal()"
		data-test-id="sidebar-final-document-access-open-modal-button"
		htm-button
	>
		{{ 'finaldocuments.add_access' | translate }}
	</button>
</div>
<div class="currentAccess">
	<block-title>{{ 'finaldocuments.valid_accesses' | translate }}</block-title>
	<ul>
		<li *ngIf="activeAccesses.length === 0">
			<div>
				<span>{{ 'finaldocuments.no_valid_accesses' | translate }}</span>
			</div>
		</li>
		<li class="accesses" *ngFor="let activeAccess of activeAccesses">
			<ng-container *ngIf="activeAccess.type === 'ACCESS_TYPE:ACCESS_CODE'">
				<icon size="medium" glyph="key" [bg]="true"></icon>
				<div>
					<button
						htm-button
						theme="link"
						(click)="openAccess(activeAccess)"
						data-test-id="sidebar-final-document-access-open-access-code-button"
					>
						{{ 'finaldocuments.with_access_code' | translate }}
					</button>
					<span>{{ activeAccess.scope | translate }}</span>
					<ng-container *ngIf="activeAccess.endDate">
						<span
							>{{ 'finaldocuments.valid_until' | translate }}
							{{ activeAccess.endDate | date : 'dd.MM.yyyy' }}</span
						>
					</ng-container>
					<ng-container *ngIf="!activeAccess.endDate">
						<span>{{ 'finaldocuments.valid_indefinitely' | translate }}</span>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="activeAccess.type === 'ACCESS_TYPE:ID_CODE'">
				<icon size="medium" glyph="user-filled" [bg]="true"></icon>
				<div>
					<button
						htm-button
						theme="link"
						(click)="openAccess(activeAccess)"
						data-test-id="sidebar-final-document-access-open-id-code-button"
					>
						{{ 'frontpage.isikukood' | translate }}:
						{{ activeAccess.accessorCode | idCode }}
					</button>
					<span>{{ activeAccess.scope | translate }}</span>
					<ng-container *ngIf="activeAccess.endDate">
						<span
							>{{ 'finaldocuments.valid_until' | translate }}
							{{ activeAccess.endDate | date : 'dd.MM.yyyy' }}</span
						>
					</ng-container>
					<ng-container *ngIf="!activeAccess.endDate">
						<span>{{ 'finaldocuments.valid_indefinitely' | translate }}</span>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="activeAccess.type === 'ACCESS_TYPE:DISCLOSURE'">
				<icon size="medium" glyph="eye" [bg]="true"></icon>
				<div>
					<button
						htm-button
						theme="link"
						(click)="openAccess(activeAccess)"
						data-test-id="sidebar-final-document-access-open-disclosure-button"
					>
						{{ 'certificates.disclosure' | translate }}
					</button>
					<span>{{ activeAccess.scope | translate }}</span>
					<ng-container *ngIf="activeAccess.endDate">
						<span
							>{{ 'finaldocuments.valid_until' | translate }}
							{{ activeAccess.endDate | date : 'dd.MM.yyyy' }}</span
						>
					</ng-container>
					<ng-container *ngIf="!activeAccess.endDate">
						<span>{{ 'finaldocuments.valid_indefinitely' | translate }}</span>
					</ng-container>
				</div>
			</ng-container>
		</li>
	</ul>
</div>
<div class="text-center">
	<button
		(click)="openInvalidAccesses()"
		htm-button
		theme="link"
		data-test-id="sidebar-final-document-access-show-invalid-accesses-button"
	>
		{{ 'finaldocuments.show_invalid_accesses' | translate }}
	</button>
</div>

<htm-modal
	id="finalDocument-addAccess"
	[modalTitle]="'finalDocumentAccess' | translate"
	[bottomAction]="false"
	wide
>
	<ng-template>
		<form [formGroup]="addAccessForm">
			<h1>
				{{
					accessAction === 'add'
						? ('finaldocuments.add_access' | translate)
						: ('finaldocuments.modify_access' | translate)
				}}
			</h1>
			<p class="required-info">
				Tärniga (*) tähistatud väljad on kohustuslikud.
			</p>
			<p>
				<ng-container *ngIf="accessAction === 'add'">
					<p>{{ 'finaldocuments.choose_access_type' | translate }}:</p>
					<formItem
						type="radio"
						formControlName="type"
						[options]="addAccessOptions.type"
						name="type"
						testId="sidebar-final-document-access-add-access-options"
					></formItem>
				</ng-container>
			</p>

			<div
				[class.border-top]="accessAction === 'add'"
				*ngIf="addAccessForm.value.type"
			>
				<ng-container
					*ngIf="addAccessForm.value.type === 'ACCESS_TYPE:ACCESS_CODE'"
				>
					<h3>{{ 'finaldocuments.access_with_email' | translate }}</h3>
					<p>{{ 'finaldocuments.insert_email' | translate }}</p>
					<formItem
						[error]="
							addAccessForm.errors?.emailMissing || addAccessForm.errors?.email
						"
						[errorMessage]="
							addAccessForm.errors &&
							addAccessForm.errors[(addAccessForm.errors | keyvalue)[0].key]
								? errors[(addAccessForm.errors | keyvalue)[0].key]
								: ''
						"
						formControlName="emailAddress"
						placeholder="E-posti aadress"
						type="text"
						[disabled]="accessAction === 'edit'"
						testId="sidebar-final-document-access-email-field"
					>
					</formItem>
				</ng-container>
				<ng-container
					*ngIf="addAccessForm.value.type === 'ACCESS_TYPE:ID_CODE'"
				>
					<h3>{{ 'finaldocuments.access_with_idcode' | translate }}</h3>
					<p>{{ 'finaldocuments.insert_id_code' | translate }}</p>
					<formItem
						*ngIf="accessAction === 'add'"
						[error]="
							addAccessForm.errors?.idCodeMissing ||
							addAccessForm.errors?.idCodeFormat
						"
						[errorMessage]="
							addAccessForm.errors &&
							addAccessForm.errors[(addAccessForm.errors | keyvalue)[0].key]
								? errors[(addAccessForm.errors | keyvalue)[0].key]
								: ''
						"
						formControlName="accessorCode"
						type="text"
						placeholder="Isikukood"
						testId="sidebar-final-document-access-id-code-field"
					>
					</formItem>
					<formItem
						*ngIf="accessAction === 'edit'"
						type="text"
						disabled="true"
						[value]="addAccessForm.value.accessorCode | idCode"
						testId="sidebar-final-document-access-permanently-disabled-id-code-field"
					>
					</formItem>
				</ng-container>
				<p *ngIf="addAccessForm.value.type === accessType.DISCLOSURE">
					{{ 'finaldocuments.choose_access_scope_disclosure' | translate }}
				</p>
				<p *ngIf="addAccessForm.value.type !== accessType.DISCLOSURE">
					{{ 'finaldocuments.choose_access_scope' | translate }}
				</p>
				<formItem
					name="scope"
					formControlName="scope"
					type="radio"
					[options]="addAccessOptions.scope"
					testId="sidebar-final-document-access-add-access-scope"
				></formItem>
				<p *ngIf="addAccessForm.value.type === accessType.DISCLOSURE">
					{{ 'finaldocuments.choose_disclosure_end_date' | translate }}
				</p>
				<p *ngIf="addAccessForm.value.type !== accessType.DISCLOSURE">
					{{ 'finaldocuments.choose_access_end_date' | translate }}
				</p>
				<div class="split">
					<input-date
						[error]="addAccessForm?.errors?.invalidDate"
						[errorMessage]="
							addAccessForm?.errors?.invalidDate ? this.errors?.invalidDate : ''
						"
						formControlName="endDate"
						[disabled]="addAccessForm.value.noEndDate"
						placeholder="pp.kk.aaaa"
						testId="sidebar-final-document-access-add-access-end-date-field"
					></input-date>
					<formItem
						type="checkbox"
						formControlName="noEndDate"
						[label]="'finaldocuments.valid_indefinitely' | translate"
						testId="sidebar-final-document-access-add-access-no-end-date-checkbox"
					></formItem>
				</div>
				<alerts small="true" id="addAccessErrors"></alerts>
				<div class="button-row">
					<button
						htm-button
						(click)="modal.toggle('finalDocument-addAccess')"
						theme="link"
						data-test-id="sidebar-final-document-access-modal-close-button"
					>
						{{ 'generic.cancel' | translate }}
					</button>
					<button
						htm-button
						*ngIf="accessAction === 'edit'"
						type="submit"
						(click)="modifyAccess()"
						data-test-id="sidebar-final-document-access-modal-save-button"
					>
						{{ 'button.save_changes' | translate }}
					</button>
					<button
						htm-button
						*ngIf="accessAction === 'add'"
						type="submit"
						(click)="addAccess()"
						data-test-id="sidebar-final-document-access-modal-submit-button"
					>
						{{ 'finaldocuments.add_access' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</htm-modal>

<htm-modal
	id="finalDocument-access"
	modalTitle="Ligipääs"
	[bottomAction]="false"
>
	<ng-template>
		<ng-container [ngTemplateOutlet]="getAccessTemplate()"> </ng-container>
	</ng-template>
</htm-modal>

<htm-modal
	id="finalDocument-invalidAccesses"
	[modalTitle]="'finalDocumentAccess' | translate"
	[bottomAction]="true"
>
	<ng-template>
		<h1 id="final-documents-inactive-accesses-title">
			{{ 'finaldocuments.inactive_accesses' | translate }}
		</h1>
		<scrollableContent *ngIf="inactiveAccesses.length > 0; else noResults">
			<table
				htm-table
				aria-labelledby="final-documents-inactive-accesses-title"
			>
				<tr>
					<th>{{ 'finaldocuments.th.type' | translate }}</th>
					<th>{{ 'finaldocuments.th.idcode_accesscode' | translate }}</th>
					<th>{{ 'finaldocuments.th.valid_from' | translate }}</th>
					<th>{{ 'finaldocuments.th.valid_until' | translate }}</th>
					<th>{{ 'finaldocuments.th.scope' | translate }}</th>
					<th>{{ 'finaldocuments.th.email' | translate }}</th>
				</tr>
				<tr *ngFor="let access of inactiveAccesses">
					<td>
						<icon
							*ngIf="access.type === 'ACCESS_TYPE:ACCESS_CODE'"
							size="medium"
							glyph="key"
							[bg]="true"
						></icon>
						<icon
							*ngIf="access.type === 'ACCESS_TYPE:ID_CODE'"
							size="medium"
							glyph="user-filled"
							[bg]="true"
						></icon>
						<icon
							*ngIf="access.type === 'ACCESS_TYPE:DISCLOSURE'"
							size="medium"
							glyph="eye"
							[bg]="true"
						></icon>
					</td>
					<td>{{ getAccessType(access) }}</td>
					<td>{{ access.issued | date : 'dd.MM.yyyy' }}</td>
					<td>
						<ng-container *ngIf="access.endDate">{{
							access.endDate | date : 'dd.MM.yyyy'
						}}</ng-container>
						<ng-container *ngIf="!access.endDate">-</ng-container>
					</td>
					<td>
						{{
							access.scope === 'ACCESS_SCOPE:MAIN_DOCUMENT'
								? data?.certificate?.typeName
								: data?.certificate?.typeName +
								  ' ' +
								  ('finaldocuments.with_supplement' | translate)
						}}
					</td>
					<td>{{ access.emailAddress || '-' }}</td>
				</tr>
			</table>
		</scrollableContent>
	</ng-template>
</htm-modal>

<htm-modal
	[modalTitle]="'finalDocumentAccess' | translate"
	[bottomAction]="false"
	id="finalDocument-confirmInvalidation"
>
	<ng-template>
		<h1 *ngIf="addAccessForm.value.type === 'ACCESS_TYPE:ID_CODE'">
			{{
				('finaldocuments.confirm_invalidate_accessorcode' | translate).replace(
					'%ACCESSORCODE%',
					addAccessForm.value.accessorCode | idCode
				)
			}}
		</h1>
		<h1 *ngIf="addAccessForm.value.type === 'ACCESS_TYPE:ACCESS_CODE'">
			{{
				('finaldocuments.confirm_invalidate_email' | translate).replace(
					'%EMAIL%',
					addAccessForm.value.emailAddress
				)
			}}
		</h1>
		<h1 *ngIf="addAccessForm.value.type === 'ACCESS_TYPE:DISCLOSURE'">
			{{ 'finaldocuments.confirm_invalidate_disclosure' | translate }}
		</h1>
		<div class="button-row" *ngIf="!invalidateLoader">
			<button
				htm-button
				(click)="modal.toggle('finalDocument-access')"
				theme="link"
				data-test-id="sidebar-final-document-access-invalidation-modal-close-button"
			>
				{{ 'generic.cancel' | translate }}
			</button>
			<button
				htm-button
				(click)="invalidateAccess()"
				data-test-id="sidebar-final-document-access-invalidation-modal-submit-button"
			>
				{{ 'finaldocuments.invalidate_access' | translate }}
			</button>
		</div>

		<loader *ngIf="invalidateLoader"></loader>
	</ng-template>
</htm-modal>

<ng-template #accessCode>
	<h1 style="word-break: break-word">
		{{ 'finaldocuments.accessCode' | translate }}:
		<span style="font-weight: normal">{{
			addAccessForm.value.accessorCode
		}}</span>
	</h1>
	<div class="line">
		<labels [type]="openedAccessLabelType" [data]="openedAccessLabel"></labels>
		<button
			(click)="modal.toggle('finalDocument-confirmInvalidation')"
			htm-button
			theme="link"
			data-test-id="sidebar-final-document-access-toggle-invalidation-modal-button"
		>
			{{ 'finaldocuments.invalidate_access' | translate }}
		</button>
	</div>
	<form [formGroup]="addAccessForm">
		<p>{{ 'finaldocuments.accessCode_recipient' | translate }}:</p>
		<formItem
			formControlName="emailAddress"
			[disabled]="true"
			placeholder="E-posti aadress"
			type="text"
			testId="sidebar-final-document-access-access-code-recipient-email-field"
		></formItem>
		<p>{{ 'finaldocuments.idcode_scope' | translate }}:</p>
		<formItem
			name="scope"
			[disabled]="true"
			formControlName="scope"
			type="radio"
			[options]="addAccessOptions.scope"
			testId="sidebar-final-document-access-access-code-scope-options-field"
		>
		</formItem>
		<p *ngIf="addAccessForm.value.type !== accessType.DISCLOSURE">
			{{ 'finaldocuments.idcode_end_date' | translate }}:
		</p>
		<div class="split">
			<input-date
				formControlName="endDate"
				[forceDisabled]="true"
				placeholder="pp.kk.aaaa"
				testId="sidebar-final-document-access-access-code-recipient-email-field"
			></input-date>
			<formItem
				forcePlaceholder="true"
				type="checkbox"
				formControlName="noEndDate"
				[disabled]="true"
				[label]="'finaldocuments.valid_indefinitely' | translate"
				testId="sidebar-final-document-access-access-code-no-end-date-checkbox"
			></formItem>
		</div>
		<h2 *ngIf="addAccessForm.value.provider === 'ACCESS_PROVIDER:ISSUER'">
			Ligipääsu andis tunnistuse väljaandja
		</h2>
	</form>
	<div class="button-row">
		<button
			htm-button
			(click)="modal.toggle('finalDocument-access')"
			theme="link"
			data-test-id="sidebar-final-document-access-close-access-modal-button"
		>
			{{ 'button.close' | translate }}
		</button>
		<button
			htm-button
			(click)="changeAccess(openedAccess)"
			data-test-id="sidebar-final-document-access-edit-access-button"
		>
			{{ 'button.edit_data' | translate }}
		</button>
	</div>
</ng-template>

<ng-template #idCode>
	<h1>
		{{ 'finaldocument.idcode' | translate }}:
		<span style="font-weight: normal">{{
			addAccessForm.value.accessorCode | idCode
		}}</span>
	</h1>
	<div class="line">
		<labels [type]="openedAccessLabelType" [data]="openedAccessLabel"></labels>
		<button
			(click)="modal.toggle('finalDocument-confirmInvalidation')"
			htm-button
			theme="link"
			data-test-id="sidebar-final-document-access-id-code-invalidation-toggle-modal-button"
		>
			{{ 'finaldocuments.invalidate_access' | translate }}
		</button>
	</div>
	<form [formGroup]="addAccessForm">
		<p>{{ 'finaldocuments.idcode_scope' | translate }}</p>
		<formItem
			name="scope"
			[disabled]="true"
			formControlName="scope"
			type="radio"
			[options]="addAccessOptions.scope"
			testId="sidebar-final-document-access-id-code-scope-options"
		>
		</formItem>
		<p>{{ 'finaldocuments.idcode_end_date' | translate }}</p>
		<div class="split">
			<input-date
				formControlName="endDate"
				[forceDisabled]="true"
				placeholder="pp.kk.aaaa"
				testId="sidebar-final-document-access-id-code-end-date-field"
			></input-date>
			<formItem
				type="checkbox"
				formControlName="noEndDate"
				[disabled]="true"
				[label]="'finaldocuments.valid_indefinitely' | translate"
				testId="sidebar-final-document-access-id-code-no-end-date-checkbox"
			></formItem>
		</div>
		<h2 *ngIf="addAccessForm.value.provider === 'ACCESS_PROVIDER:ISSUER'">
			Ligipääsu andis tunnistuse väljaandja
		</h2>
	</form>
	<div class="button-row">
		<button
			htm-button
			(click)="modal.toggle('finalDocument-access')"
			theme="link"
			data-test-id="sidebar-final-document-access-id-code-close-modal-button"
		>
			{{ 'button.close' | translate }}
		</button>
		<button
			htm-button
			(click)="changeAccess(openedAccess)"
			data-test-id="sidebar-final-document-access-id-code-edit-access-button"
		>
			{{ 'button.edit_data' | translate }}
		</button>
	</div>
</ng-template>

<ng-template #disclosure>
	<h1>
		{{ 'certificates.disclosure' | translate
		}}<span style="font-weight: normal">{{
			addAccessForm.value.accessorCode | idCode
		}}</span>
	</h1>
	<div class="line">
		<labels [type]="openedAccessLabelType" [data]="openedAccessLabel"></labels>
		<button
			(click)="modal.toggle('finalDocument-confirmInvalidation')"
			htm-button
			theme="link"
			data-test-id="sidebar-final-document-access-disclosure-submit-button"
		>
			{{ 'finaldocuments.invalidate_access' | translate }}
		</button>
	</div>
	<form [formGroup]="addAccessForm">
		<p>{{ 'finaldocuments.disclosure_scope' | translate }}</p>
		<formItem
			name="scope"
			[disabled]="true"
			formControlName="scope"
			type="radio"
			[options]="addAccessOptions.scope"
			testId="sidebar-final-document-access-disclosure-scope-options"
		>
		</formItem>
		<p>{{ 'finaldocuments.disclosure_end_date' | translate }}</p>
		<div class="split">
			<input-date
				formControlName="endDate"
				[forceDisabled]="true"
				placeholder="pp.kk.aaaa"
				testId="sidebar-final-document-access-disclosure-end-date-field"
			></input-date>
			<formItem
				type="checkbox"
				formControlName="noEndDate"
				[disabled]="true"
				[label]="'finaldocuments.valid_indefinitely' | translate"
				testId="sidebar-final-document-access-disclosure-no-end-date-checkbox"
			></formItem>
		</div>
		<h2 *ngIf="addAccessForm.value.provider === 'ACCESS_PROVIDER:ISSUER'">
			Ligipääsu andis tunnistuse väljaandja
		</h2>
	</form>
	<div class="button-row">
		<button
			htm-button
			(click)="modal.toggle('finalDocument-access')"
			theme="link"
			data-test-id="sidebar-final-document-access-disclosure-close-modal-button"
		>
			{{ 'button.close' | translate }}
		</button>
		<button
			htm-button
			(click)="changeAccess(openedAccess)"
			data-test-id="sidebar-final-document-access-disclosure-edit-access-button"
		>
			{{ 'button.edit_data' | translate }}
		</button>
	</div>
</ng-template>

<ng-template #noResults>
	<alerts small="true" id="invalidAccessesAlerts"></alerts>
</ng-template>
