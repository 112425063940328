import { Directive, OnInit, ElementRef, HostBinding, ChangeDetectorRef, Input } from '@angular/core';

@Directive({
  selector: '[cornerLogo]',
})
export class CornerLogoDirective implements OnInit{
  @Input() includeClass?: boolean;
  public eventScrollListener: EventListener;
  public isHidden: boolean = false;
  @HostBinding('class') get hostClasses(): string {
    const includeBaseClass = (this.includeClass !== undefined && this.includeClass) || this.includeClass === undefined;
    return `${includeBaseClass ? 'corner-logo' : ''} ${this.isHidden ? 'hidden' : ''}`;
  }
  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  public listenerEvent(): void {
    if (document.querySelector('.app-content').scrollTop > 10) {
      if (!this.isHidden) {
        this.isHidden = true;
      }
    } else {
      this.isHidden = false;
    }
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    const eventListener = () => this.listenerEvent();
    this.eventScrollListener = eventListener;
    document.querySelector('.app-content')
    .addEventListener('scroll', eventListener);
  }
  ngOnDestroy(): void {
    document.querySelector('.app-content').removeEventListener('scroll', this.eventScrollListener);
  }
}
