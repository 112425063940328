import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '@app/_services';

@Pipe({
  name: 'mapFieldsByCurrentLang',
})
export class MapFieldsByCurrentLangPipe implements PipeTransform {
  constructor(private settingsService: SettingsService) {}

  transform(value: Object[], fields: string[]): unknown {
    if (!Array.isArray(value)) {
      return value;
    }
    return value?.map((item: Object) => {
      const newItem = {...item};
      // Override the target fields with their active lang values
      fields.forEach((fieldKey) => {
        // Avoid overriding if it's not an object
        if (typeof item?.[fieldKey] === 'object') {
          newItem[fieldKey] = item?.[fieldKey]?.[this.settingsService.currentAppLanguage];
        }
      });
      return newItem;
    })
  }
}

