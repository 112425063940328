import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'line',
  templateUrl: 'line.template.html',
  styleUrls: ['line.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class LineComponent {
  @Input() type: number = 1;
  @Input() absoluteContainer = true;

  @HostBinding('class')
  get hostClasses() {
    return this.absoluteContainer ? '' : 'line--not-absolute';
  }
}
