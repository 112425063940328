<div class="modal" role="dialog" [attr.aria-modal]="true" aria-labelledby="dialogTitle" cdkTrapFocus>
  <div class="modal__backdrop">
    <div *ngIf="blockModal" class="modal__block" #modalContent attr.id="modal-{{id}}">
      <ng-container *ngIf="opened()">
        <div class="modal__header" *ngIf="titleExists || topAction" [ngClass]="{ 'justify-end': !titleExists }">
          <h2 tabindex="-1" #titleRef id="dialogTitle" attr.aria-label="{{ modalTitle }}" *ngIf="titleExists">{{ modalTitle }}</h2>
          <button *ngIf="topAction" attr.aria-label="{{ 'button.close' | translate }}" htm-button theme="icon"
            (click)="modalService.close(id)"
            [attr.data-test-id]="'modal-top-close-button-' + id">
            <icon glyph="x" size="medium"></icon>
          </button>
        </div>
        <div class="modal-content">
          <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
        </div>
        <div class="modal__footer" *ngIf="bottomAction">
          <button (blur)="modalService.focusLock()" htm-button theme="plain"
            attr.aria-label="{{ 'button.close' | translate }}"
            [attr.data-test-id]="'modal-bottom-close-button-' + id"
            (click)="modalService.close(id)">{{ 'button.close' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!blockModal" class="modal__regular" #modalContent attr.id="modal-{{id}}" tabindex="0">
      <ng-container *ngIf="opened()">
        <div class="modal__header" *ngIf="topAction" [ngClass]="{ 'justify-end': topAction }" >
          <button *ngIf="topAction" class="white" attr.aria-label="{{ 'button.close' | translate }}" htm-button theme="icon"
            (click)="modalService.close(id)"
            [attr.data-test-id]="'modal-top-close-button-' + id">
            <icon glyph="x" size="medium"></icon>
          </button>
        </div>
        <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
      </ng-container>
    </div>
  </div>
</div>
