import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import Swiper from 'swiper';
import  { Navigation, Pagination }  from 'swiper/modules';
import { HTMSwiperComponent } from './swiper/swiper.component';

Swiper.use([Navigation, Pagination]);

@NgModule({
  declarations: [HTMSwiperComponent],
  exports: [HTMSwiperComponent],
  imports: [CommonModule]
})
export class HTMSwiperModule {}
