<input-wrapper
  [disabled]="forceDisabled || disabled"
  [error]="error"
  [errorMessage]="errorMessage"
  [isFocused]="isFocused"
  [filledField]="filledField"
  [title]="title"
>
  <input
  matInput
  class="date-picker"
  [class.date-picker--focused]="isFocused"
  [matDatepicker]="picker"
  [attr.aria-label]="ariaLabel"
  [required]="required"
  [name]="name"
  (dateChange)="writeValue($event?.target?.value?.toDate())"
  [placeholder]="!title || isFocused ? placeholder : undefined"
  [disabled]="forceDisabled || disabled"
  [value]="innerValue?.value"
  [min]="innerMinDate"
  [max]="innerMaxDate"
  (focus)="onFocus()"
  (blur)="triggerBlur()"
  [attr.data-test-id]="testId"
  #input
  />
<mat-datepicker-toggle class="date-picker__toggle" matIconSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
</input-wrapper>
