const skipKeys = ['fieldEventGroup', 'fieldIscedfSearchLink'];

export class Dedrupalize {
	static recurseObject(input: unknown) {
		if (!input || typeof input !== 'object') {
			return input;
		}

		if (Array.isArray(input)) {
			return input.map((entry) => Dedrupalize.recurseObject(entry));
		}

		// Convert ID-keyed PHP "arrays" into JavaScript arrays
		// `{ 1234: {...}, 5678: {...} }` -> `[{...}, {...}]`
		const objectKeys = Object.keys(input);
		if (objectKeys.every((key) => !isNaN(Number(key)))) {
			return Dedrupalize.recurseObject(Object.values(input));
		}

		return objectKeys.reduce(
			(output, key) => ({
				...output,
				[key]: Dedrupalize.handleKey(input, key),
			}),
			{}
		);
	}

	static handleKey(input: unknown, key: string) {
		const value = input[key];
		if (!value) return value;
		if (skipKeys.includes(key)) return value;
		if (key === 'path') return decodeURIComponent(value);
		return Dedrupalize.recurseObject(value);
	}
}
