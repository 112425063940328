import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import {
	ExternalQualifications,
	Studies,
} from '@app/_assets/studies/studies.model';
import { TeachingWorkType } from '@app/modules/my-data/my-data-teaching-detail-view/my-data-teaching-detail-view.model';
import { catchError, of } from 'rxjs';
import { AlertsService } from './AlertsService';
import { SettingsService } from './SettingsService';

export enum DigiSignGroupType {
	CONTINUING = 'continuing',
	WORKING = 'working',
	PROOF = 'proof',
	CERTIFICATE = 'certificate',
}

export type DigiSignItem = (
	| Studies
	| ExternalQualifications
	| TeachingWorkType
) & {
	digiSignGroup?: DigiSignGroupType;
};

@Injectable({
	providedIn: 'root',
})
export class DigiSignService {
	public addedItems = signal<DigiSignItem[]>([]);
	public addedItemIds = computed(() =>
		this.addedItems().reduce((acc, curr) => {
			acc[curr.id] = true;
			return acc;
		}, {}),
	);

	constructor(
		private readonly httpClient: HttpClient,
		private readonly settingsService: SettingsService,
		private readonly alertService: AlertsService,
	) {}

	public addItem(item: DigiSignItem, group: DigiSignGroupType) {
		item.digiSignGroup = group;
		this.addedItems.update((prev) => prev.concat(item));
	}

	public removeItem(item: DigiSignItem) {
		this.addedItems.update((prev) =>
			prev.filter((existingItem) => existingItem?.id !== item?.id),
		);
	}

	public clearItems() {
		this.addedItems.set([]);
	}

	public downloadAddedItems(callback: (success?: boolean) => void) {
		const itemIds = this.addedItems()?.map((item) => item?.id);
		this.downloadItems(itemIds, callback);
	}

	public downloadItems(
		itemIds: string[],
		callback: (success?: boolean) => void,
	) {
		const body = {
			andmekirje: itemIds,
			andmeplokk: [],
			valjundiTyyp: [],
		};
		this.httpClient
			.post(`${this.settingsService?.url}/digi-signed`, body)
			.pipe(
				catchError(() => {
					callback?.(false);
					return of({});
				}),
			)
			.subscribe((response: any) => {
				if (response?.fileName && response?.value) {
					this.saveFile(response);
					callback?.(true);
					this.clearItems();
				}
				if (response?.error) {
					this.alertService.error('digi_sign.download_failed', 'global');
					callback?.(false);
				}
			});
	}

	// Code from digitalSignView
	private saveFile(file: any) {
		const blob = this.b64toBlob(file.value, 'application/vnd.etsi.asic-e+zip');
		const elem = globalThis?.window?.document?.createElement('a');
		elem.href = globalThis?.window?.URL?.createObjectURL(blob);
		elem.download = file?.fileName;
		globalThis?.document?.body?.appendChild(elem);
		elem.click();
		globalThis?.document?.body?.removeChild(elem);
	}

	private readonly b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};
}
