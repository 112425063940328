import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageSwitchLink } from '@app/_core/models/interfaces/main';
import { GenericObject } from '@app/_core/models/interfaces/utils.model';
import { getLangCode } from '@app/_core/router-utility';
import { findFromObj } from '@app/_core/utility';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

export enum LanguageCodes {
	ESTONIAN = 'et',
	ENGLISH = 'en',
	RUSSIAN = 'ru',
}

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	constructor(
		private http: HttpClient,
		public route: ActivatedRoute,
	) {
		this.activeLang = getLangCode();
		this.setUrl();
		this.ehisUrl = environment.EHIS_URL;
	}

	public url: string = '';
	// Api url without language suffix
	public baseUrl = environment.API_URL;
	public ehisUrl: string = '';
	public login = '/api/v1/token?_format=json';
	public mobileLogin = '/custom/login/mobile_id?_format=json';
	public error: boolean = false;
	public data: any;
	public compareObservable = new Subject<string>();

	availableLanguages: Record<string, string | LanguageCodes>[] = [
		{ label: 'frontpage.et', code: LanguageCodes.ESTONIAN },
		{ label: 'frontpage.en', code: LanguageCodes.ENGLISH },
		{ label: 'frontpage.ru', code: LanguageCodes.RUSSIAN },
	].filter(({ code }) =>
		(environment.LANGUAGES || ['et']).includes(code as string),
	);

	private activeLang: LanguageCodes = LanguageCodes.ESTONIAN;
	activeLang$ = new Subject();
	get currentAppLanguage() {
		return this.activeLang;
	}
	set currentAppLanguage(code: LanguageCodes) {
		document.documentElement.lang = code;
		if (this.activeLang === code) return;
		this.activeLang = code;
		this.activeLang$.next(code);
		this.setUrl();
	}

	private languageSwitchLinks: LanguageSwitchLink[];

	get currentLanguageSwitchLinks() {
		return this.languageSwitchLinks;
	}

	set currentLanguageSwitchLinks(links: LanguageSwitchLink[]) {
		this.languageSwitchLinks = links;
	}

	getLangPrefix(): string {
		return this.currentAppLanguage !== LanguageCodes.ESTONIAN
			? `/${this.currentAppLanguage}`
			: '/';
	}

	getCurrentLanguageSwitchLink(): string | undefined {
		return this.languageSwitchLinks?.find(
			(link) => link?.langcode === this.activeLang,
		)?.path;
	}

	setUrl(): void {
		this.url = `${environment.API_URL}${
			this.activeLang === LanguageCodes.ESTONIAN
				? ''
				: `/${this.activeLang.toLowerCase()}`
		}`;
	}

	/**
	 * Compiles GraphQL query string from query name and variables
	 * @param [name] - query name. Found in variables API request
	 * @param [variables] - variables object. Usually consists of path, lang etc.
	 * @returns - url string to use in http request
	 */
	public query(name: string = '', variables: object = {}) {
		if (
			variables &&
			variables['path'] &&
			this.activeLang !== LanguageCodes.ESTONIAN
		) {
			Object.assign(variables, {
				path: `${this.activeLang}${variables['path']}`,
			});
		}

		const requestName = this.get(`request.${name}`);
		let path = `${this.url}/graphql?queryName=${name}&queryId=${requestName}`;
		path = `${path}&variables=${encodeURI(
			JSON.stringify({
				...variables,
				lang: this.activeLang.toUpperCase(),
			}),
		)}`;

		return path;
	}

	public queryParams<T = Record<string, unknown>>(variables?: T) {
		const stringified = Object.keys(variables).reduce<Record<string, string>>(
			(object, key) => {
				if (variables[key] != null) {
					if (Array.isArray(variables[key])) {
						// Attempt to get the value property from array items, fall back to item itself
						object[key] = variables[key].map(item => item?.value || item).join(',');
					} else {
						object[key] = variables[key];
					}
				}
				return object;
			},
			{},
		);
		const params = new URLSearchParams(stringified);
		return params.toString();
	}

	public queryList<T = Record<string, unknown>>(
		contentType: string,
		params: T,
	) {
		const parameterized = this.queryParams(params);
		return `${this.url}/api/list?_format=json&content_type=${contentType}&${parameterized}`;
	}

	public queryFilters(type: string, params: GenericObject = {}): string {
		const parameterized = this.queryParams(params);
		return `${this.url}/api/filters?_format=json&type=${type}&lang=${this.currentAppLanguage}${parameterized ? '&' + parameterized : ''}`;
	}

	public queryTags(type: string): string {
		return `${this.url}/api/tags?_format=json&content_type=${type}&lang=${this.currentAppLanguage}`;
	}

	/**
	 * Get query ID
	 * @param [name] - query name found in variables API request
	 * @returns - query id string
	 */
	public queryID(name: string = '') {
		return this.get(`request.${name}`);
	}

	/**
	 * @param [key] - query name found in variables API request
	 * @returns - query string with appended :1
	 */
	public get(key: string = '') {
		let output = findFromObj(this.data, key) || undefined;
		if (key.match(/request\./gim)) {
			output = `${output}:1`;
		}
		return output;
	}

	/**
	 * Loads settings service on app init
	 * @returns - boolean
	 */
	public load() {
		return new Promise((resolve, reject) => {
			const path = `${
				this.url
			}/variables?_format=json&lang=${this.activeLang.toLowerCase()}`;
			this.http.get(path).subscribe({
				next: (response) => {
					this.data = response;
					resolve(true);
				},
				error: (err) => {
					this.error = true;
					resolve(true);
				},
			});
		});
	}
}
