import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

const mobileBreakpoint = '(max-width: 1024px)';
@Injectable({ providedIn: 'root' })
export class BreakpointService {
	public isMobile$ = this.breakpointObserver?.observe(mobileBreakpoint);
	public isMobile = toSignal(this.isMobile$.pipe(map((val) => val?.matches)));
	// Using only one source to avoid weird overlapping states, desktop can only be true if mobile is not and vice versa
	public isDesktop = toSignal(this.isMobile$.pipe(map((val) => !val?.matches)));

	constructor(private breakpointObserver: BreakpointObserver) {}
}
