<ul [class.ellipsis]="ellipsis()">
  <li *ngFor="let item of data; let i = index;">
    <span class="arrow" *ngIf="i > 0" aria-hidden="true">
      <icon glyph="chevron-right"></icon>
    </span>
    <a *ngIf="item.link && isMainPage(item.link)" (click)="navigateToMainPage()"
      [attr.data-test-id]="'breadcrumbs-link-' + (item?.link === '/' ? 'index' :(item?.link | slugify))">
      {{ 'frontpage.label' | translate }}
    </a>	<!-- item.label -->
    <a *ngIf="item.link && !isMainPage(item.link)" [routerLink]="[item.link]"
      [attr.data-test-id]="'breadcrumbs-link-' + (item?.link === '/' ? 'index' :(item?.link | slugify))">
      {{ item.title | translate }}
    </a>
    <ng-container *ngIf="!item.link">
      <span aria-current="page" data-test-id="breadcrumbs-link-current">
          {{ item.title | translate }}
      </span>
    </ng-container>
  </li>
</ul>
