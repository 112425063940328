<block>
	<block-title><h2>{{ 'event.related' | translate }}</h2></block-title>
	<block-content>
		<ng-container *ngIf="!loading && data.length">
			<div class="row">
				<ng-container *ngFor="let item of data">
					<div class="col-6 sm-12">
						<div class="entry" [routerLink]="[item.path]">
							<span class="entry__date" *ngIf="item.fieldEventMainDate"
								>{{ 'time.' + (item.fieldEventMainDate | weekday: "unix") |
								translate }}, {{ item.fieldEventMainDate | date: "dd.MM.yyyy"
								}}</span
							>
							<h3 class="entry__title">{{ item.title }}</h3>
							<span
								class="entry__author"
								*ngIf="item.person"
								tabindex="0"
								attr.aria-label="{{'author.author' | translate}} {{ item.person }}"
								>{{ item.person }}</span
							>
						</div>
					</div>
					<!--/col-6-->
				</ng-container>
			</div>
			<!--/row-->
		</ng-container>

		<ng-container *ngIf="!loading && !data.length">
			<div class="row">
				<div class="col-12">
					<div class="text-center">
						{{ 'event.related_no_results' | translate }}
					</div>
					<!--/text-center-->
				</div>
				<!--/col-12-->
			</div>
			<!--/row-->
		</ng-container>

		<loader *ngIf="loading"></loader>
	</block-content>
</block>
