<div class="picto">
    <img class="picto-image" [src]="img" alt=""/>
    <ng-container *ngIf="pictoDesigns[pictoNumber]">
        <div class="bg">
            <div *ngFor="let circle of pictoDesigns[pictoNumber].circles" class="circle"
                [style.margin-left.rem]="circle.left" [style.margin-top.rem]="circle.top"
                [style.font-size.rem]="circle.size" [style.z-index]="circle.z" [style.background-color]="circle.color">
            </div>
            <div *ngFor="let triangle of pictoDesigns[pictoNumber].triangles" class="triangle"
                [style.border-width]="triangle.height/2 +'rem '+ triangle.width +'rem '+ triangle.height/2 +'rem 0rem'"
                [style.margin-left.rem]="triangle.left" [style.margin-top.rem]="triangle.top"
                [style.transform]="'rotate(' + triangle.rotate + 'deg)'" [style.z-index]="triangle.z"
                [style.border-color]="triangle.color"></div>
        </div>
    </ng-container>
</div>