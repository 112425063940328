<ul *ngIf="entriesData && entriesData.length">
  <ng-container *ngFor="let item of entriesData;">
    <li *ngFor="let elem of item[1]; let last = last;" [ngClass]="{
      'last': last,
      'proColor': item[0] === 'fieldPros',
      'neutralColor': item[0] === 'fieldNeutral',
      'conColor': item[0] === 'fieldCons'}">
      <icon aria-hidden="true" size="medium" bg="true" [glyph]="(item[0] === 'fieldPros' && 'arrow-up')
        || (item[0] === 'fieldNeutral' && 'minimize-2')
        || (item[0] === 'fieldCons' && 'arrow-down')"></icon>
      <span role="text">{{ elem }}</span>
    </li>
  </ng-container>
</ul>
