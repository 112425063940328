<div class="header-notifications__wrapper">
	<button
		htm-button
		theme="plain"
		[noPadding]="true"
		data-test-id="header-notifications-toggle-button"
		aria-controls="header-notifications-content"
		[attr.aria-expanded]="contentOpen"
		aria-haspopup="true"
		[attr.aria-label]="(contentOpen 
      ? 'notifications_block.close_notifications' 
      : 'notifications_block.open_notifications') 
        | translate"
		(click)="contentOpen = !contentOpen;"
	>
		<icon
			glyph="bell"
			[class.header-notifications__toggle-icon--unread]="unreadCount > 0"
		></icon>
	</button>
	<div
		class="header-notifications__content"
		id="header-notifications-content"
		[class.header-notifications__content--open]="contentOpen"
	>
		<div class="header-notifications__content-header">
			<span class="header-notifications__title"
				>{{ 'notifications_block.my_notifications' | translate }}</span
			>
			<button
				class="header-notifications__close-button"
				htm-button
				theme="plain"
				[noPadding]="true"
				[attr.aria-label]="'notifications_block.close_notifications' | translate"
				(click)="contentOpen = false;"
			>
				<icon glyph="x"></icon>
			</button>
		</div>
		<ol class="header-notifications__list" *ngIf="notificationsLength">
			<li
				class="header-notifications__list-item"
				*ngFor="let notification of notifications; let i = index;"
				[class.header-notifications__list-item--with-separator]="notificationsLength > 1 && i < notificationsLength - 1"
				[class.header-notifications__list-item--unread]="!notification?.read"
			>
			
			<a [routerLink]="('/töölaud/teavitused'| translatePath) + ('/' + notification?.id) "
			class="header-notifications__subject"
			[class.header-notifications__subject--unread]="!notification?.read"
			>
				{{notification?.subject}}
			</a>
				<span class="header-notifications__time"
				>{{notification?.formattedSentAt?.date}} {{'notifications_block.time'
				| translate}} {{notification?.formattedSentAt?.time}}</span
				>
			</li>
		</ol>
		<p *ngIf="!notificationsLength">
			{{ 'notifications_block.no_notifications' | translate }}
		</p>
		<button
			class="header-notifications__view-all"
			htm-button
			theme="outline"
			(click)="routeToNotificationsPage()"
		>
			<span
				>{{ 'notifications_block.view_all_notifications' | translate }}</span
			>
			<span class="header-notifications__count" *ngIf="unreadCount > 0"
				>{{unreadCount}}</span
			>
		</button>
	</div>
</div>
