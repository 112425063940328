import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { GenericObject } from '@app/_core/models/interfaces/utils.model';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { AlertsService } from '../AlertsService';
import { SettingsService } from '../SettingsService';
import { isNeuroTranslated } from './helpers';
import { neuroTranslationAlertId } from './neuro-translation.model';

@Injectable({ providedIn: 'root' })
export class NeuroTranslationService {
	constructor(private settingsService: SettingsService) {
		this.settingsService.activeLang$
			.pipe(takeUntilDestroyed())
			.subscribe(() => this.alertsService.clear(neuroTranslationAlertId));
		this.router.events
		.pipe(takeUntilDestroyed())
		.subscribe((res) => {
			if (res instanceof NavigationStart) {
				this.alertsService.clear(neuroTranslationAlertId);
			}
		});
	}
	private alertsService = inject(AlertsService);
	private translationService = inject(TranslateService);
	private location = inject(Location);
	private router = inject(Router);

	checkDetailViewContent(data: GenericObject, isHomePage = false): void {
		if (
			isNeuroTranslated(
				data?.content?.fieldNeurotolgeState,
				this.settingsService.currentAppLanguage
			)
		) {
			const path = this.location?.path();
			if (path?.includes('node/') || isHomePage) {
				this.alertsService.clear(neuroTranslationAlertId);
				this.alertsService.neuroTranslation(
					this.translationService.get('neuro_translation.just_translated'),
					neuroTranslationAlertId,
					isHomePage
				);
			}
		}
	}
}
