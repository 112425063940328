import { Routes } from '@angular/router';

export const curriculaRoutes: Routes = [
	{
		path: '',
		loadComponent: () =>
			import('./list/curricula-list.component').then(
				(m) => m.CurriculaListComponent
			),
	},
	{
		path: 'võrdlus',
		loadChildren: () =>
			import('../../_views/compareView').then((m) => m.CompareViewModule),
		data: {
			type: 'curriculaComparison',
			query: 'curriculaComparison',
		},
	},
	{
		path: ':id',
		loadComponent: () =>
			import('./detail/curriculum-detail.component').then(
				(m) => m.CurriculumDetailComponent
			),
	},
];
