import { Injectable, RendererFactory2 } from '@angular/core';
import { LanguageCodes, SettingsService } from '@app/_services/SettingsService';
import {
	getLangCode,
	isMainPage,
	isOnSearchPage,
	isWildcardPage,
	translatePathTo,
} from '@app/_core/router-utility';
import { Router } from '@angular/router';
import { Observable, map, shareReplay, startWith, switchMap } from 'rxjs';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { LanguageSwitcherData } from '@app/components/language-switcher/language-switcher.model';
export const languageServiceInitializer = (service: LanguageService) => {
	return () => service.init();
};

const navigationPaths = {
	'/ametialad/andmed': 'en/professions/data',
	'/en/professions/data': 'ametialad/andmed',

	'/valdkonnad/andmed': 'en/sectors/data',
	'/en/sectors/data': 'valdkonnad/andmed',

	// should be here, because calendar's render occurs in Angular
	'/sündmused/kalender': 'en/events/calendar',
	'/en/events/calendar': 'sündmused/kalender',
};

const langPrefixes = [`/${LanguageCodes.ENGLISH}/`];

@Injectable({ providedIn: 'root' })
export class LanguageService {
	public languageSwitcherData$ = this.getLanguageData();
	
	constructor(
		private settingsService: SettingsService,
		private rendererFactory: RendererFactory2,
		private router: Router,
		private translateService: TranslateService
	) {}

	init() {
		this.handleBadLanguageChanges();
		// Wait for both active language and translationsupdated observables to update before changing url
		this.settingsService.activeLang$
			.pipe(switchMap(() => this.translateService.translationsUpdated$))
			.subscribe(() => {
				this.updateUrl(this.settingsService.currentAppLanguage);
			});
	}

	handleBadLanguageChanges() {
		const renderer = this.rendererFactory.createRenderer(null, null);
		renderer.listen('window', 'popstate', () => {
			// Bad old method of reloading when user goes back in history to a page with another language
			// TODO: find a better way
			if (this.settingsService.currentAppLanguage !== getLangCode()) {
				// Using globalThis to avoid ssr issues
				globalThis?.window?.location?.reload();
			}
		});
	}

	private validatePathFallback(code: LanguageCodes): void {
		const encodedUrl = encodeURI(this.router?.url);
		const targetUrl = navigationPaths?.[encodedUrl];
		if (targetUrl) {
			this.router?.navigate(targetUrl);
		} else {
			try {
				this.navigate(translatePathTo(this.router?.url, code));
			} catch (error) {
				this.navigate(code === LanguageCodes.ESTONIAN ? '' : code);
			}
		}
	}

	// Mostly old logic with some newish refactor, can't think of a better way for now
	private updateUrl(code: LanguageCodes): void {
		const newUrl = this.settingsService.currentLanguageSwitchLinks?.find(
			(link) => link?.langcode === code
		)?.path;
		// Check BE provided link first
		if (newUrl) {
			let newUrlWithLanguage = newUrl;
			if (
				this.settingsService.currentAppLanguage !== LanguageCodes.ESTONIAN &&
				!langPrefixes?.some((prefix) => newUrl?.includes(prefix))
			) {
				newUrlWithLanguage =
					`/${this.settingsService.currentAppLanguage}/${newUrl}`?.replace(
						'//',
						'/'
					);
			}
			this.navigate(decodeURIComponent(newUrlWithLanguage));
			return;
		}

		if (isMainPage()) {
			this.navigate(code === LanguageCodes.ESTONIAN ? '' : code);
			return;
		}

		// Strip query params and try to match the route
		if (isOnSearchPage() || this.router?.url?.includes('?')) {
			const pathSplit = this.router.url.split('?');
			const translatedPath = translatePathTo(pathSplit[0], code);
			this.navigate(translatedPath);
			return;
		}

		if (isWildcardPage()) {
			this.navigateToMainPage();
			return;
		}

		this.validatePathFallback(code);
	}

	private navigateToMainPage(): void {
		const path =
			getLangCode() === LanguageCodes.ESTONIAN ? '/' : `/${getLangCode()}`;
		this.navigate(path);
	}

	private navigate(path: string) {
		this.router.navigate([path || '']);
	}

	private getLanguageData(): Observable<LanguageSwitcherData> {
		// Start active language subject with current language,
		// map it into language data with available langauges
		return this.settingsService.activeLang$.pipe(
			startWith(this.settingsService.currentAppLanguage),
			map((activeLangCode: string) => {
				const activeLang = this.settingsService.availableLanguages?.find(
					(lang) => lang?.code === activeLangCode
				);
				return {
					activeLang,
					availableLanguages: this.settingsService.availableLanguages,
				};
			}),
			// Keep the source same for all subscribers (aka all language switchers)
			shareReplay()
		);
	}

	public handleLanguageChange(code: LanguageCodes) {
		this.settingsService.currentAppLanguage = code;
	}
}
