<ng-container *ngIf="data.fieldEntryType && data.fieldEntryType == 'register'">
  <h2>{{ 'event.registration' | translate }}</h2>
  <p>
    <ng-container *ngIf="canRegister() == true">
      <span>{{'button.register' | translate}} {{'time.from' | translate | lowercase}} </span>
      <span>{{ data.fieldRegistrationDate?.[0].fieldRegistrationFirstDate | date:'dd.MM.yyyy' }} </span>
      <span>{{'time.to' | translate | lowercase}} {{ data.fieldRegistrationDate?.[0].fieldRegistrationLastDate | date:'dd.MM.yyyy' }}</span>
    </ng-container>
    <span class="error" *ngIf="canRegister() == 'ended'" role="alert">{{'event.registration_ended' | translate}}</span>
    <span class="error" *ngIf="canRegister() == 'not_started'" role="alert">{{'event.registration_not_started' | translate}}</span>
    <span class="error" *ngIf="canRegister() == 'full'" role="alert">{{'event.registration_full' | translate}}</span>
  </p>
  <div class="actions" *ngIf="canRegister() == true">
    <button htm-button (click)="modal.toggle('register')"
      data-test-id="sidebar-register-toggle-register-modal-button"
    >{{ 'button.register' | translate }}</button>
    <a href="{{ iCalUrl }}{{ pageData.nid }}"
      data-test-id="sidebar-register-calendar-add-link"
      >{{'button.calendar_add' | translate}}</a>
  </div>

  <htm-modal
    id="register"
    modalTitle="{{ 'event.registration_form' | translate }}"
    [bottomAction]="false"
    (onClose)="clearModal()"
  >
    <ng-template id="register">
      <ng-container *ngIf="!loading && step === 1">
        <h2 class="eventTitle">{{ pageData.title }}</h2>
        <div class="dialog__time" tabindex="0"> 
          <span class="capitalize">{{ 'time.'+ (pageData.eventStartDate | weekday: "unix" | lowercase) | translate }} {{ pageData.eventStartDate | date: "dd.MM"}}</span>
          
          <span *ngIf="pageData.fieldEventStartTimeMain || pageData.fieldEventEndTimeMain">
            <span> {{ 'time.time_of_day' | translate }}</span>
            <ng-container *ngIf="pageData.fieldEventStartTimeMain">
              {{ pageData.fieldEventStartTimeMain | unixToTime  }}
            </ng-container>
            <ng-container *ngIf="pageData.fieldEventStartTimeMain && pageData.fieldEventEndTimeMain">
              -
            </ng-container>
            <ng-container *ngIf="pageData.fieldEventEndTimeMain">
              {{ pageData.fieldEventEndTimeMain | unixToTime  }}
            </ng-container>
          </span>
          <ng-container *ngIf="pageData.eventExtraDates && pageData.eventExtraDates.length">
            <div *ngFor="let item of pageData.eventExtraDates">
              <ng-container *ngIf="item.fieldEventAdditionalDate">
                <span class="capitalize">{{ 'time.'+ (item.fieldEventAdditionalDate | weekday: "unix" | lowercase) | translate }} {{ item.fieldEventAdditionalDate | date: "dd.MM"}}</span>
                <span *ngIf="item.fieldEventStartTimeAddition || item.fieldEventEndTimeAddition">
                  <span> {{ 'time.time_of_day' | translate }}</span>
                  <ng-container *ngIf="item.fieldEventStartTimeAddition">
                    {{ item.fieldEventStartTimeAddition | unixToTime  }}
                  </ng-container>
                  <ng-container *ngIf="item.fieldEventStartTimeAddition && item.fieldEventEndTimeAddition">
                    -
                  </ng-container>
                  <ng-container *ngIf="item.fieldEventEndTimeAddition">
                    {{ item.fieldEventEndTimeAddition | unixToTime  }}
                  </ng-container>
                </span>
                
              </ng-container>
            </div>
          </ng-container>
        </div>

        <p>* {{ 'event.registration_form_required' | translate }}</p>

        <form [formGroup]="form" (submit)="submitForm()">
          <div class="row">
            <div class="col-6 md-12 sm-12">
              <formItem type="text" [title]="('event_registration.first_name' | translate) + ' *'" formControlName="firstName" [error]="formSubmitted && hasError('firstName')"
              testId="sidebar-register-first-name-input"
              ></formItem>
            </div><!--/col-6-->
            <div class="col-6 md-12 sm-12">
              <formItem type="text" [title]="('event_registration.last_name' | translate) + ' *'" formControlName="lastName" [error]="formSubmitted && hasError('lastName')"
              testId="sidebar-register-last-name-input"
              ></formItem>
            </div><!--/col-6-->
            <div class="col-12">
              <formItem type="text" [title]="'event_registration.company_name' | translate" formControlName="companyName"
              testId="sidebar-register-company-name-input"
              ></formItem>
            </div><!--/col-12-->
            <div class="col-6 md-12 sm-12">
              <formItem type="text" [title]="('event_registration.email' | translate) + ' *'" formControlName="email" [error]="formSubmitted && hasError('email')"
              testId="sidebar-register-email-input"
              ></formItem>
            </div><!--/col-6-->
            <div class="col-6 md-12 sm-12">
              <formItem type="text" [title]="('event_registration.phone' | translate) + ' *'" formControlName="telephone"
              [error]="formSubmitted && hasError('telephone')"
              testId="sidebar-register-telephone-input"
              ></formItem>
            </div><!--/col-6-->
            <div class="col-12">
              <formItem type="textarea" [title]="'event_registration.marked' | translate" formControlName="marked"
              testId="sidebar-register-marked-input"
              ></formItem>
            </div><!--/col-12-->
          </div><!--/row-->
          <div class="buttons">
            <a htm-button theme="plain" (click)="modal.close('register')"
              data-test-id="sidebar-register-close-register-modal-link">
              {{'event.registration_form_cancel' | translate}}
            </a>
            <button htm-button
              data-test-id="sidebar-register-registration-submit-button"
              >{{'event.registration_form_send' | translate}}
            </button>
          </div><!--/buttons-->
        </form>
      </ng-container>
      <ng-container *ngIf="!loading && step == 2">
        <div class="registerResponse">
          <h2 *ngIf="response && response?.errors?.length == 0">{{ 'event.registration_form_thanks' | translate }}</h2>
          <ng-container *ngIf="response">
            <ng-container *ngFor="let item of response?.errors">
              <h2 *ngIf="item == 1">{{ 'event.registration_full' | translate }}</h2>
            </ng-container>
          </ng-container>
          <a htm-button href="{{ iCalUrl }}{{ pageData.nid }}"
            data-test-id="sidebar-register-second-step-calendar-add-link"
            >{{'event.registration_form_add_to_calendar' | translate}}</a>
          <button htm-button theme="plain" (click)="modal.close('register')"
            data-test-id="sidebar-register-second-step-close-register-modal-button"
            >{{ 'event.registration_form_close' | translate }}</button>
        </div><!--/registerResponse-->
      </ng-container>
      <loader *ngIf="loading"></loader>
    </ng-template>
  </htm-modal>
</ng-container>

<ng-container *ngIf="data.fieldEntryType == 'external_inv' || data.fieldEntryType === 'juhan'">
  <h3>{{ 'event.registration' | translate }}</h3>
  <div class="actions" *ngIf="data.fieldRegistrationUrl?.length">
    <a target="_blank" [href]="data.fieldRegistrationUrl[0].uri"
      [attr.data-test-id]="'sidebar-register-action-link-' + (data?.fieldRegistrationUrl?.[0]?.uri | slugify)"
    >{{ 'event.external_registration' | translate }}</a>
  </div>
</ng-container>

<ng-container *ngIf="data.fieldEntryType && data.fieldEntryType == 'free' && !data.EventRegistrations">
  <h3>{{ 'event.entrance' | translate }}</h3>
  <p>
    <span *ngIf="canRegister() !== 'ended'" >{{'event.free' | translate}}</span>
    <span class="error" *ngIf="canRegister() == 'ended'">{{'event.passed' | translate}}</span>
  </p>
</ng-container>

<ng-container *ngIf="data.fieldEntryType && data.fieldEntryType == 'invite'">
  <h3>{{ 'event.registration' | translate }}</h3>
  <p>
    <span *ngIf="canRegister() !== 'ended'">{{'event.invite' | translate}}</span>
    <span class="error" *ngIf="canRegister() == 'ended'" >{{'event.passed' | translate}}</span>
  </p>
</ng-container>

<ng-container *ngIf="data.EventRegistrations && step === 4">
  <h3>{{'event.participant_list' | translate}}</h3>
  <div class="actions">
    <button htm-button (click)="toggleParticipants(true)"
      data-test-id="sidebar-register-toggle-participants-button"
      >{{'event.participants_list_view' | translate}}</button>
  </div><!--/actions-->
</ng-container>
