<ng-container *ngIf="options">
  <div class="map__wrapper">
    <div class="map-loader" *ngIf="loading">
      <loader></loader>
    </div>

    <ol-map
      (mapReady)="mapReady($event)"
      (zoomChange)="zoomChange($event)"
      [zoom]="options.zoom"
      [minZoom]="options.minZoom"
      [maxZoom]="options.maxZoom"
      [draggable]="options.draggable"
      [enableZoomControl]="options.enableZoomControl"
    >
      <ol-data-layer
        *ngIf="type === 'polygons' && polygons"
        [geoJson]="polygons"
        [styles]="mapService.polygonStyles"
        (layerClick)="mapService.layerClick($event, options.polygonType)"
      ></ol-data-layer>

      <ng-container
        *ngIf="type === 'polygons' && polygonMarkers && polygonMarkers.length && polygons && polygons.features && polygonLayer == 'county'"
      >
        <ng-container *ngFor="let item of polygonMarkers">
          <ol-marker
            *ngIf="item.labelOptions && item.labelOptions.text"
            [latitude]="item.latitude"
            [longitude]="item.longitude"
            [label]="item.labelOptions"
          ></ol-marker>
        </ng-container>
      </ng-container>

      <ol-clusters
        *ngIf="type === 'markers' && markers && markers.length"
        [markers]="markers"
        [styles]="options.clusterStyles || defaultMapOptions.clusterStyles"
        [iconUrl]="options.icon || defaultMapOptions.icon"
      >
        <ng-template let-marker="feature" let-close="close">
          <ol-info-window
            (close)="close()"
            *ngIf="marker.schoolInfo || marker.FieldSchoolName || marker.FieldName"
          >
            <ng-container *ngIf="marker.schoolInfo">
              <p class="title with--border">
                {{ marker.schoolInfo.entityLabel }}
              </p>

              <ng-container *ngIf="!infoWindowFunding">
                <p *ngIf="marker.schoolInfo.fieldRegistrationCode">
                  {{ 'school.register_code' | translate }}:
                  <strong>{{ marker.schoolInfo.fieldRegistrationCode }}</strong>
                </p>
                <p *ngIf="marker.schoolInfo.fieldOwnershipType">
                  {{ 'school.ownership' | translate }}:
                  <strong
                    >{{ marker.schoolInfo.fieldOwnershipType
                    }}</strong
                  >
                </p>
              </ng-container>
              <ng-container *ngIf="!infoWindowFunding">
                <hr />
                <p>
                  {{ 'school.select_investment_measure_year' | translate }}:
                </p>
                <div class="tab-buttons">
                  <ng-container
                    *ngFor="let item of mapService.groupYears(marker.schoolInfo.subsidies)"
                  >
                    <button (click)="showFunding(item, infoWindow)" [attr.data-test-id]="'map-open-funding-button-' + item">
                      {{ item }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="infoWindowFunding">
                <a
                  href="javascript:void(0);"
                  (click)="showFunding(false)"
                  class="link"
                  data-test-id="map-close-funding-link"
                  >&lt; {{ 'button.back' | translate }}</a
                >
                <div class="snazzy-scrollarea">
                  <p>
                    {{ infoWindowFunding }}-{{
                    'school.selected_year_investment_measure' | translate }}
                  </p>
                  <ng-container
                    *ngFor="let item of mapService.parseInfoWindowMarkerData(marker.schoolInfo.subsidies)"
                  >
                    <ng-container *ngIf="item.year == infoWindowFunding">
                      <p>
                        {{ 'school.investment_measure' | translate }}:
                        <strong>{{ item.investmentMeasure?.entityLabel }}</strong
                        ><br />
                        {{ 'school.investment_project' | translate }}:
                        <strong>{{ item.investmentProject }}</strong><br />
                        {{ 'school.investment_max_amount' | translate }}:
                        <strong>{{ item.investmentAmount | currency:"EUR" }}</strong
                        ><br />
                        {{ 'school.investment_deadline' | translate }}:
                        <strong
                          >{{ item.investmentDeadline |
                          date:"dd.MM.yyyy" }}</strong
                        >
                      </p>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="marker.FieldSchoolName">
              <p class="title with--border">{{ marker.FieldSchoolName }}</p>
              <p *ngIf="marker.FieldAddress">
                {{ 'school.map_address' | translate }}:
                <strong>
                  <a
                    [href]="'https://google.com/maps/place/' + (marker.Lat || marker.lat) + ',' + (marker.Lon || marker.lon)"
                    rel="nofollow noopener"
                    target="_blank"
                    [attr.data-test-id]="'map-school-coordinates-link-' + (marker.Lat || marker.lat) + ',' + (marker.Lon || marker.lon)"
                  >{{ marker.FieldAddress }}</a>
                </strong>
              </p>

              <p *ngIf="marker.FieldEducationalInstitutionTy">
                {{ 'school.institution_type' | translate }}:
                <strong>{{ marker.FieldEducationalInstitutionTy }}</strong>
              </p>
              <p
                *ngIf="marker.FieldSchoolContactPhone || marker.FieldSchoolContactEmail"
              >
                {{ 'school.contact_info' | translate }}:
                <a *ngIf="marker.FieldSchoolContactPhone"
                  href="tel:{{ marker.FieldSchoolContactPhone }}"
                  [attr.data-test-id]="'map-school-phone-' + marker.FieldSchoolContactPhone"
                  >{{ marker.FieldSchoolContactPhone }}</a
                ><span
                  *ngIf="marker.FieldSchoolContactPhone && marker.FieldSchoolContactEmail"
                  >, </span
                >
                <a
                  *ngIf="marker.FieldSchoolContactEmail"
                  href="mailto:{{ marker.FieldSchoolContactEmail }}"
                  [attr.data-test-id]="'map-school-email-' + marker.FieldSchoolContactEmail"
                  ><strong>{{ marker.FieldSchoolContactEmail }}</strong></a
                >
              </p>

              <p
                style="margin-bottom: 20px"
                *ngIf="marker.FieldSchoolWebpageAddress"
              >
                {{ 'school.website' | translate }}:
                <a
                  href="{{ marker.FieldSchoolWebpageAddress | url }}"
                  target="_blank"
                  rel="noopener"
                  [attr.data-test-id]="'map-school-webpage-' + (marker.FieldSchoolWebpageAddress | removeProtocol | slugify)"
                  ><strong
                    >{{ marker.FieldSchoolWebpageAddress | removeProtocol }}</strong
                  ></a
                >
              </p>
              <a [routerLink]="marker.EntityPath" class="button" [attr.data-test-id]="'map-school-view-more-link-' + (marker?.EntityPath | slugify)"
                >{{ 'button.view_more' | translate }}</a
              >
            </ng-container>
            <ng-container *ngIf="marker.FieldJobTime">
              <p class="title with--border">{{ marker.FieldName }}</p>

              <p *ngIf="marker.FieldJobTime">
                <ng-container
                  *ngFor="let timeStr of marker.FieldJobTime; let isLast = last"
                >
                  <strong>{{ timeStr | translate }}</strong
                  ><span *ngIf="!isLast">, </span>
                </ng-container>
              </p>

              <p *ngIf="marker.FieldOfferedBy" class="no-margin">
                {{ 'jobOffer.offered_by' | translate }}:
                <strong>{{ marker.FieldOfferedBy }}</strong>
              </p>
              <p *ngIf="marker.FieldAddress" class="no-margin">
                {{ 'event.aadress' | translate }}:
                <strong>
                  <a
                    [href]="'https://google.com/maps/place/' + (marker.Lat || marker.lat) + ',' + (marker.Lon || marker.lon)"
                    rel="nofollow noopener"
                    target="_blank"
                    [attr.data-test-id]="'map-job-coordinates-link-' + (marker.Lat || marker.lat) + ',' + (marker.Lon || marker.lon)"
                  >{{ marker.FieldAddress }}</a>
                </strong>
              </p>
              <p *ngIf="marker.FieldDate" class="no-margin end-item">
                {{ 'jobOffer.job_expiry' | translate }}:
                <strong>{{ marker.FieldDate * 1000 | date:"dd.MM.yyyy" }}</strong>
              </p>
              <a
                href="{{ marker.FieldURL | url }}"
                target="_blank"
                rel="noopener"
                class="button"
                [attr.data-test-id]="'map-job-url-link-' + (marker.FieldURL | removeProtocol | slugify)"
              >
                <icon glyph="external-link" size="medium"></icon>
                <span>{{ 'button.view_more' | translate }}</span>
              </a>
            </ng-container>
          </ol-info-window>
        </ng-template>
      </ol-clusters>

      <ng-container
        *ngIf="mapService.infoLayer && mapService.infoLayer.status && options.enablePolygonModal"
      >
        <ol-overlay
          [isOpen]="mapService.infoLayer.status"
          [latitude]="mapService.infoLayer.latitude"
          [longitude]="mapService.infoLayer.longitude"
          (isOpenChange)="layerClickStatus($event)"
        >
          <ng-template let-close="close">
            <ol-info-window (close)="close()">
              <p *ngIf="mapService.infoLayer.title" class="title">
                {{ mapService.infoLayer.title }}
              </p>
              <p>{{ mapService.infoLayer.currency | currency }}</p>
            </ol-info-window>
          </ng-template>
        </ol-overlay>
      </ng-container>
    </ol-map>

    <div
      class="map__additions"
      *ngIf="options.enableParameters && parameters && parameters.length"
    >
      <div class="pair" *ngFor="let item of parameters">
        <div class="pair__label">{{ item.label }}:</div>
        <div class="pair__value">{{ item.value | shorten: 20: '...' }}</div>
      </div>
    </div>

    <a
      class="outerLink"
      *ngIf="options.enableOuterLink"
      href="http://www.google.com/maps/place/{{ options.centerLat }},{{ options.centerLng }}"
      attr.aria-label="{{'button.map_closer' | translate}}"
      target="_blank"
      [attr.data-test-id]="'map-outer-link-' + options.centerLat + ',' + options.centerLng"
    >
      <icon glyph="external-link" size="medium"></icon>
      <span>{{'button.map_closer' | translate}}</span>
    </a>

    <div
      class="polygons__toggle"
      *ngIf="type === 'polygons' && polygons && options.enableLayerSelection"
    >
      <button
        (click)="changeLayer('county')"
        [class.active]="polygonLayer === 'county'"
        data-test-id="map-enable-county-layer-button"
      >
        {{ 'school.counties' | translate }}
      </button>
      <button
        (click)="changeLayer('kov')"
        [class.active]="polygonLayer === 'kov'"
        data-test-id="map-enable-municipalities-layer-button"
      >
        {{ 'school.municipalities_detailed' | translate }}
      </button>
    </div>
  </div>

  <div
    class="map__legend"
    *ngIf="options.enablePolygonLegend && heatmap && heatmap.length > 0 && type === 'polygons' && options.polygonType === 'investment'"
  >
    <div
      class="map__legend__block"
      *ngFor="let item of heatmap; let first = first; let last = last;"
    >
      <div
        class="map__legend__color"
        [style.background-color]="item.color"
      ></div>
      <div
        class="map__legend__item"
        *ngIf="options.polygonType === 'investment'"
      >
        {{ item.minAmount | legendCurrency:"min" }} - {{ item.maxAmount |
        legendCurrency:"max" }} €
      </div>
      <div
        class="map__legend__item"
        *ngIf="options.polygonType === 'oskaFields'"
      >
        {{ item.minAmount | legendCurrency:"min" }} - {{ item.maxAmount |
        legendCurrency:"max" }}
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="options.enablePolygonLegend && heatmap && heatmap.length > 0 && type === 'polygons' && options.polygonType === 'oskaFields'"
  >
    <div class="mapLegend">
      <ng-container *ngFor="let item of heatmap">
        <div
          class="mapLegend__block"
          *ngIf="(params && params.mapIndicator && mapService.fieldMaxRanges[params.mapIndicator] && item.amount <= mapService.fieldMaxRanges[params.mapIndicator])
          || (paramValue && mapService.fieldMaxRanges[paramValue] && item.amount <= mapService.fieldMaxRanges[paramValue])"
        >
          <div
            class="mapLegend__color"
            [style.background-color]="item.color"
          ></div>
        </div>
      </ng-container>
    </div>

    <div
      class="mapLegend__labels"
      *ngIf="legendLabels && activeLegendParameters"
    >
      <div>{{ activeLegendParameters.start }}</div>
      <div>{{ activeLegendParameters.end }}</div>
    </div>
  </ng-container>
</ng-container>
