import { CommonModule, TitleCasePipe } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
	AnalyticsEvent,
	CornerLogoDirective,
	FiltersDirective,
	LazyImgDirective,
	RippleDirective,
	RotateTableDirective,
	ScrollToDirective,
} from '@app/_directives';
import { TranslateModule } from '@app/_modules/translate';
import {
	AnalyticsService,
	AuthService,
	BreakpointService,
	ModalService,
	RippleService,
	ScrollRestorationService,
	SettingsService,
	SidebarService,
	SidemenuService,
	UploadService,
} from '@app/_services';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { LoaderComponent } from '../components/loader';
import { ScrollableContentComponent } from '../components/scrollableContent';
import { AccordionComponent, AccordionItemComponent } from './accordion';
import { AlertsComponent } from './alerts';
import { ArticleLayout } from './article-layout';
import { AutocompleteComponent } from './autocomplete';
import {
	BlockComponent,
	BlockContentComponent,
	BlockSecondaryTitleComponent,
	BlockSecondaryTitleSubtextComponent,
	BlockSubTitleComponent,
	BlockTabsComponent,
	BlockTitleComponent,
} from './block';
import { ChartComponent } from './chart/chart.component';
import { CompareComponent } from './compare';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';
import { FeedbackComponent } from './feedback';
import { FormItemComponent } from './formItem';
import { ImageComponent } from './images';
import { LinksComponent } from './links';
import { ListItemComponent } from './listItem/listItem.component';
import { MainProfessionsComponent } from './mainProfessions/mainProfessions.component';
import { MapComponent } from './map';
import { MenuComponent, MenuItemComponent } from './menu';
import { ModalComponent, ModalContentComponent } from './modal';
import { NewsComponent } from './news/news.component';
import { SchoolsComponent } from './schools/schools.component';
import { SearchResultsComponent } from './searchResults';
import { ShareComponent } from './share';
import {
	SidebarActionsComponent,
	SidebarArticlesComponent,
	SidebarCategoriesComponent,
	SidebarComponent,
	SidebarContactComponent,
	SidebarDataComponent,
	SidebarDownloadFileComponent,
	SidebarEventsComponent,
	SidebarFactsComponent,
	SidebarFinalDocumentAccessComponent,
	SidebarFinalDocumentDownloadComponent,
	SidebarFinalDocumentHistoryComponent,
	SidebarGdprComponent,
	SidebarLinksComponent,
	SidebarLocationComponent,
	SidebarNotificationsComponent,
	SidebarProgressComponent,
	SidebarRegisterComponent,
} from './sidebar';
import { StudyProgrammesComponent } from './studyProgrammes/studyProgrammes.component';
import { SchoolTable, StudyProgrammeTable, TableComponent } from './table';
import { VideoComponent } from './video';

import { A11yModule } from '@angular/cdk/a11y';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppPipes } from '@app/_pipes';
import { AddressService } from '@app/_services/AddressService';
import { QueryParamsService } from '@app/_services/QueryParams.service';
import { TableService } from '@app/_services/tableService';
import { ButtonComponent } from '@app/components/button/button.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { LabelCountComponent } from '@app/components/label-count/label-count.component';
import { LabeledSeparatorComponent } from '@app/components/labeled-separator/labeled.separator.component';
import { LabelsComponent } from '@app/components/labels/labels.component';
import { LanguageSwitcherComponent } from '@app/components/language-switcher/language-switcher.component';
import { MaxWidthWrapperComponent } from '@app/components/max-width-wrapper/max-width-wrapper.component';
import { PictoComponent } from '@app/components/picto/picto.component';
import { ProgressBarComponent } from '@app/components/progressBar/progressBar.component';
import { SkeletonComponent } from '@app/components/skeleton/skeleton.component';
import { SkipToContentComponent } from '@app/components/skip-to-content/skip-to-content.component';
import { TagComponent } from '@app/components/tag/tag.component';
import { TextTruncateTogglerComponent } from '@app/components/text-truncate-toggler/text-truncate-toggler.component';
import { TooltipComponent } from '@app/components/tooltip/tooltip.component';
import { CertificateDetailedComponent } from '@app/modules/certificates/components/certificate-detailed/certificate-detailed.component';
import { CertificateDocumentCheckComponent } from '@app/modules/certificates/components/certificate-document-check/certificate-document-check.component';
import { CertificateFinalDocumentsComponent } from '@app/modules/certificates/components/certificate-final-documents/certificate-final-documents.component';
import { CertificateGradeSheetComponent } from '@app/modules/certificates/components/certificate-grade-sheet/certificate-grade-sheet.component';
import { CertificateComponent } from '@app/modules/certificates/components/certificate/certificate.component';
import { CertificatesComponent } from '@app/modules/certificates/components/certificates/certificates.component';
import { DarkModeModule } from '@app/modules/dark-mode/dark-mode.module';
import { FormInputsModule } from '@app/modules/form-inputs/form-inputs.module';
import { OlMapModule } from '@app/modules/olmap/olmap.module';
import { HTMSwiperModule } from '@app/modules/swiper/swiper.module';
import { BaseLayoutModule } from './base-layout/base-layout.module';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { CarouselComponent } from './carousel/carousel.component';
import { MoreBlockComponent } from './more.block/more.block.component';
import { NotFoundComponent } from './notFound';
import { RelatedEventsComponent } from './relatedEvents';
import { RelatedStudyProgrammesListComponent } from './relatedStudyprogrammesList/relatedStudyProgrammesList.component';
import { SessionExpirationComponent } from './sessionExpiration';

export function settingsProviderFactory(provider: SettingsService) {
	return () => provider.load();
}


const certificatesModuleDeclarations = [
	CertificatesComponent,
	CertificateDetailedComponent,
	CertificateComponent,
	CertificateFinalDocumentsComponent,
	CertificateDocumentCheckComponent,
	CertificateGradeSheetComponent,
];

const declarations = [
	BlockComponent,
	BlockContentComponent,
	BlockTitleComponent,
	BlockSecondaryTitleComponent,
	BlockSecondaryTitleSubtextComponent,
	BlockTabsComponent,
	CarouselComponent,
	AccordionComponent,
	AccordionItemComponent,
	TableComponent,
	SchoolTable,
	StudyProgrammeTable,
	VideoComponent,
	FeedbackComponent,
	MenuComponent,
	MenuItemComponent,
	FormItemComponent,
	RippleDirective,
	FiltersDirective,
	ScrollToDirective,
	RotateTableDirective,
	CornerLogoDirective,
	ModalComponent,
	ModalContentComponent,
	MainProfessionsComponent,
	ArticleLayout,
	SidebarComponent,
	SidebarLinksComponent,
	SidebarCategoriesComponent,
	SidebarContactComponent,
	SidebarArticlesComponent,
	SidebarDataComponent,
	SidebarActionsComponent,
	SidebarFactsComponent,
	SidebarLocationComponent,
	SidebarProgressComponent,
	SidebarRegisterComponent,
	SidebarEventsComponent,
	SidebarNotificationsComponent,
	SidebarGdprComponent,
	SidebarFinalDocumentAccessComponent,
	SidebarFinalDocumentDownloadComponent,
	SidebarFinalDocumentHistoryComponent,
	SidebarDownloadFileComponent,
	StudyProgrammesComponent,
	SchoolsComponent,
	MapComponent,
	ShareComponent,
	ChartComponent,
	ListItemComponent,
	SearchResultsComponent,
	NewsComponent,
	AutocompleteComponent,
	CompareComponent,
	ImageComponent,
	LinksComponent,
	// HomeSearchListViewComponent,
	DropdownListComponent,
	//
	BlockSubTitleComponent,
	RelatedStudyProgrammesListComponent,
	RelatedEventsComponent,
	AnalyticsEvent,
	NotFoundComponent,
	SessionExpirationComponent,
	MoreBlockComponent,
	...certificatesModuleDeclarations,
	LazyImgDirective
];

const exports = [
  BaseLayoutModule,
  BreadcrumbsModule,
  HTMSwiperModule,
  FormInputsModule,
  MatTooltipModule
];

const providers = [
  RippleService,
  ModalService,
  SidemenuService,
  SidebarService,
  TableService,
  AddressService,
  SettingsService,
  UploadService,
  AuthService,
  QueryParamsService,
  ScrollRestorationService,
  AnalyticsService,
  TitleCasePipe,
  BreakpointService,
  {
    provide: APP_INITIALIZER,
    useFactory: settingsProviderFactory,
    deps: [SettingsService],
    multi: true,
  },
];

const imports = [
	OlMapModule,
	CommonModule,
	RouterModule,
	TranslateModule,
	FormsModule,
	AppPipes,
	NgSelectModule,
	Ng2GoogleChartsModule,
	ReactiveFormsModule,
	A11yModule,
	BaseLayoutModule,
	BreadcrumbsModule,
	HTMSwiperModule,
	MatTooltipModule,
	FormInputsModule,
	IconComponent,
	ButtonComponent,
	LabelCountComponent,
	LabeledSeparatorComponent,
	LabelsComponent,
	MaxWidthWrapperComponent,
	PictoComponent,
	ProgressBarComponent,
	SkeletonComponent,
	SkipToContentComponent,
	TagComponent,
	TooltipComponent,
	IconComponent,
	DarkModeModule,
	LanguageSwitcherComponent,
	DarkModeModule,
	AlertsComponent,
	ScrollableContentComponent,
	LoaderComponent,
];

@NgModule({
	imports,
	declarations: [...declarations],
	exports: [...declarations, ...exports],
})
export class AssetsModule {
	static forRoot(): ModuleWithProviders<AssetsModule> {
		return {
			providers,
			ngModule: AssetsModule,
		};
	}
}
