<ng-container *ngIf="type == 'school'; else related">
  <loader *ngIf="loading"></loader>
  <ng-container *ngIf="!loading && list.length">
    <block>
      <block-title><h1>{{ title | translate | uppercase }}</h1></block-title>
      <block-content>
        <listItems [list]="list" type="studyProgramme" compare="studyProgrammeComparison"></listItems>
        <div class="text-center" *ngIf="!loading && !list.length">
          {{ 'studyProgramme.no_results' | translate }}
        </div><!--/text-center-->
      </block-content>
    </block>
  </ng-container>
  <div class="loadMore-wrapper" [class.load-more--space-bottom]="!loadingMore && (totalItems+1 > ((1 + page)  * 24))">
    <loader *ngIf="loadingMore"></loader>
    <button
      *ngIf="!loadingMore && (totalItems+1 > ((1 + page)  * 24))"
      htm-button
      (click)="loadMore()"
      type="button"
      data-test-id="related-study-programmes-school-load-more-button"
      >{{ 'button.load_more' | translate }}</button>
  </div><!--/loadMore-->
</ng-container>

<ng-template #related>
  <div class="text-center" *ngIf="!loading && list.length && !showList">
    <button
      htm-button
      data-test-id="related-study-programmes-view-similar-button"
      (click)="showListItems()">{{ 'studyProgramme.view_similar' | translate }}</button>
  </div><!--/text-center-->
  <block *ngIf="showList">
    <block-title><h2>{{ title | translate | uppercase }}</h2></block-title>
    <block-content>

      <form [formGroup]="relatedProgrammesForm" class="filter-wrapper" (submit)="getRelatedStudyprogrammes(nid)" filters>
        <formItem
          title="{{ 'studyProgramme.related_filter_location' | translate }}"
          type="text"
          name="address"
          formControlName="address"
          testId="related-study-programmes-search-address-field"
          ></formItem>
        <button
          type="button"
          (click)="getRelatedStudyprogrammes(nid)"
          htm-button
          data-test-id="related-study-programmes-search-submit-button"
          >{{ 'button.search' | translate}}</button>
      </form>
      <loader *ngIf="loading"></loader>
      <div class="text-center" *ngIf="!loading && !list.length">
        {{ 'studyProgramme.related_no_results' | translate }}
      </div><!--/text-center-->
      <label-count *ngIf="searchWithParams && !loading && totalItems && list?.length" [count]="totalItems">{{ 'search.results_of' | translate }}: </label-count>
      <listItems *ngIf="!loading" [list]="list" type="studyProgramme" compare="studyProgrammeComparison"></listItems>
    </block-content>
  </block>
  <div class="loadMore-wrapper" *ngIf="showList && !loading" [class.load-more--space-bottom]="!loadingMore && (totalItems+1 > ((1 + page)  * 24))">
    <loader *ngIf="loadingMore"></loader>
    <button
      *ngIf="!loadingMore && (totalItems+1 > ((1 + page)  * 24))"
      htm-button
      (click)="loadMore()"
      type="button"
      data-test-id="related-study-programmes-load-more-button"
      >
      <span>{{ 'button.load_more' | translate }}</span>
      <span> ({{ (totalItems - ((1 + page) * 24)) }})</span>
    </button>
  </div>
</ng-template>


