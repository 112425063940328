<ng-container [ngTemplateOutlet]="bg() ? bgIcon : regularIcon"></ng-container>

<ng-template #regularIcon>
	<mat-icon
		class="icon"
		[attr.aria-label]="ariaLabel()"
		[svgIcon]="glyph()"
		[attr.aria-hidden]="ariaHidden"
		[class.icon--no-fill]="noFill()"
	>
	</mat-icon>
</ng-template>

<ng-template #bgIcon>
	<span class="icon__background">
		<mat-icon
			class="icon"
			[attr.aria-label]="ariaLabel()"
			[svgIcon]="glyph()"
			[attr.aria-hidden]="ariaHidden"
      [class.icon--no-fill]="noFill()"
		>
		</mat-icon>
	</span>
</ng-template>
