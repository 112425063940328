<ul *ngIf="!prefetching && !data?.conditionalRender">
	<li>
		<button
			[disabled]="data.invalid || downloading"
			(click)="downloadBlob(true)"
			htm-button
			class="text-center"
			data-test-id="sidebar-file-download-button"
		>
			{{'downloadFile' | translate}}
		</button>
	</li>
</ul>

<block theme="sidebar" *ngIf="!prefetching && data?.conditionalRender">
	<block-title *ngIf="!prefetching" ngClass="type-downloadFile">
		<h2>{{ 'downloadFile' | translate }}</h2>
	</block-title>
	<block-content>
		<ul *ngIf="!prefetching">
			<li>
				<button
					[disabled]="data.invalid || downloading"
					(click)="saveFile()"
					htm-button
					class="text-center"
					data-test-id="sidebar-file-download-button"
				>
					{{'downloadFile' | translate}}
				</button>
			</li>
		</ul>
	</block-content>
</block>
