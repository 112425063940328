<div
  *ngIf="parsedData.organizer || parsedData.title"
   attr.aria-label="{{'event.organizer' | translate}} {{ parsedData.organizer || parsedData.title }}"
>
{{ parsedData.organizer || parsedData.title }}
</div>
<div
  *ngIf="(parsedData.person || parsedData.fieldRegistrationCode) && type !== 'studyProgramme'"
   attr.aria-label="{{'event.person' | translate}} {{ parsedData.person || parsedData.fieldRegistrationCode }}"
>
{{ parsedData.person || parsedData.fieldRegistrationCode }}
</div>
<a
  *ngIf="parsedData.fieldSchoolWebsite && type === 'studyProgramme'"
   href="{{ parsedData.fieldSchoolWebsite }}"
   target="_blank"
   attr.aria-label="{{'event.contact' | translate}} {{ parsedData.fieldSchoolWebsite }}"
   [attr.data-test-id]="'sidebar-contact-link-' + (parsedData?.fieldSchoolWebsite | slugify)"
   >{{ parsedData.fieldSchoolWebsite }}</a
>
<a
  *ngIf="parsedData.phone"
   href="tel:{{ parsedData.phone }}"
   attr.aria-label="{{'event.phone' | translate}} {{ parsedData.phone }}"
   [attr.data-test-id]="'sidebar-contact-link-' + (parsedData?.phone)"
   >{{ parsedData.phone }}</a
>
<a
  *ngIf="parsedData.email"
   href="mailto:{{ parsedData.email }}"
   attr.aria-label="{{'event.email' | translate}} {{ parsedData.email }}"
   [attr.data-test-id]="'sidebar-contact-link-' + (parsedData?.email)"
   >{{ parsedData.email }}</a
>
<a
  *ngIf="parsedData.webpage"
   href="{{ parsedData.webpage }}"
   attr.aria-label="{{'event.contact' | translate}} {{ parsedData.webpage }}"
   [attr.data-test-id]="'sidebar-contact-link-' + (parsedData?.webpage | slugify)"
   >{{ parsedData.webpage | removeProtocol }}</a
>
