<ul>
	<li *ngFor="let item of data">
		<a
			[href]="item.url?.path || item.url || item.path || item.uri"
			target="_blank"
			*ngIf="type === 'external'"
			[attr.data-test-id]="'links-block-link-' + (item?.url?.path || item?.url || item?.path || item?.uri | slugify)"
		>
			<span class="sr-only">{{'link.external' | translate}}</span>
			<icon [ariaHidden]="true" glyph="external-link"></icon>
			{{ item.title }}</a
		>
		<a
			[href]="item.entity?.url || item.url || item.path || item.uri"
			target="_blank"
			*ngIf="type === 'document'"
			[attr.data-test-id]="'links-block-link-' + (item?.entity?.url || item?.url || item?.path || item?.uri | slugify)"
		>
			<span class="sr-only">{{'link.document' | translate}}</span>
			<icon [ariaHidden]="true" glyph="file"></icon>
			{{ item.description || item.entity?.entityLabel || item.filename }}</a
		>
	</li>
</ul>
