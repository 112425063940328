import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	signal,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { AssetsModule } from '@app/_assets';
import { isDateInPast } from '@app/_core/utility';
import { TranslateModule } from '@app/_modules/translate';
import { TranslateService } from '@app/_modules/translate/translate.service';
import {
	DigiSignGroupType,
	DigiSignItem,
	DigiSignService,
} from '@app/_services/digi-sign.service';
import { ButtonComponent } from '@app/components/button/button.component';
import { IconComponent } from '@app/components/icon/icon.component';
import {
	ExternalQualifications,
	Studies,
} from '@app/components/studies-list/studies-list.model';
import {
	TeachingQualification,
	TeachingQualificationEducation,
	TeachingWork,
} from '@app/modules/my-data/my-data-teaching-detail-view/my-data-teaching-detail-view.model';
import { DigiSignGroup } from './digi-sign-overlay.model';
import { LoaderComponent } from '@app/components/loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointService } from '@app/_services';

@Component({
	selector: 'digi-sign-overlay',
	templateUrl: './digi-sign-overlay.template.html',
	styleUrls: ['./digi-sign-overlay.styles.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		OverlayModule,
		ButtonComponent,
		TranslateModule,
		IconComponent,
		NgIf,
		NgFor,
		NgTemplateOutlet,
		AssetsModule,
		LoaderComponent,
		MatTooltipModule,
	],
})
export class DigiSignOverlayComponent {
	private overlay: OverlayRef;
	protected isOpen = false;
	protected isExpanded = signal(false);
	protected groups = computed<DigiSignGroup[]>(() =>
		this.createGroups(this.digiSignService.addedItems()),
	);
	protected downloading = signal(false);
	protected isMobileListCollapsed = signal(true);
	@ViewChild('overlayTemplate') overlayTemplate: TemplateRef<unknown>;
	constructor(
		private readonly overlayService: Overlay,
		private readonly _viewContainerRef: ViewContainerRef,
		protected digiSignService: DigiSignService,
		private readonly translate: TranslateService,
		protected readonly breakpointService: BreakpointService,
	) {
		// Toggle overlay when items length changes
		effect(() => {
			this.handleToggle(this.digiSignService.addedItems().length > 0);
		});
	}

	getPositionStrategy(expanded: boolean) {
		// Create overlay relative to content element
		const appContent =
			globalThis?.document?.querySelector<HTMLElement>('.app-content');
		// Get the browser scrollbar width to avoid overlap
		const scrollWidth =
			(appContent?.offsetWidth ?? 0) - (appContent?.clientWidth ?? 0);
		// Place overlay at the bottom right corner of screen
		return (
			this.overlayService
				.position()
				.flexibleConnectedTo(appContent)
				.withPositions([
					{
						originX: 'end',
						originY: expanded ? 'top' : 'bottom',
						overlayX: 'end',
						overlayY: expanded ? 'top' : 'bottom',
					},
				])
				// Offset by scrollbar width
				.withDefaultOffsetX(-scrollWidth)
		);
	}

	openOverlay() {
		this.overlay = this.overlayService.create({
			positionStrategy: this.getPositionStrategy(false),
			panelClass: 'digi-sign-overlay__panel',
		});
		const portal = new TemplatePortal(
			this.overlayTemplate,
			this._viewContainerRef,
		);
		this.overlay.attach(portal);
		this.isOpen = true;
	}

	handleToggle(hasItems: boolean) {
		if (!hasItems && this.isOpen) {
			// Get rid of overlay
			this.overlay?.dispose();
			this.isOpen = false;
		} else if (hasItems && !this.isOpen) {
			this.openOverlay();
		}
	}

	createGroups(items: DigiSignItem[]) {
		const groupIndices = {};
		let i = 0;
		return items.reduce<DigiSignGroup[]>((groups, item) => {
			const existingGroupIndex = groupIndices[item.digiSignGroup];

			const mapped = { id: item.id, name: this.getItemName(item) };
			if (existingGroupIndex !== undefined) {
				groups[existingGroupIndex].items.push(mapped);
			} else {
				groups.push({
					label: this.getGroupName(item.digiSignGroup),
					group: item.digiSignGroup,
					items: [mapped],
				});
				groupIndices[item.digiSignGroup] = i++;
			}
			return groups;
		}, []);
	}

	removeItem(item: DigiSignItem) {
		this.digiSignService.removeItem(item);
	}

	clearList() {
		this.digiSignService.clearItems();
		this.isExpanded.set(false);
		this.isMobileListCollapsed.set(true);
	}

	downloadItems() {
		this.downloading.set(true);
		this.digiSignService.downloadAddedItems(() => this.downloading.set(false));
	}

	getGroupName(groupType?: DigiSignGroupType) {
		return `digi_sign.${groupType}`;
	}

	getItemName(item: DigiSignItem) {
		const workType = item as TeachingWork;
		const qualType = item as TeachingQualification;
		const eduType = item as TeachingQualificationEducation;
		const extType = item as ExternalQualifications;
		const schType = item as Studies;

		let baseString = `${
			qualType.nimetus ||
			workType.oppeasutus ||
			extType.oppeasutuseNimi ||
			extType.oppeasutuseNimiTranslit ||
			extType.oppeasutuseNimiMuusKeeles
		}`;

		if (workType?.ametikohtLopp && isDateInPast(workType.ametikohtLopp)) {
			baseString += `, ${this.parseTypeTranslation('LOPETANUD')} ${
				workType.ametikohtLopp
			}`;
		}

		if (extType.valjaandmKp) {
			baseString +=
				', ' +
				this.parseTypeTranslation('LOPETANUD') +
				' ' +
				extType.valjaandmKp;
		}

		if (schType.staatus || schType.oppLopp) {
			baseString += schType.staatus
				? ', ' + this.parseTypeTranslation(schType.staatus)
				: '';
			baseString += schType.oppLopp ? ' ' + schType.oppLopp : '';
		}

		if (qualType?.aasta) {
			baseString += `, ${qualType.aasta}`;
		}

		if (eduType?.aastaLopetanud) {
			baseString += `, ${eduType.aastaLopetanud}`;
		}

		return baseString;
	}

	public parseTypeTranslation(type) {
		const translation = this.translate.get(`frontpage.${type}`).toString();
		if (translation.includes(`frontpage.${type}`)) {
			return type.toLocaleLowerCase();
		}
		return translation.toLocaleLowerCase();
	}

	protected toggleExpanded() {
		const newState = !this.isExpanded();
		if (this.overlay) {
			this.overlay.updatePositionStrategy(this.getPositionStrategy(newState));
			this.overlay.updatePosition();
		}
		this.isExpanded.set(newState);
	}
}
