<div class="notFound" *ngIf="!loading">
  <div class="flex">
    <div aria-hidden="true" class="error__head">{{ 'frontpage.message' | translate }}</div>
    <div *ngIf="!redirectUrl; else auth"
      aria-hidden="true"
      class="error__explanation"
      innerHtml="{{ 'notFound.explanation' | translate }}">
    </div>
    <ng-template #auth>
      <div class="error__explanation" aria-hidden="true">
        {{ 'frontpage.login_required_message' | translate }}
      </div>
    </ng-template>
    <div class="error__button">
      <button
        id="toFront"
        htm-button
        aria-live="assertive"
        theme="default"
        (click)="action()">
        <ng-container *ngIf="!redirectUrl; else authLabel">
          {{ 'frontpage.navigate' | translate }}
        </ng-container>
        <ng-template #authLabel>
          {{ 'button.login' | translate }}
        </ng-template>
      </button>
    </div>
    <div class="error__links">
      <a [routerLink]="'/kool' | translatePath"><span>{{'school.maps' | translate}}</span></a>
      <a [routerLink]="'/sündmused' | translatePath"><span>{{'event.label' | translate}}</span></a>
      <a [routerLink]="'/uudised' | translatePath"><span>{{'news.label' | translate}}</span></a>
    </div>
  </div>
</div>
