import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { IMenuItem, IMenuItems } from '@app/_assets/menu/sidemenu.model';
import { translatePath } from '@app/_core/router-utility';
import { BreakpointService } from '@app/_services/BreakpointService';
import { BehaviorSubject, Subject } from 'rxjs';
import { LanguageCodes, SettingsService } from './SettingsService';
import { slugifyString } from '@app/_core/utility';
@Injectable({
	providedIn: 'root',
})
export class SidemenuService {
	private innerData = new BehaviorSubject<IMenuItems>([]);
	// Open menu by default when front page is opened
	private subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		this.isFrontPage() && this.breakpointService.isDesktop()
	);
	private theme: BehaviorSubject<string> = new BehaviorSubject<string>(
		'default'
	);
	private langSwitch = new Subject<any>();

	public languageWasChanged =
		signal(
			false
		); /** it's only for the change of background-color after language change */
	public isLoading = signal(false);
	public initialAutoOpen = signal(false);
	public wasClosed = signal(false);

	/**
	 * These paths should not open the menu automatically on load.
	 */
	public ignoreAutoOpen = [
		translatePath('/õppimine'),
		translatePath('/karjäär'),
		translatePath('/õpetaja'),
		translatePath('/noored'),
	];
	public themes = {
		õppimine: 'learning',
		learning: 'learning',
		õpetamine: 'teaching',
		teaching: 'teaching',
		karjäär: 'career',
		career: 'career',
		noored: 'youth',
		youth: 'youth',
		noortevaldkond: 'youth',
		noorteseire: 'youth-monitoring',
		"youth-monitoring": 'youth-monitoring',
	};

	constructor(
		private location: Location,
		private breakpointService: BreakpointService,
		private http: HttpClient,
		private settings: SettingsService
	) {}

	force = false;
	lang: any;

	get isVisible() {
		return this.subject.getValue();
	}

	get isVisibleSubscription() {
		return this.subject;
	}

	get currentTheme(): string {
		return this.theme.getValue();
	}

	get themeSubscription() {
		return this.theme;
	}

	get isMobileView() {
		return window.innerWidth <= 1024;
	}

	get $data() {
		return this.innerData;
	}

	toggle() {
		this.subject.next(!this.subject.getValue());
	}

	close() {
		this.subject.next(false);
	}

	setTheme(theme: string): void {
		this.theme.next(theme);
	}

	triggerLang(force: boolean = false) {
		// force language switch on login to load main nav
		this.force = force;

		this.langSwitch.next({ any: Math.random() * 1000000 });
	}

	resetPageFocus(): void {
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
		document.body.setAttribute('tabindex', '-1');
		document.body.focus();
		document.body.removeAttribute('tabindex');
	}

	isFrontPage(): boolean {
		const path = decodeURI(this.location?.path());
		return !path;
	}

	/**
	 * @param item Sidemenu item
	 * @param path current path string
	 * @returns boolean for whether item matches the path
	 */
	private getMatch(item: IMenuItem, path: string): boolean {
		const decoded = decodeURI(item.relative);
		const pathSplit = path.split('/');
		const compareTo = pathSplit[0]
			? `/${pathSplit[0]}/${pathSplit[1]}`
			: `/${pathSplit[1]}`;
		return (
			(pathSplit.length > 2 && decoded === compareTo) ||
			path.replace(/\?.*/, '') === decoded
		);
	}

	/**
	 * This function is used to check whether menus have active items according to their URL every time
	 * the route changes or page reloads.
	 * @param items List of menu items, starts from the items from menus in `menus`
	 * @param path Browser URL
	 */
	private hasActiveInTree(items: IMenuItem[], path: string): boolean {
		let hasExpanded = false;
		for (const item of items) {
			const match = this.getMatch(item, path);
			if (item.below?.length) {
				const isChildActive = this.hasActiveInTree(item.below, path);
				if (match || isChildActive) {
					hasExpanded = true;
				}
			} else if (match) {
				hasExpanded = true;
			}
		}

		return hasExpanded;
	}

	/**
	 * This function is used to map active properties on active items according to their URL every time
	 * the route changes or page reloads.
	 * @param items List of menu items, starts from the items from menus in `menus`
	 * @param path Browser URL
	 */
	private mapActiveInTree(
		items: IMenuItem[],
		path: string
	): IMenuItem[] | undefined {
		if (!items) {
			return items;
		}

		return items.map((item) => {
			const match = this.getMatch(item, path);
			const newItem = { ...item };
			if (item.below?.length) {
				const isChildActive = this.hasActiveInTree(item.below, path);
				if (match || isChildActive) {
					newItem.expanded = true;
					newItem.active = true;
				} else {
					newItem.expanded = false;
					newItem.active = false;
				}
				newItem.below = this.mapActiveInTree(newItem.below, path);
			} else if (match) {
				newItem.active = true;
			} else {
				newItem.active = false;
			}
			return newItem;
		});
	}

	/**
	 * @returns either the theme found via active first level menu item or 'default'
	 */
	private findSidemenuTheme(): string {
		let theme = 'default';
		// Determine the theme of the current page
		for (const item of this.innerData.value) {
			if (item.firstLevel && item.active) {
				const itemLabel = item?.title?.toLowerCase();
				const foundTheme = this.themes?.[itemLabel];
				if (foundTheme) {
					theme = foundTheme;
				}
			}
		}
		return theme;
	}

	private setSidemenuTheme(opened: boolean, path: string) {
		if (opened || this.languageWasChanged()) {
			this.setTheme(this.findSidemenuTheme());
			// Open the menu when: 1. not in mobile view 2. the menu
			// is not already visible and 3. the page is not in the list of pages not to open on
			if (
				!this.isMobileView &&
				!this.isVisible &&
				!this.wasClosed() &&
				this.ignoreAutoOpen.indexOf(path) === -1
			) {
				this.initialAutoOpen.set(true);
				this.toggle();
			}
		} else {
			this.setTheme('default');
		}
	}

	/**
	 * @param items Sidemenu items
	 * @param slug Slugified item title
	 * @returns boolean value for whether the current tree contains that item
	 */
	private hasSlugInTree(items: IMenuItem[], slug: string): boolean {
		for (const item of items) {
			if (slugifyString(item.title) === slug) {
				return true;
			}
			if (item.below?.length) {
				return this.hasSlugInTree(item.below, slug);
			}
		}

		return false;
	}

	/**
	 * 
	 * @param items Sidemenu items
	 * @param slug Slugified item title
	 * @returns result object containing set slugged title and mapped items
	 */
	private mapSlugPath(items: IMenuItems, slug: string) {
		const result = {sluggedTitle: undefined, items};
		if (!items) {
			return result;
		}

		result.items = items.map((item) => {
			const newItem = { ...item };
			const sluggedTitle = slugifyString(newItem.title);
			// Found target
			if (sluggedTitle === slug) {
				newItem.active = true;
				if (newItem.expanded !== undefined) {
					newItem.expanded = true;
				}
				// Make sure to save the data for identification
				newItem.sluggedTitle = sluggedTitle;
				result.sluggedTitle = sluggedTitle;
			}
			const childResult = this.mapSlugPath(newItem.below, slug);
			newItem.below = childResult.items;
			// Check recursive call just in case
			if (!result.sluggedTitle && childResult.sluggedTitle) {
				result.sluggedTitle = childResult.sluggedTitle;
			}

			if (newItem.below?.length && this.hasSlugInTree(newItem.below, slug)) {
				newItem.expanded = true;
			}
			return newItem;
		});

		return result;
	}

	/**
	 * This function sets actives again and changes theme if needed
	 * It also opens the menu automatically when the page is a content page
	 * and was opened directly (on initialization).
	 */
	public makeActive(): void {
		const path = decodeURI(this.location.path());
		let opened = false;
		const newData = this.mapActiveInTree(this.innerData.value, path);
		if (this.hasActiveInTree(newData, path)) {
			opened = true;
		}
		this.setData(newData);
		this.setSidemenuTheme(opened, path);
	}

	public getData(): void {
		this.isLoading.set(true);
		// Use this instead of settings.url to fix language changes, idk
		const lang = this.settings.currentAppLanguage;
		const langParam = lang === LanguageCodes.ESTONIAN ? '' : `/${lang}`;
		// force to not use disk cache
		this.http
			.get(`${this.settings.baseUrl}${langParam}/api/menu_items/main`, {
				headers: new HttpHeaders({ 'Cache-Control': 'no-cache' }),
				params: { _format: 'json' },
			})
			.subscribe({
				next: (response: IMenuItems) => {
					// Set all the first level menus as such
					response?.forEach((item: IMenuItem) => {
						item.firstLevel = true;
					});
					this.innerData.next(response);
					this.makeActive();
				},
				error: () => {
					this.isLoading.set(false);
				},
				complete: () => {
					this.isLoading.set(false);
				},
			});
	}

	public setData(items: IMenuItems): void {
		this.innerData.next(items);
	}

	public openItemBySlug(slug: string): void {
		const newData = this.mapSlugPath(this.innerData.value, slug);
		this.setData(newData.items);
		if (!this.subject.value) {
			this.subject.next(true);
		}

		// Wait for render to finish and then focus the slugged title
    globalThis?.window?.setTimeout(() => {
      const targetElement = globalThis?.document?.querySelector(`[data-slug-title=${newData.sluggedTitle}]`);
      if (targetElement) {
        (targetElement as HTMLElement)?.focus();
      }
    }, 0);
	}
}
