import { BehaviorSubject } from 'rxjs';
import { Injectable, RendererFactory2 } from '@angular/core';
import {
	DARK_MODE_STORAGE_KEY,
} from '../modules/dark-mode/dark-mode.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FeatureFlagService } from '@app/modules/feature-flag/feature-flag.service';
import { FeatureFlags } from '@app/modules/feature-flag/feature-flags.model';

@Injectable({
	providedIn: 'root',
})
export class DarkModeService {
	public darkModeAllowed = this.featureFlagService.getFlagValue(FeatureFlags.DARK_MODE);
	public darkModeEnabled = new BehaviorSubject(this.getSavedState());
	constructor(
		private rendererFactory: RendererFactory2,
		private breakpointObserver: BreakpointObserver,
		private featureFlagService: FeatureFlagService
	) {
		const renderer = this.rendererFactory.createRenderer(null, null);
		this.darkModeEnabled.subscribe((state) => {
			if (globalThis?.document?.documentElement) {
				// Add class to root html element if dark mode is enabled
				if (state) {
					renderer.addClass(globalThis?.document?.documentElement, 'dark-mode');
				} else {
					renderer.removeClass(
						globalThis?.document?.documentElement,
						'dark-mode'
					);
				}
			}
		});
	}

	// Retrieve previously saved state from localstorage
	private getSavedState(): boolean {
    // Don't bother checking for anything else if dark mode is disabled in settings
		if (!this.darkModeAllowed) {
			return false;
		}
		let isEnabled = false;
		let savedState: string | null;
		if (globalThis?.localStorage) {
			savedState = globalThis?.localStorage?.getItem(
				DARK_MODE_STORAGE_KEY
			);
			isEnabled = savedState === 'true';
		}
		// Check null in case saved preference is just false.
		if (!isEnabled && savedState === null) {
			// Additional check for possible dark mode preference
			isEnabled = this.breakpointObserver.isMatched(
				'(prefers-color-scheme: dark)'
			);
		}
		return isEnabled;
	}

	public setState(state: boolean): void {
		// Should never be called in an SSR context but adding check anyway
		if (globalThis?.localStorage) {
			globalThis?.localStorage?.setItem(DARK_MODE_STORAGE_KEY, `${state}`);
			this.darkModeEnabled.next(state);
		}
	}

	public toggleState(): void {
		this.setState(!this.darkModeEnabled.value);
	}
}
