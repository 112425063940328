import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'arrayFirst',
})
export class ArrayFirstPipe implements PipeTransform {
	transform<T>(entry: T | T[]): T {
		if (!entry) return entry as T;
		if (Array.isArray(entry)) {
			return entry?.[0];
		}
		return entry;
	}
}
