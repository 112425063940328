<div id="grade-sheet" #gradeSheet>

  <section id="duplicate-info" *ngIf="document.content?.duplicateCertificateNumber">
    <p>{{'certificates.grade_sheet_cert_nr' | translate}} {{document.content?.duplicateCertificateNumber}}</p>
    <p>{{'certificates.grade_sheet_duplicate' | translate}} {{document.content?.duplicateIssueDate }}</p>
  </section>

  <section id="header">
    <h1 id="grade-sheet-title">{{ document.content?.documentName?.toUpperCase() }}</h1>
    <p id="for-certificate">{{ ('certificates.grade_sheet_for_certificate' | translate).replace('%number%', document.content?.registrationNumber) }}</p>
    <p>
      <ng-container *ngIf="document.content?.graduate?.idCode">
        {{'certificates.grade_sheet_id_code' | translate}}
      </ng-container>
      <ng-container *ngIf="!document.content?.graduate?.idCode && document.content?.graduate?.dateOfBirth">
        {{'certificates.grade_sheet_date_of_birth' | translate}}
      </ng-container>
      <span class="bold">
        {{ (document.content?.graduate?.idCode | idCode) || document.content?.graduate?.dateOfBirth || '' }}
      </span>
    </p>
  </section>

  <section id="info">
    <p class="info-badge" *ngIf="document.status === 'CERT_DOCUMENT_STATUS:INVALID'">{{'certificates.grade_sheet_invalid' | translate}}</p>
    <p class="bold">{{ document.content?.graduate?.firstName }} {{ document.content?.graduate?.lastName }}</p>
    <p [class.bold]="toimetulekuOpe">{{'certificates.grade_sheet_studied' | translate}} <span class="bold">{{ document.content?.studies?.curriculumNameToPrintOut }}.</span></p>
    <p>{{'certificates.grade_sheet_study_language' | translate}}: {{ document.content?.studies?.language }}</p>
  </section>

  <p class="bold">{{'certificates.grade_sheet_grades' | translate}}:</p>

  <section id="grades">
    <ng-container *ngIf="toimetulekuOpe; else gradesTable">
      <p class="subject-type">{{'certificates.grade_sheet_compulsory_subjects' | translate}}:</p>
      <div *ngFor="let subject of mandatorySubjects" class="subject-grade">
        <p class="bold">
          {{subject?.subject}}
        </p>
        <p>
          {{subject?.resultInWords}}
        </p>
      </div>

      <p class="subject-type" *ngIf="electives?.length > 0">{{'certificates.grade_sheet_elective_subjects' | translate}}:</p>
      <div *ngFor="let subject of electives" class="subject-grade">
        <p class="bold">
          {{subject?.subject}}
        </p>
        <p>
          {{subject?.resultInWords}}
        </p>
      </div>
    </ng-container>
    
    <ng-template #gradesTable>
      <scrollableContent>
        <table>
          <tbody>
            <tr class="highlight">
              <td>{{'certificates.grade_sheet_compulsory_subjects' | translate}}</td>
              <td *ngIf="isGeneralEducation">{{'certificates.grade_sheet_courses' | translate}}</td>
              <td *ngIf="!isGeneralEducation && hasGradedExaminations && hasResultantExaminations"></td>
              <td>{{'certificates.grade_sheet_grade' | translate}}</td>
            </tr>
            <tr *ngFor="let subject of mandatorySubjects; let last = last" [class.last-cell]="last && !document.content?.graduationStudy">
              <td>
                {{subject.subject}}
                <ng-container *ngIf="subject.subjectDetail">({{subject.subjectDetail}})</ng-container>
                <ng-container *ngIf="subject.studyProgrammeType === 'individuaalne õppekava'">*</ng-container>
              </td>
              <td *ngIf="isGeneralEducation">
                {{subject.amount}}
              </td>
              <td *ngIf="!isGeneralEducation && hasGradedExaminations && hasResultantExaminations"></td>
              <td>
                <span *ngIf="subject.resultNumeric != null" class="numeric-grade">{{subject.resultNumeric}}</span>{{subject.resultInWords}}
                <ng-container *ngIf="!subject.resultNumeric && !subject.resultInWords && subject.studyProgrammeType !== 'individuaalne õppekava'">
                  {{subject.studyProgrammeType}}
                </ng-container>
              </td>
            </tr>
  
            <tr *ngIf="document?.content?.graduationStudy" class="last-cell">
              <td [attr.colspan]="isGeneralEducation || (!isGeneralEducation && hasGradedExaminations && hasResultantExaminations) ? 2 : null">
                {{ document.content.graduationStudy.type }}: {{ document.content.graduationStudy.subject }}
              </td>
              <td>
                <span *ngIf="document.content.graduationStudy.resultNumeric != null" class="numeric-grade">
                  {{document.content.graduationStudy.resultNumeric}}
                </span>
                {{document.content.graduationStudy.resultInWords}}
              </td>
            </tr>
  
            <tr class="highlight" *ngIf="electives?.length > 0">
              <td>{{'certificates.grade_sheet_elective_subjects' | translate}}</td>
              <td *ngIf="isGeneralEducation">{{'certificates.grade_sheet_courses' | translate}}</td>
              <td *ngIf="!isGeneralEducation && hasGradedExaminations && hasResultantExaminations"></td>
              <td>{{'certificates.grade_sheet_grade' | translate}}</td>
            </tr>
            <tr *ngFor="let subject of electives; let last = last" [class.last-cell]="last">
              <td>
                {{subject.subject}}
                <ng-container *ngIf="subject.subjectDetail">({{subject.subjectDetail}})</ng-container>
                <ng-container *ngIf="subject.studyProgrammeType === 'individuaalne õppekava'">*</ng-container>
              </td>
              <td *ngIf="isGeneralEducation">
                {{subject.amount}}
              </td>
              <td *ngIf="!isGeneralEducation && hasGradedExaminations && hasResultantExaminations"></td>
              <td>
                <span *ngIf="subject.resultNumeric != null" class="numeric-grade">{{subject.resultNumeric}}</span>{{subject.resultInWords}}
                <ng-container *ngIf="!subject.resultNumeric && !subject.resultInWords && subject.studyProgrammeType !== 'individuaalne õppekava'">
                  {{subject.studyProgrammeType}}
                </ng-container>
              </td>
            </tr>
            @if (document?.content?.graduationExaminations?.length) {
              <tr class="highlight">
                <td [attr.colspan]="resultColspan">{{'certificates.grade_sheet_final_exams' | translate}}</td>
                <td *ngIf="hasResultantExaminations || !hasGradedExaminations">{{'certificates.grade_sheet_result' | translate}}</td>
                <td *ngIf="hasGradedExaminations">{{'certificates.grade_sheet_grade' | translate}}</td>
              </tr>
              <tr *ngFor="let subject of document?.content?.graduationExaminations; let last = last" [class.last-cell]="last">
                <td [attr.colspan]="resultColspan">
                  {{subject.subject}}
                  <ng-container *ngIf="subject.type === 'koolieksam'">*</ng-container>
                </td>
                <td *ngIf="hasResultantExaminations || !hasGradedExaminations">
                  <span *ngIf="subject.resultPercent">{{subject.resultPercent}}%</span>
                </td>
                <td *ngIf="hasGradedExaminations">
                  <span *ngIf="subject.resultNumeric != null" class="numeric-grade">{{subject.resultNumeric}}</span>{{subject.resultInWords}}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </scrollableContent>
    </ng-template>
  </section>

  <section class="additional-info" *ngIf="hasIndividualProgramme || hasSchoolExam">
    <p *ngIf="hasIndividualProgramme">{{'certificates.grade_sheet_individual_programme_explanation' | translate}}</p>
    <p *ngIf="hasSchoolExam">{{'certificates.grade_sheet_school_exam_explanation' | translate}}</p>
  </section>

  <section class="additional-info" *ngIf="document.content?.conductGrade?.resultInWords">
    <p>{{'certificates.grade_sheet_conduct' | translate}} {{ document.content?.conductGrade?.resultInWords }}</p>
  </section>

  <section class="additional-info">
    <p>{{'certificates.grade_sheet_printed_by' | translate}} <span class="bold">{{ document.content?.issuer?.nameToPrintOut }}</span></p>
    <p>{{ ('certificates.grade_sheet_printed_at' | translate).replace('%date', document.content?.councilDecision?.issueDateToPrintOut) }}</p>
  </section>

  <section class="additional-info" *ngIf="toimetulekuOpe">
    <p>{{'certificates.grade_sheet_valid_with_primary_school' | translate}}</p>
  </section>

  <section class="additional-info">
    <p>{{ document.content?.qualificationToPrintOut }}</p>
  </section>

  <section id="grade-sheet-number" *ngIf="document.number">
    <p>{{ document.number }}</p>
  </section>
</div>
