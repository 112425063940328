<ul *ngIf="data && data.length">
	<li *ngFor="let item of data">
		<a target="_blank" [href]="item.path"
			[attr.data-test-id]="'sidebar-events-link-' + (item?.path | slugify)">
			<p>
				{{item.fieldEventMainDate | date:"dd.MM.yyyy"}}
				<ng-container *ngIf="item.fieldEventStartTimeMain">
					kell {{item.fieldEventStartTimeMain | unixToTime}}</ng-container
				>
			</p>
			<span>{{item.title}}</span>
		</a>
	</li>
</ul>
<div class="bottom" *ngIf="data.length > 0">
	<a routerLink="{{ '/sündmused' | translatePath }}"
		data-test-id="sidebar-events-view-all-link"
		>{{ 'button.view_all' | translate }}</a
	>
</div>
