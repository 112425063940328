import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { expectedJwtAudience, expectedJwtIssuer, expectedJwtType } from '@app/_core/jwt.constant';
import { JWT } from '@app/_core/models/interfaces/jwt.model';
import { addLanguageCode, findUrlLanguageCode, getLangCode, translatePath } from '@app/_core/router-utility';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AlertsService } from './AlertsService';
import { CookieService } from './CookieService';
import { SettingsService } from './SettingsService';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root',
})

export class AuthService implements CanActivate {
	/**
	 * Determines whether if the user is authenticated
	 */
	public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	/**
	 * Determines whether the user has EHIS token
	 */
	public hasEhisToken: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public ehisTokenInvalid = new BehaviorSubject(false);
	public user: any = {};
	// Middleman login modal for header and whatever else
	public openLoginModal$ = new ReplaySubject<void>(undefined, 3000);
	public isLoggedInSignal = toSignal(this.isAuthenticated);
	constructor(
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private settings: SettingsService,
		private cookie: CookieService,
		private alertService: AlertsService,
		private translateService: TranslateService
	) {
		this.isAuthenticated.next(this.isLoggedIn());
	}

	/**
	 * Gets user data
	 */
	get userData() {
		return this.user;
	}

  /**
   * Sets user data
   * User data is decoded from JWT token
   */
  set userData(data) {
    /**
     * Skip replacing names if we're using new names from EHIS
     */
    if (this.user?.usingEhisNames) {
      const {firstname, lastname, ...restOfData} = data;
      Object.assign(this.user, restOfData);
    } else {
      Object.assign(this.user, data);
    }
  }

	/**
	 * Logins auth service
	 * @param data - Username, Password
	 * @returns http Observable
	 */
	public login(data: any) {
		return this.http
			.post(`${this.settings.url}/api/v1/token?_format=json`, data)
			.pipe(
				catchError(() => {
				return of({});
				}),
				map((response: any) => {
				if (response?.['token']) {
					sessionStorage.setItem('token', response['token']);
					this.userData = this.decodeToken(response.token);
					this.testNewJWT(response['token']);
				} else {
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('ehisToken');
					sessionStorage.removeItem('redirectUrl');
				}
				this.isAuthenticated.next(this.isLoggedIn());
				return response;
			}));
	}

	/**
	 * Tests new JSON web token
	 * @param token - jwt token
	 */
	public testNewJWT(token) {
		const data = {
			jwt: token,
		};
		this.http
			.post(`${this.settings.ehisUrl}/users/v1/haridusportaal/jwt`, data).subscribe({
			next: (response: any) => {
				if (response.jwt) {
					const isValidJwt = this.verifyJwt(this.decodeToken(response.jwt));
					if (isValidJwt) {
						sessionStorage.setItem('ehisToken', response.jwt);
						this.saveEhisTokenInCookie(response.jwt);
						this.hasEhisToken.next(true);
						this.isAuthenticated.next(true);
					} else {
						this.ehisTokenInvalid.next(true);
						this.alertInvalidJwt();
					}
				}
				this.useRedirectUrl(true);
			},
			error: (err) => {
				this.useRedirectUrl();
			},
		});
	}

	/**
	 * Gets ehis token
	 * Used to refresh users token
	 * @param token - JWT token
	 */
	public getEhisToken(token) {
		this.http
			.post(`${this.settings.ehisUrl}/users/v1/haridusportaal/jwt`, { jwt: token })
			.pipe(take(1))
			.subscribe((res: any) => {
				if (res.jwt) {
					const isValidJwt = this.verifyJwt(this.decodeToken(res.jwt));
					if (isValidJwt) {
						sessionStorage.setItem('ehisToken', res.jwt);
						this.saveEhisTokenInCookie(res.jwt);
						this.hasEhisToken.next(true);
					} else {
						this.ehisTokenInvalid.next(true);
						this.alertInvalidJwt();
					}
				}
			});
	}

	public getAnonToken() {
		this.http.get(`${this.settings.ehisUrl}/users/v1/anonymous/jwt`, {responseType: 'text'}).subscribe((res: string) => {
			sessionStorage.setItem('ehisToken', res);
		})
	}

	/**
	 * Sets ehis token in cookies
	 * @param token - JWT token
	 */
	public saveEhisTokenInCookie(token) {
		this.cookie.set('ehisToken', token, 0);
	}

	/**
	 * Deletes ehis token from cookies
	 */
	public deleteEhisTokenFromCookie() {
		this.cookie.remove('ehisToken');
	}

	/**
	 * Logs user out of the page and navigates the user to homepage
	 */
	public logout() {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('redirectUrl');
		sessionStorage.removeItem('ehisToken');
		this.deleteEhisTokenFromCookie();

		this.isAuthenticated.next(false);
		this.hasEhisToken.next(false);
		this.ehisTokenInvalid.next(false);
		this.router.navigateByUrl('/');
	}

	/**
	 * Determines whether the user is logged in
	 * @returns boolean
	 */
	public isLoggedIn() {
		if (!sessionStorage.getItem('token')) {
			if (this.isAuthenticated.getValue()) {
				this.isAuthenticated.next(false);
			}
			return false;
		}
		if (this.isTokenExpired()) {
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('ehisToken');
			sessionStorage.removeItem('redirectUrl');
			this.deleteEhisTokenFromCookie();

			if (this.isAuthenticated.getValue()) {
				this.isAuthenticated.next(false);
				this.hasEhisToken.next(false);
			}
			return false;
		}
		this.refreshUser();
		return true;
	}

	/**
	 * Refreshs user
	 * Updates users storage
	 * @param [newToken] - JWT token
	 */
	public refreshUser(newToken: any = false) {
		// TODO: refactor this somehow? currently gets called an insane amount of times
		if (newToken) {
			sessionStorage.setItem('token', newToken);
			this.getEhisToken(newToken);
		}
		const token = sessionStorage.getItem('token');
		if (sessionStorage.getItem('ehisToken')) {
			this.hasEhisToken.next(true);
		}
		this.userData = this.decodeToken(token);
	}

	/**
	 * Decodes token
	 * @param token - JWT token
	 * @returns - decoded token
	 */
	public decodeToken(token) {
		const payload = JSON.parse(
			decodeURIComponent(
				atob(token.split('.')[1]),
			),
		);
		return payload;
	}

	/**
	 * JWT expiration
	 * @returns Time when the token expires
	 */
	public expireTime() {
		const token = sessionStorage.getItem('token');
		const tokenPayload = token ? this.decodeToken(token) : {};
		if (tokenPayload.exp) {
			return tokenPayload.exp * 1000;
		}
		return false;
	}

	/**
	 * Auth guard
	 * @returns activate
	 */
	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		try {
			const url = state.url.startsWith('/') ? state.url.slice(1) : state.url;
			const expectedLangCode = findUrlLanguageCode(url);
			if (expectedLangCode && expectedLangCode !== getLangCode()) {
				const newUrl = addLanguageCode(url, expectedLangCode);
				this.router.navigateByUrl(newUrl);
				return false;
			}
		} catch (e) {
			console.error('Error during route parsing/repair', e);
		}
		if (!this.isLoggedIn()) {
			this.router.navigate(['/auth'], {
				queryParams: { redirect: decodeURIComponent(state.url) },
			});
		} else if (!sessionStorage.getItem('ehisToken')) {
			this.getEhisToken(sessionStorage.getItem('token'));
		}
		return true;
	}

	public useRedirectUrl(checkSessionStorage?: boolean) {
		let redirectUrl = this.route.snapshot.queryParamMap.get('redirect')
		if (checkSessionStorage && !redirectUrl) {
			redirectUrl = sessionStorage.getItem('redirectUrl');
		}
		this.router.navigateByUrl(redirectUrl || translatePath('/dashboard'), { replaceUrl: !!(redirectUrl) });
	}

	private isTokenExpired() {
		const token = sessionStorage.getItem('token');
		const tokenPayload = this.decodeToken(token);

		return Date.now() >= tokenPayload.exp * 1000;
	}

	public triggerLoginModal(redirectUrl?: string) {
		// Set the path to be redirected to after login
		if (redirectUrl) {
			globalThis?.sessionStorage?.setItem('redirectUrl', redirectUrl);
		}
		this.openLoginModal$.next();
	}

	private verifyJwt(jwt: JWT): boolean {
		const expectedValues = {
			"token-type": expectedJwtType,
			aud: expectedJwtAudience,
			iss: expectedJwtIssuer
		} as Partial<JWT>;
		// Return early if any of the keys are missing
		const hasRequiredKeys = Object.keys(expectedValues).reduce((acc, key) => {
			return acc && key in jwt;
		}, true);
		if (!hasRequiredKeys) {
			return false;
		}

		const hasExpectedValues = Object.entries(jwt ?? {})?.reduce((acc, [key, val])=> {
			// Compare keys that have an expected value
			if (key in expectedValues && expectedValues?.[key] !== val) {
				return false;
			}
			return acc;
		}, true);
		// Make sure the expiration date is valid
		const currentTime = new Date().getTime();
		const isValidExpiration = currentTime < (jwt.exp * 1000);
		return hasExpectedValues && isValidExpiration;
	}

	private alertInvalidJwt() {
		this.alertService.error(this.translateService.get('errors.ehis_auth_failed'), 'global', 'auth-errors', true);
	}
}
