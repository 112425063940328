<ul>
	<li>
		<button
			[disabled]="data?.invalid"
			(click)="modal.toggle('finalDocument-download')"
			htm-button
			class="text-center"
			data-test-id="sidebar-final-document-download-button"
		>
			{{'finalDocumentDownload' | translate}}
		</button>
	</li>
</ul>

<htm-modal
	id="finalDocument-download"
	[modalTitle]="'finalDocumentDownload' | translate"
	[bottomAction]="false"
>
	<ng-template>
		<loader *ngIf="loading"></loader>
		<alerts small id="download"></alerts>
		<ng-container *ngIf="!loading">
			<h1>{{'generic.download' | translate}}</h1>
			<p class="remark">Tärniga (*) tähistatud väljad on kohustuslikud.</p>
			<form [formGroup]="downloadForm">
				<div
					class="group"
					*ngIf="hasAccessToAccompanyingDocuments && data?.generalEducationDocumentType"
				>
					<p>{{ 'certificates.download_choose_content' | translate }}:</p>
					<formItem
						type="radio"
						formControlName="scope"
						[options]="downloadOptions.scope"
						name="scope"
						testId="sidebar-final-document-download-scope"
					></formItem>
				</div>
				<div
					class="group group__documents"
					*ngIf="data?.documents && documentsForm?.controls && !data.generalEducationDocumentType"
					[formGroup]="documentsForm"
				>
					<p>{{ 'certificates.download_choose_content' | translate }}:</p>
					<formItem
						*ngFor="let document of data?.documents; let i = index"
						type="checkbox"
						[class.inCursive]="!document.isInMainLanguage"
						[formControlName]="document.id"
						[disabled]="documentsForm?.controls[document.id]?.disabled"
						label="{{ document?.metadata?.shortName || document?.typeName }}"
						[testId]="document.id"
					>
					</formItem>
				</div>
				<div class="group">
					<p>{{ 'certificates.download_choose_type' | translate }}:</p>
					<formItem
						type="radio"
						formControlName="fileFormat"
						[options]="downloadOptions.fileFormat"
						name="fileFormat"
						testId="sidebar-final-document-download-file-format"
					></formItem>
				</div>
				<div class="button-row">
					<button
						htm-button
						(click)="modal.toggle('finalDocument-download')"
						theme="link"
						data-test-id="sidebar-final-document-download-modal-toggle-button"
					>
						{{'generic.cancel' | translate}}
					</button>
					<button htm-button (click)="downloadTranscript()" data-test-id="sidebar-final-document-download-transcript-button">
						{{ 'button.download' | translate }}
					</button>
				</div>
			</form>
		</ng-container>
	</ng-template>
</htm-modal>
