import { Pipe, PipeTransform } from '@angular/core';
import { titleCase } from '@app/_core/utility';

@Pipe({
  name: 'titleCase',
})

export class TitleCasePipe implements PipeTransform {
  transform(input:string): string {
    return titleCase(input);
  }
}
