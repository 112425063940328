import { NgFor, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	HostListener,
	OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterLink } from '@angular/router';
import { translatePath } from '@app/_core/router-utility';
import { TranslateModule } from '@app/_modules/translate';
import { NotificationsService } from '@app/_services/notifications.service';
import { ButtonComponent } from '@app/components/button/button.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { map } from 'rxjs';
import { Notification, NotificationDateTime } from './notifications.model';
import { AppPipes } from '@app/_pipes';

@Component({
	selector: 'header-notifications',
	templateUrl: './header-notifications.template.html',
	styleUrls: ['./header-notifications.styles.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IconComponent, ButtonComponent, NgIf, TranslateModule, NgFor, AppPipes, RouterLink],
})
export class HeaderNotificationsComponent implements OnInit {
	protected contentOpen = false;
	protected unreadCount = 0;
	protected notifications = [] as Notification[];
	protected notificationsLength = 0;

	constructor(
		private elementRef: ElementRef,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private notificationsService: NotificationsService,
		private destroyRef: DestroyRef
	) {}

	ngOnInit(): void {
		this.notificationsService.notifications
			.pipe(
				map((notificationsResponse) =>
					notificationsResponse?.messages?.slice(0, 3)?.map((notification) => {
						notification.formattedSentAt = this.getNotificationDateTime(
							notification.sentAt
						);
						return notification;
					})
				),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((notifications) => {
				this.notifications = notifications;
				this.notificationsLength = notifications?.length;
				this.cdr.markForCheck();
			});

		this.notificationsService.unreadMessagesCount
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((count) => {
				this.unreadCount = count;
				this.cdr.markForCheck();
			});
	}

	@HostListener('document:click', ['$event'])
	handleDocumentClick(event: MouseEvent) {
		// Close menu if it's open and click target is not inside it
		if (
			this.contentOpen &&
			!this.elementRef?.nativeElement?.contains(event?.target)
		) {
			this.contentOpen = false;
		}
	}

	protected getNotificationDateTime(input: string): NotificationDateTime {
		const dateObject = new Date(input);
		const day = dateObject?.getDate()?.toString()?.padStart(2, '0');
		const month = dateObject?.getMonth()?.toString()?.padStart(2, '0');
		const date = `${day}.${month}`;
		const time = `${dateObject
			?.getHours()
			?.toString()
			?.padStart(2, '0')}.${dateObject
			?.getMinutes()
			?.toString()
			?.padStart(2, '0')}`;
		return { date, time };
	}

	protected routeToNotificationsPage(): void {
		this.router.navigateByUrl(translatePath('/töölaud/teavitused'));
		this.contentOpen = false;
	}
}
