<ng-container *ngIf="darkModeAllowed">
	<ng-container *ngIf="{value: darkModeEnabled | async} as state">
		<button
			class="dark-mode__toggle"
			[class.dark-mode__toggle--dark-mode-enabled]="state.value"
			(click)="handleToggle()"
		>
			<icon [glyph]="state.value ? 'sun' : 'moon'"></icon>
			<span
				>{{(state.value ? 'dark_mode.disable' : 'dark_mode.enable') |
				translate}}</span
			>
		</button>
	</ng-container>
</ng-container>
