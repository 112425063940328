import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RippleService, SidemenuService } from '@app/_services';
import { IMenuItem } from './sidemenu.model';

const noLinkUrls = ['#nolink', '#nocategory', '#category'];

@Component({
  selector: 'sidemenu-item',
  templateUrl: './sidemenu.item.template.html',
  styleUrls: ['./sidemenu.styles.scss'],
})
export class MenuItemComponent {
  activeTheme = this.sidemenuService.themeSubscription;
  @Input() public items: IMenuItem[];
  @Input() public type = 'item';
  @Input() public nestingLevel = 0;
  @Output() public hideToggle: EventEmitter<IMenuItem> = new EventEmitter<IMenuItem>();

  constructor(
    private ripple: RippleService,
    private router: Router,
    private sidemenuService: SidemenuService,
    private location: Location
    ) {}

  /**
   * This function will either navigate to an URL or expand/hide a menu.
   * Navigation takes precedence.
   * @param item `IMenuItem` object
   */
  public clickMenuItem(item: IMenuItem) {
    const path = decodeURI(this.location.path());
    const match = path.replace(/\?.*/, '') === decodeURI(item.relative);
    if (
      !match &&
      // Check if item url is missing or a hash variant that doesn't lead anywhere
      !noLinkUrls.some(url => !item?.relative || item?.relative?.includes(url))
      ) {
      this.router.navigateByUrl(item.relative);
      // If the page is not a homepage, close the menu on mobile view
      if (this.sidemenuService.isMobileView && !item.firstLevel) {
        this.sidemenuService.close();
      }
    } else {
      if (item.below?.length) {
        item.expanded = !item.expanded;
        if (!item.expanded) {
          item.userClosed = true;
        } else if (item.userClosed) {
          item.userClosed = false;
        }

        if (item.expanded) {
          this.closeOthers(item);
          this.hideToggle.emit(item);
        }
      }
    }
  }

  public closeOthers(item: IMenuItem): void {
    this.items.forEach(i => {
      if (i !== item) {
        i.expanded = false;
      }
    });
  }

  public animateRipple(event: any) {
    this.ripple.animate(event, 'dark');
  }
}
