import { Routes } from '@angular/router';

export const TrainingsRoutes: Routes = [
	{
		path: '',
		loadComponent: () =>
			import('./list/trainings-list.component').then(
				(c) => c.TrainingsListComponent,
			),
	},
	{
		path: ':id',
		loadComponent: () =>
			import('./detail/trainings-detail.component').then(
				(c) => c.TrainingsDetailComponent,
			),
	},
];
