import { Component, inject, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { isLanguageCode, translateRoutes } from '@app/_core/router-utility';
import { routeRepair } from './_core/route-repair.matcher';
import { AuthService } from './_services';
import { curriculaRoutes } from './components/curricula/curricula.routes';
import { MyDataRoutes } from './modules/my-data/my-data.routes';
import { TrainingsRoutes } from './modules/trainings/trainings.routes';
import { FeatureFlags } from './modules/feature-flag/feature-flags.model';
import { FeatureFlagService } from './modules/feature-flag/feature-flag.service';

@Component({
    selector: 'dummy-view',
    template: '',
    standalone: false
})
export class DummyViewComponent {}

const routes: Routes = [
	{
		path: '404',
		loadChildren: () =>
			import('./_views/notFoundView').then((m) => m.NotFoundViewModule),
	},
	{
		path: '',
		loadChildren: () =>
			import('./_views/homePageView').then((m) => m.HomePageViewModule),
	},
	{
		path: 'tunnistused/lõpudokumendid',
		redirectTo: 'minu-andmed/lõpudokumendid',
	},
	{
		path: 'tunnistused/lõpudokumendid/:certificateNr/:accessorCode',
		redirectTo: 'minu-andmed/lõpudokumendid/:certificateNr/:accessorCode',
	},
	{
		path: 'tunnistused/lõpudokumendid/:id',
		redirectTo: 'minu-andmed/lõpudokumendid/:id',
	},
	{
		path: 'töölaud',
		loadChildren: () =>
			import('./_views/dashboardView').then((m) => m.DashboardViewModule),
		canActivate: [AuthService],
		data: {
			type: 'dashboard',
		},
		canMatch: [
			() => {
				return inject(FeatureFlagService).isFlagDisabled(
					FeatureFlags.DASHBOARD_DISABLED,
				);
			},
		],
	},
	{
		path: 'uudised',
		loadChildren: () =>
			import('./_views/newsListView').then((m) => m.NewsListViewModule),
	},
	{
		path: 'uudised/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'news',
		},
	},
	{
		path: 'otsing',
		loadChildren: () =>
			import('./_views/homeSearchListView').then(
				(m) => m.HomeSearchListViewModule,
			),
	},
	{
		path: 'sündmused',
		loadChildren: () =>
			import('./modules/events').then((m) => m.EventsViewModule),
	},
	{
		path: 'sündmused/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'event',
		},
	},
	{
		path: 'artiklid/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'article',
		},
	},
	{
		path: 'kool',
		loadChildren: () =>
			import('./modules/schoolListView/schoolListView.module').then(
				(m) => m.SchoolListViewModule,
			),
	},
	{
		path: 'kool/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'school',
		},
	},
	{
		path: 'koolide-rahastus',
		loadChildren: () =>
			import('./_views/schoolFunding').then((m) => m.SchoolFundingViewModule),
	},
	{
		path: 'ametialad',
		loadChildren: () =>
			import('./modules/mainProfession/mainProfessionListView.module').then(
				(m) => m.MainProfessionListViewModule,
			),
	},
	{
		path: 'ametialad/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'profession',
		},
	},
	{
		path: 'valdkonnad',
		loadChildren: () =>
			import('./_views/oskaFieldListView').then(
				(m) => m.OskaFieldListViewModule,
			),
	},
	{
		path: 'valdkonnad/andmed',
		loadChildren: () =>
			import('./_views/oskaFieldDataView').then((m) => m.OskaFieldDataView),
	},
	{
		path: 'valdkonnad/kaart',
		loadChildren: () =>
			import('./_views/oskaFieldMap').then((m) => m.OskaFieldMapModule),
	},
	{
		path: 'valdkonnad/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'field',
		},
	},
	{
		path: 'oska-tulemused/ettepanekute-elluviimine',
		loadChildren: () =>
			import('./_views/oskaResultsView').then((m) => m.OskaResultsViewModule),
	},
	{
		path: 'oska-tulemused/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'resultPage',
		},
	},
	{
		path: 'tööjõuprognoos/:id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'surveyPage',
		},
	},
	{
		path: 'infosüsteemid',
		loadChildren: () =>
			import('./_views/infoSystemView').then((m) => m.InfoSystemViewModule),
		data: {
			type: 'infosystem',
		},
	},
	{
		path: 'tunnistuse-kehtivuse-kontroll',
		loadChildren: () =>
			import(
				'./modules/certificates/containers/certificates-check-detail/certificates-check-detail.module'
			).then((m) => m.CertificatesCheckDetailModule),
	},
	{
		path: 'lõpudokumentide-kehtivuse-kontroll',
		loadChildren: () =>
			import(
				'./modules/certificates/containers/certificates-document-check-detail/certificates-document-check-detail.module'
			).then((m) => m.CertificateDocumentCheckDetailModule),
	},
	{
		path: 'töölaud/taotlused/:id',
		loadChildren: () => import('./_views/xjson').then((m) => m.XjsonModule),
		canActivate: [AuthService],
		canMatch: [
			() => {
				return inject(FeatureFlagService).isFlagDisabled(
					FeatureFlags.DASHBOARD_DISABLED,
				);
			},
		],
	},
	{
		path: 'taotlused/:id',
		loadChildren: () => import('./_views/xjson').then((m) => m.XjsonModule),
	},
	{
		path: 'teavitused',
		loadChildren: () =>
			import('./_views/messagesView').then((m) => m.MessagesViewModule),
		canActivate: [AuthService],
	},
	{
		path: 'töölaud/gdpr',
		loadChildren: () =>
			import('./_views/gdprView').then((m) => m.GdprViewModule),
		canActivate: [AuthService],
		canMatch: [
			() => {
				return inject(FeatureFlagService).isFlagDisabled(
					FeatureFlags.DASHBOARD_DISABLED,
				);
			},
		],
	},
	{
		path: 'töölaud/digitempel',
		loadChildren: () =>
			import('./_views/digitalSignView').then((m) => m.DigitalSignViewModule),
		canActivate: [AuthService],
		canMatch: [
			() => {
				return inject(FeatureFlagService).isFlagDisabled(
					FeatureFlags.DASHBOARD_DISABLED,
				);
			},
		],
	},
	{
		path: 'töölaud/tunnistused/lõputunnistused/:id',
		canActivate: [AuthService],
		loadChildren: () =>
			import(
				'./modules/certificates/containers/final-document-dashboard-detail/final-documents-dashboard-detail.module'
			).then((m) => m.FinalDocumentsDashboardDetailModule),
		canMatch: [
			() => {
				return inject(FeatureFlagService).isFlagDisabled(
					FeatureFlags.DASHBOARD_DISABLED,
				);
			},
		],
	},
	{
		path: 'uuringud',
		loadChildren: () =>
			import('./components/study-list/study-list.routes').then(
				(m) => m.StudyRoutes,
			),
	},
	{
		path: 'tasuta-kursused',
		loadChildren: () =>
			import('./components/adult-courses/adult-courses.routes').then(
				(m) => m.adultCoursesRoutes,
			),
	},
	{
		path: 'erialad',
		children: curriculaRoutes,
	},
	{
		path: 'dummy',
		component: DummyViewComponent,
	},
	{
		path: 'minu-andmed',
		children: MyDataRoutes,
	},
	{
		path: 'noorteseire/:id',
		loadChildren: () =>
			import('./modules/youth-monitoring/youth-monitoring.module').then(
				(m) => m.YouthMonitoringModule,
			),
	},
	{
		path: 'koolitused',
		children: TrainingsRoutes,
	},
	{
		path: ':id',
		loadChildren: () =>
			import('./_views/detailView').then((m) => m.DetailViewModule),
		data: {
			type: 'article',
		},
	},
	{
		path: '**',
		loadChildren: () =>
			import('./_views/notFoundView').then((m) => m.NotFoundViewModule),
		canMatch: [routeRepair],
	},
];

@NgModule({
	declarations: [DummyViewComponent],
	imports: [
		RouterModule.forRoot([
			{
				matcher: (url: UrlSegment[]) => {
					return isLanguageCode(url[0]?.path)
						? { consumed: url.slice(0, 1) }
						: { consumed: [] };
				},
				children: translateRoutes(routes, [
					'404',
					'search',
					'preview',
					'dummy',
					'oska',
				]),
			},
		]),
	],
	exports: [RouterModule],
})
export class RoutesModule {}
