<ul *ngIf="{value: activeTheme | async}; let activeTheme">
  <li 
    *ngFor="let item of items" 
    class="sidemenu__{{ type }}"
    [ngClass]="[
      item?.firstLevel && item?.active ? 'sidemenu-theme--' + activeTheme?.value : '',
      item?.active ? 'active' : '',
      item?.expanded ? 'expanded' : ''
    ]">
      <a (click)="clickMenuItem(item); false"
      class="sidemenu__button sidemenu__button__{{ type }}"
      href="{{ item.relative }}"
      [attr.aria-expanded]="item?.below?.length ? item.expanded : null"
      [attr.aria-controls]="item?.below?.length ? (item.title | slugify) : null"
      [class.with-submenu]="item?.below?.length"
      [attr.aria-haspopup]="item?.below?.length !== 0"
      [attr.aria-current]="item.active"
      [class.active]="item.active"
      (mousedown)="animateRipple($event)"
      [attr.data-test-id]="'sidemenu-link-' + (item?.title?.toLowerCase() | slugify)"
      [class.sidemenu__item--third-level]="nestingLevel >= 2"
      [attr.data-slug-title]="item?.sluggedTitle"
      *ngIf="item.relative !== '/#nolink' && item.relative !== '/#nocategory' && item.relative !== '/#category'">
        <span class="sidemenu__item-title" [class.sidemenu__item-title--nested]="nestingLevel > 0">
          {{item.title}}
          <icon *ngIf="item?.below?.length" class="chevron expand small" glyph="chevron-right" size="small"></icon>
        </span>
      </a>
    <button 
      (click)="clickMenuItem(item); false"
      class="sidemenu__button sidemenu__button__{{ type }}"
      [attr.aria-expanded]="item?.below?.length ? item.expanded : null"
      [attr.aria-controls]="item?.below?.length ? (item.title | slugify) : null"
      [class.with-submenu]="item?.below?.length"
      [attr.aria-haspopup]="item?.below?.length !== 0"
      [attr.aria-current]="item.active"
      [class.active]="item.active"
      (mousedown)="animateRipple($event)"
      *ngIf="item.relative === '/#nolink' || item.relative === '/#nocategory' || item.relative === '/#category'"
      [attr.data-test-id]="'sidemenu-link-' + (item?.title?.toLowerCase() | slugify)"
      [attr.data-slug-title]="item?.sluggedTitle"
      [class.sidemenu__button--third-level]="nestingLevel === 1 && item?.below?.length"
      >
      <span class="sidemenu__item-title" [class.sidemenu__item-title--nested]="nestingLevel > 0">
        {{item.title}}
        <icon class="chevron small" glyph="chevron-right" size="small"></icon>
      </span>
    </button>

    <ng-container *ngIf="item?.below?.length && item.expanded">
      <sidemenu-item [items]="item.below" [nestingLevel]="nestingLevel + 1"></sidemenu-item>
    </ng-container>
  </li>
</ul>
