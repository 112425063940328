<ul *ngIf="parsedData && parsedData.length && !blocks">
	<ng-container
		*ngFor="let item of (showAll ? parsedData : parsedData | slice:0:5)"
	>
		<li *ngIf="item.url || item.path">
			<ng-container *ngIf="item.url && !item.url.url">
				<ng-container *ngIf="(item.url | arrayFirst) as routeUrl">
					<ng-container *ngIf="item?.routed || routeUrl.routed">
						<a
							class="sidebar-links__link--has-icon"
							[routerLink]="routeUrl?.path || item.url"
							[attr.target]="routeUrl?.target || '_self'"
							[queryParams]="routeUrl?.params || false"
							[attr.data-test-id]="'sidebar-links-link-' + (routeUrl?.path || item?.url | slugify)"
							class="sidebar-links__link--has-icon"
						>
							<icon
								size="medium"
								ariaLabel="lehe sisene link"
								glyph="{{ item.icon ? item.icon : 'link-2' }}"
							></icon>
							<span role="text">{{ item.title }}</span>
						</a>
					</ng-container>
					<ng-container *ngIf="!item?.routed && !routeUrl.routed">
						<a
							class="sidebar-links__link--has-icon"
							[href]="routeUrl?.path || item.url"
							[attr.target]="routeUrl?.target || '_self'"
							class="sidebar-links__link--has-icon"
							[attr.data-test-id]="'sidebar-links-link-' + (routeUrl?.path || item?.url | slugify)"
						>
							<icon
								size="medium"
								ariaLabel="lehe väline link"
								glyph="{{ item.icon ? item.icon : 'external-link' }}"
							></icon>
							<span role="text">{{ item.title }}</span>
						</a>
					</ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="item.url?.url">
				<ng-container *ngIf="item.url.url.routed">
					<a
						class="sidebar-links__link--has-icon"
						[routerLink]="item.url.url.path"
						[attr.target]="item.url?.target || '_self'"
						[queryParams]="item.url.url.params || false"
						[attr.data-test-id]="'sidebar-links-link-' + ( item?.url?.url?.path | slugify)"
						class="sidebar-links__link--has-icon"
					>
						<icon
							size="medium"
							glyph="{{ item.icon ? item.icon : 'link-2' }}"
						></icon>
						<span role="text">{{ item.title }}</span>
					</a>
				</ng-container>
				<ng-container *ngIf="!item.url.url.routed">
					<a
						class="sidebar-links__link--has-icon"
						[href]="item.url.url.path"
						[attr.target]="item.url?.target || '_self'"
						class="sidebar-links__link--has-icon"
						[attr.data-test-id]="'sidebar-links-link-' + ( item?.url?.url?.path | slugify)"
					>
						<icon
							size="medium"
							glyph="{{ item.icon ? item.icon : 'external-link' }}"
						></icon>
						<span role="text">{{ item.title }}</span>
					</a>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="item.path">
				<ng-container *ngIf="item.routed">
					<a
						class="sidebar-links__link--has-icon"
						[routerLink]="item.path"
						[attr.target]="item?.target || '_self'"
						[queryParams]="item.params || false"
						class="sidebar-links__link--has-icon"
						[attr.data-test-id]="'sidebar-links-link-' + ( item?.path | slugify)"
					>
						<icon
							size="medium"
							glyph="{{ item.icon ? item.icon : 'link-2' }}"
						></icon>
						<span role="text">{{ item.title }}</span>
					</a>
				</ng-container>
				<ng-container *ngIf="!item.routed">
					<a
						[href]="item.path"
						[attr.target]="item?.target || '_self'"
						[attr.data-test-id]="'sidebar-links-link-' + ( item?.path | slugify)"
						class="sidebar-links__link--has-icon"
					>
						<icon
							size="medium"
							glyph="{{ item.icon ? item.icon : 'external-link' }}"
						></icon>
						<span role="text">{{ item.title }}</span>
					</a>
				</ng-container>
			</ng-container>
		</li>

		<li *ngIf="item.uri && !item.path">
			<a
				[href]="item.uri"
				[attr.target]="item.target || '_self'"
				[attr.data-test-id]="'sidebar-links-link-' + ( item?.uri | slugify)"
				class="sidebar-links__link--has-icon"
			>
				<icon
					size="medium"
					glyph="{{ item.icon ? item.icon : 'external-link' }}"
				></icon>
				<span role="text">{{ item.title }}</span>
			</a>
		</li>

		<li *ngIf="!item.url && !item.uri && !item.title" class="text-instance">
			{{ item }}
		</li>

		<li *ngIf="!item.url && !item.uri && item.title">
			<a class="no-link">
				<span role="text">{{ item.title }}</span>
			</a>
		</li>
	</ng-container>
	<ng-container *ngIf="parsedData.length > 5">
		<div class="bottom">
			<button
				htm-button
				theme="plain"
				class="showMore"
				(click)="showAll = !showAll"
				data-test-id="sidebar-links-show-more-toggle-button"
				attr.aria-label="{{ ('button.show_' + (showAll ? 'less' : 'more')) | translate }}"
			>
				<ng-container *ngIf="showAll;else showMoreText">
					- {{ 'button.show_less' | translate }} ({{ parsedData.length - 5 }})
				</ng-container>
				<ng-template #showMoreText>
					+ {{ 'button.show_more' | translate }} ({{ parsedData.length - 5 }})
				</ng-template>
			</button>
		</div>
	</ng-container>
</ul>

<ng-container *ngIf="blocks">
	<ng-container *ngFor="let block of blocks">
		<block theme="sidebar">
			<block-title>
				<ng-container>{{ block.title }}</ng-container>
			</block-title>
			<block-content>
				<sidebar-links [data]="block.links"></sidebar-links>
			</block-content>
		</block>
	</ng-container>
</ng-container>
