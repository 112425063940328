<table htm-table>
  <tr>
    <td>{{ 'school.register_code_short' | translate }}</td>
    <td>{{ data.fieldRegistrationCode }}</td>
  </tr>

  <tr *ngIf="data.fieldOwnershipType?.length">
    <td>{{ 'school.ownership' | translate }}</td>
    <td>{{ data.fieldOwnershipType[0] | titlecase }}</td>
  </tr>
  <tr *ngIf="data.teachingLanguage?.length">
    <td>{{ 'school.language' | translate }}</td>
    <td>
      <div *ngFor="let language of data.teachingLanguage">
        {{ language | titleCase }}
      </div>
    </td>
  </tr>
  <tr *ngIf="data.specialClass">
    <td>{{ 'school.special_needs_long' | translate }}</td>
    <td>
      <span>{{ 'school.available' | translate | titlecase }}</span>
    </td>
  </tr>
  <tr *ngIf="data.studentHome">
    <td>{{ 'school.dormitory' | translate }}</td>
    <td>
      <span>{{ 'school.available' | translate | titlecase }}</span>
    </td>
  </tr>
</table>
