<ng-container *ngIf="data && data.length">
	<ng-container *ngFor="let location of data">
		<map
			*ngIf="markers && markers.length"
			type="markers"
			[options]="options"
			[markers]="markers"
			(mapLoaded)="mapLoaded()"
		></map>
		<ng-container *ngIf="!location.fieldEventLocation && location.fieldAddress">
			<h2>
				{{ location?.fieldBuildingName || ('school.legal_address' | translate)
				}}
			</h2>
			<p>{{ location?.fieldAddress }}</p>
		</ng-container>
		<ng-container *ngIf="location.fieldEventMainDate">
			<h2>{{ 'event.place_and_time' | translate }}</h2>
			<p *ngIf="location.fieldEventMainDate">
				<span>
					{{ 'time.'+ (location.fieldEventMainDate | weekday: "unix" |
					lowercase) | translate }} {{ location.fieldEventMainDate | date:
					"dd.MM yyy "}}

					<span
						*ngIf="location.fieldEventStartTimeMain && location.fieldEventEndTimeMain"
					>
						<span> {{ 'time.time_of_day' | translate }}</span>
						<ng-container *ngIf="location.fieldEventStartTimeMain">
							{{ location.fieldEventStartTimeMain | unixToTime }}
						</ng-container>
						<ng-container
							*ngIf="location.fieldEventStartTimeMain && location.fieldEventEndTimeMain"
						>
							-
						</ng-container>
						<ng-container *ngIf="location.fieldEventEndTimeMain">
							{{ location.fieldEventEndTimeMain | unixToTime }}
						</ng-container>
					</span>
				</span>
				<ng-container *ngIf="location && location.fieldEventDate">
					<div *ngFor="let item of location.fieldEventDate">
						<ng-container *ngIf="item.fieldEventAdditionalDate">
							{{ 'time.'+ (item.fieldEventAdditionalDate | weekday: "unix" |
							lowercase) | translate }} {{ item.fieldEventAdditionalDate | date:
							"dd.MM yyy "}}

							<span *ngIf="item.fieldEventStartTimeAddition || item.fieldEventEndTimeAddition">
								<span> {{ 'time.time_of_day' | translate }}</span>
								<ng-container *ngIf="item.fieldEventStartTimeAddition">
									{{ item.fieldEventStartTimeAddition | unixToTime }}
								</ng-container>
								<ng-container
									*ngIf="item.fieldEventStartTimeAddition && item.fieldEventEndTimeAddition"
								>
									-
								</ng-container>
								<ng-container *ngIf="item.fieldEventEndTimeAddition">
									{{ item.fieldEventEndTimeAddition | unixToTime }}
								</ng-container>
							</span>
						</ng-container>
					</div>
				</ng-container>
			</p>
			<p>
				<ng-container *ngIf="location.fieldEventLocation">
					{{ location.fieldEventLocation?.name }}<br />
				</ng-container>
				<a
					*ngIf="location.fieldEventLocationLink?.length"
					href="{{ location.fieldEventLocationLink?.[0]?.uri }}"
					target="_blank"
					>{{ location.fieldEventLocationLink?.[0]?.uri }}</a
				>
			</p>
		</ng-container>
	</ng-container>
</ng-container>
