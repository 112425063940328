import { translatePath } from '@app/_core/router-utility';
import { mergeObjectOfArrays, takeFirstValue } from '@app/_core/utility';

export const collection = {
  'nodeQuery': 'articles',
  'newsQuery': 'news',
  'fieldContact': 'contact',
  'fieldContactSection': 'contact',
  'fieldOskaFieldContact': 'contact',
  'educationalInstitution': 'contact',
  // 'fieldIscedfSearchLink': 'links',
  'fieldJobOpportunities': 'links',
  'fieldLearningOpportunities': 'links',
  'fieldJobs': 'links',
	'fieldRelatedProfession': 'links',
  'fieldOskaField': 'links',
  'fieldQualificationStandard': 'links',
  'fieldQuickFind': 'links',
  'fieldOskaFieldQuickFind': 'links',
  'fieldRelatedArticle': 'links',
  'fieldRelatedPages': 'links',
  'fieldHyperlinks': 'links',
  'prosCons': 'categories',
  'additional': 'data',
  'fieldRegistration': 'register',
  'fieldRegistration2': 'register',
  'fieldButton': 'actions',
  'fieldEhisLinks': 'links',
  'fieldSchoolLocation': 'location',
  'fieldEventLocation': 'location',
  'indicator': 'facts',
  'fillingBar': 'progress',
  'fieldLegislationBlock': 'links',
  'links': 'links',
  'event': 'events',
  'fieldBlocks': 'links',
  'notifications': 'notifications',
  'gdpr': 'gdpr',
  'finalDocumentAccess': 'finalDocumentAccess',
  'finalDocumentHistory': 'finalDocumentHistory',
  'finalDocumentDownload': 'finalDocumentDownload',
  'downloadFile': 'downloadFile',
}

export const uniformTypes = {
  'prosCons': ['fieldPros', 'fieldNeutral', 'fieldCons', 'fieldOskaFieldPros', 'fieldOskaFieldCons'],
  'fieldContact': ['email', 'person', 'phone', 'organizer', 'webpage'],
  'fieldEventLocation': [
    'fieldEventLocation',
    'fieldEventLocationLink',
    'fieldEventDate',
    'fieldEventMainDate',
    'fieldEventMainEndDate',
    'fieldEventStartTimeMain',
    'fieldEventEndTimeMain'
  ],
  'fieldRegistration': [
    'fieldEntryType',
    'fieldRegistrationDate',
    'EventRegistrations',
    'fieldRegistrationUrl',
  ]
}

export const titleLess = {
  'contact': false,
  'links': false,
  'prosCons': false,
  'data': true,
  'register': true,
  'actions': true,
  'facts': false,
  'progress': false,
  'articles': false,
  'location': true,
  'event': false,
};

const iscedfFields = {
  iscedf_detailed: 'fieldIscedfDetailed',
  iscedf_narrow: 'fieldIscedfNarrow',
  iscedf_broad: 'fieldIscedfBroad',
  level: 'fieldLevel'
}

const parseIscedfFields = (data: any) => {
  const innerData = takeFirstValue(data);
  const result: Record<string, string[]> = {};
  if (!innerData) return result;
  
  Object.entries(iscedfFields).forEach(([setter, getter]) => {
    result[setter] = innerData[getter] && Object.keys(innerData[getter]);
  });
  return result;
}

export const parseProfessionData = (inputData, translate) => {
  let mappedData = inputData;
  let iscedfResults: Record<string, string[]> = {
    iscedf_detailed: [],
    iscedf_narrow: [],
    iscedf_broad: [],
    level: [],
  };

  // õppimisvõimaluste filtrite kogumine AMETIALA puhul
  if (mappedData['fieldIscedfSearchLink']) {
    iscedfResults = mergeObjectOfArrays(
      iscedfResults,
      parseIscedfFields(mappedData['fieldIscedfSearchLink'])
    );
  }

  // õppimisvõimaluste filtrite kogumine AMETI puhul
  mappedData.fieldRelatedProfession?.forEach((profession) => {
    if (!profession.fieldSidebar?.length) return;
    iscedfResults = mergeObjectOfArrays(
      iscedfResults,
      parseIscedfFields(profession.fieldSidebar?.[0]?.fieldIscedfSearchLink)
    );
  });

  const searchParams: Record<string, string> = {};
  Object.keys(iscedfResults).forEach(key => {
    if (!Array.isArray(iscedfResults[key]) || !iscedfResults[key].length) return;
    searchParams[key] = iscedfResults[key].join(';');
  });

  if (Object.keys(searchParams).length || mappedData.fieldProfession) {
    mappedData['fieldLearningOpportunities'] = [
      {
        title: translate.get('professions.go_to_subjects'),
        url: {
          path: `/erialad`,
          params: searchParams,
          routed: true,
        },
      },
    ];
  }

  try {
    mappedData['fieldOskaField'] = mappedData['fieldOskaField'].map((item) => {
      return {
        title: item.entity.entityLabel,
        url: item.entity.entityUrl,
      };
    });
  } catch (err) { }

  try {
    mappedData['fieldJobs'] = mappedData?.['fieldJobs']?.map((item) => {
        return {
          title: item?.fieldJobName,
          url: item?.fieldJobLink?.[0]?.path || '',
          routed: item?.fieldJobLink?.[0]?.routed
        };
      });
  } catch (err) {}

  try {
    if (!mappedData['fieldQuickFind']) {
      mappedData['fieldQuickFind'] = [];
    }
    let additionalData = [
      {
        title: translate.get('oskaProfessions.data'),
        url: {
          path: '/ametialad/andmed',
          routed: false,
          target: '_blank',
        },
      },
      {
        title: translate.get('oska.all_professions_and_jobs'),
        url: {
          path: '/ametialad',
          routed: false,
          target: '_blank',
        },
      },
    ];

    if (mappedData['fieldProfession']) additionalData = additionalData.slice(1);

    additionalData.forEach((item) => {
      let match = false;
      mappedData['fieldQuickFind'].forEach((link) => {
        if (link.title === item.title) {
          match = true;
        }
      });
      if (!match) {
        mappedData['fieldQuickFind'].unshift(item);
      }
    });
  } catch (err) { }

  try {
    if (!mappedData['indicator'].entities.length) {
      delete mappedData['indicator'];
    }
  } catch (err) { }

  try {
    if (!mappedData['fillingBar'].entities.length) {
      delete mappedData['fillingBar'];
    }
  } catch (err) { }

  mappedData = getIndicators(mappedData, translate);

  try {
    delete mappedData['links'];
  } catch (err) { }

  try {
    if (!mappedData['fieldContact']?.[0]?.['fieldEmail'] &&
      !mappedData['fieldContact']?.[0]?.['fieldPerson'] &&
      !mappedData['fieldContact']?.[0]?.['fieldPhone']) {
      delete mappedData['fieldContact'];
    }
  } catch (err) { }

  return mappedData;
}


const getFieldNumberEmployedIcon = (val) => {
  if (val < 10000) {
    return 1
  } else if (val >= 10000 && val < 15000) {
    return 2
  } else if (val >= 15000 && val < 20000) {
    return 3
  } else if (val >= 20000 && val < 25000) {
    return 4
  } else if (val >= 25000 && val < 30000) {
    return 5
  } else if (val >= 30000 && val < 35000) {
    return 6
  } else if (val >= 35000 && val < 45000) {
    return 7
  } else if (val >= 45000 && val < 55000) {
    return 8
  } else if (val >= 55000 && val < 65000) {
    return 9
  } else if (val >= 65000 && val < 75000) {
    return 10
  } else {
    return 11;
  }
}

const getIndicators = (mappedData, translate) => {
  const indicators = [];
  let hasFieldNumberEmployed = false;
  if (mappedData.fieldNumberEmployed) {
    indicators.push({
      oskaId: 1,
      value: mappedData.fieldNumberEmployed,
      oskaIndicator: translate.get('oska.fieldNumberEmployed'),
      icon: getFieldNumberEmployedIcon(mappedData.fieldNumberEmployed),
    })
    hasFieldNumberEmployed = true;
  }

  if (mappedData.fieldEmploymentChange && hasFieldNumberEmployed) {
    indicators.push({
      oskaId: 2,
      value: mappedData.fieldEmploymentChange,
      oskaIndicator: translate.get('oska.numberEmployedChange'),
      icon: mappedData.fieldEmploymentChange,
    });
  }

  if (indicators.length) {
    mappedData['indicator'] = indicators;
  }

  return mappedData;
}

export const parseFieldData = (inputData, translate) => {
  let mappedData = inputData;

  try {

    if (!mappedData['fieldQuickFind']) {
      mappedData['fieldQuickFind'] = [];
    }
    const additionalData = [
      {
        title: translate.get('oskaProfessions.fieldsCompare'),
        url: {
          path: translatePath('/valdkonnad/andmed'),
          routed: true,
        },
      },
      {
        title: translate.get('oskaProfessions.fields_all'),
        url: {
          path: translatePath('/valdkonnad'),
          routed: true,
        },
      },
    ];

    additionalData.forEach((item) => {
      let match = false;
      mappedData['fieldQuickFind'].forEach((link) => {
        if (link.title === item.title) {
          match = true;
        }
      });
      if (!match) {
        mappedData['fieldQuickFind'].unshift(item);
      }
    });
  } catch (err) { }

  if (mappedData.links) {
    mappedData['fieldQuickFind'] = [...mappedData['fieldQuickFind'], ...mappedData.links];
    delete mappedData.links;
  }

  if (mappedData['fieldOskaFieldQuickFind']) {
    mappedData['fieldQuickFind'] = [...mappedData['fieldQuickFind'], ...mappedData['fieldOskaFieldQuickFind']];
    delete mappedData['fieldOskaFieldQuickFind'];
  }

  mappedData = getIndicators(mappedData, translate);

  try {
    if (!mappedData['fieldOskaFieldContact']['entity']['fieldEmail'] &&
      !mappedData['fieldOskaFieldContact']['entity']['fieldPerson'] &&
      !mappedData['fieldOskaFieldContact']['entity']['fieldPhone']) {
      delete mappedData['fieldOskaFieldContact'];
    }
  } catch (err) { }

  return mappedData;
}
