<div
	[class.hidden]="searchEnabled"
	class="header__search"
	*ngIf="searchEnabled$ | async as searchEnabled"
>
	<form (submit)="$event?.preventDefault()">
		<formItem
			role="search"
			type="autocomplete"
			query="testAutocomplete"
			name="homeSearch"
			[title]="'frontpage.enter_keyword_and_search' | translate"
			testId="header-search-input"
			(autoCompleteChanged)="searchRoute($event)"
		>
		</formItem>
	</form>
	<button
		type="button"
		class="header__search-icon"
		[ariaLabel]="'button.search' | translate"
		(click)="searchRoute()"
		data-test-id="header-search-button"
	>
		<icon glyph="header-search"></icon>
	</button>
</div>
<button
	htm-button
	[class.active]="modalService.modalOpened['search'] || false"
	(click)="modalService.toggle('search')"
	theme="plain"
	attr.aria-label="{{ 'search.label' | translate}}"
	class="header__search-modal-toggle"
>
	<icon aria-hidden="true" glyph="header-search" size="medium"></icon>
</button>

<htm-modal
	id="search"
	modalTitle="{{ 'button.search' | translate}}"
	[bottomAction]="false"
>
	<ng-template id="search">
		<formItem
			class="header-search__modal-input"
			type="text"
			[(ngModel)]="searchTerm"
			[title]="'search.keyword' | translate"
			(keyup.enter)="searchRoute(); modalService.close('search')"
			testId="header-search-modal-input"
		></formItem>
		<button
			(blur)="modalService.focusLock()"
			htm-button
			style="margin: 1rem 0; width: 100%"
			(click)="searchRoute(); modalService.close('search')"
			data-test-id="header-search-modal-search-button"
		>
			{{ 'button.search' | translate}}
		</button>
	</ng-template>
</htm-modal>
