<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'article'">
    <div class="image"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line" style="width: 75%"></div>
  </ng-container>

  <ng-container *ngSwitchCase="'lines'">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line" style="width: 75%"></div>
  </ng-container>
  
  <ng-container *ngSwitchDefault>
    <div class="line"></div>
  </ng-container>
</ng-container>