export enum GraduationDocumentType {
  RESIDENCY_DIPLOMA_SUPPLEMENT = 'GRADUATION_DOCUMENT_TYPE:RESIDENCY_DIPLOMA_SUPPLEMENT',
  RESIDENCY_COMPLETION_CERTIFICATE = 'GRADUATION_DOCUMENT_TYPE:RESIDENCY_COMPLETION_CERTIFICATE',
  VOCATIONAL_PROFESSIONAL_EDUCATIONAL_STUDY_TRANSCRIPT_OF_GRADES = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_PROFESSIONAL_EDUCATIONAL_STUDY_TRANSCRIPT_OF_GRADES',
  VOCATIONAL_PROFESSIONAL_EDUCATIONAL_STUDY_CERTIFICATE = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_PROFESSIONAL_EDUCATIONAL_STUDY_CERTIFICATE',
  VOCATIONAL_TRAINING_TRANSCRIPT_OF_GRADES = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_TRAINING_TRANSCRIPT_OF_GRADES',
  VOCATIONAL_TRAINING_CERTIFICATE = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_TRAINING_CERTIFICATE',
  VOCATIONAL_TRAINING_CERTIFICAT = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_TRAINING_CERTIFICAT',
  VOCATIONAL_SECONDARY_EDUCATION_TRANSCRIPT_OF_GRADES = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_SECONDARY_EDUCATION_TRANSCRIPT_OF_GRADES',
  VOCATIONAL_SECONDARY_EDUCATION_CERTIFICATE = 'GRADUATION_DOCUMENT_TYPE:VOCATIONAL_SECONDARY_EDUCATION_CERTIFICATE',
  DOCTORAL_DEGREE_DIPLOMA_SUPPLEMENT =
    'GRADUATION_DOCUMENT_TYPE:DOCTORAL_DEGREE_DIPLOMA_SUPPLEMENT',
  DOCTORAL_DEGREE_DIPLOMA = 'GRADUATION_DOCUMENT_TYPE:DOCTORAL_DEGREE_DIPLOMA',
  MASTERS_DEGREE_DIPLOMA_SUPPLEMENT = 'GRADUATION_DOCUMENT_TYPE:MASTERS_DEGREE_DIPLOMA_SUPPLEMENT',
  MASTERS_DEGREE_DIPLOMA = 'GRADUATION_DOCUMENT_TYPE:MASTERS_DEGREE_DIPLOMA',
  PROFESSIONAL_HIGHER_EDUCATION_DIPLOMA_SUPPLEMENT = 'GRADUATION_DOCUMENT_TYPE:PROFESSIONAL_HIGHER_EDUCATION_DIPLOMA_SUPPLEMENT',
  PROFESSIONAL_HIGHER_EDUCATION_DIPLOMA = 'GRADUATION_DOCUMENT_TYPE:PROFESSIONAL_HIGHER_EDUCATION_DIPLOMA',
  BACHELORS_DEGREE_DIPLOMA_SUPPLEMENT = 'GRADUATION_DOCUMENT_TYPE:BACHELORS_DEGREE_DIPLOMA_SUPPLEMENT',
  BACHELORS_DEGREE_DIPLOMA = 'GRADUATION_DOCUMENT_TYPE:BACHELORS_DEGREE_DIPLOMA',
  GENERAL_EDUCATION_TRANSCRIPT_OF_GRADES = 'GRADUATION_DOCUMENT_TYPE:GENERAL_EDUCATION_TRANSCRIPT_OF_GRADES',
  GENERAL_EDUCATION_CERTIFICATE = 'GRADUATION_DOCUMENT_TYPE:GENERAL_EDUCATION_CERTIFICATE',
  BASIC_EDUCATION_TRANSCRIPT_OF_GRADES = 'GRADUATION_DOCUMENT_TYPE:BASIC_EDUCATION_TRANSCRIPT_OF_GRADES',
  BASIC_EDUCATION_CERTIFICATE = 'GRADUATION_DOCUMENT_TYPE:BASIC_EDUCATION_CERTIFICATE',
}
