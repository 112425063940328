<div class="map__container" #mapContainer>
	<div class="map__pan-controls">
		@for (btn of panControls; track $index) {
		<button
			class="map__pan-control"
			[attr.style]="'--pan-control-area: ' + btn"
			type="button"
			(click)="panMap(btn, $event)"
			[attr.aria-label]="('map.move_' + btn) | translate"
		>
			<icon [glyph]="'chevron-' + btn" />
		</button>
		}
	</div>
	<ng-content></ng-content>
</div>
