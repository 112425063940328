import { LanguageCodes } from '@app/_services';
import {
	ChangeDetectorRef,
	OnDestroy,
	Pipe,
	PipeTransform,
} from '@angular/core';
import { TranslateService } from './translate.service';
import { Subscription } from 'rxjs';

@Pipe({
	name: 'translate',
	pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
	value: string = '';
	lastKey: string | null = null;
	lastLangCode: LanguageCodes | null = null;
	langChangeSub?: Subscription;
	constructor(
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {}
  subscribeToLangChange(key: string, langCode?: LanguageCodes) {
    this.langChangeSub = this.translate.translationsUpdated$.subscribe(() => {
			this.value = this.translate.get(
				key,
				langCode
			);
      this.cdr.markForCheck();
		});
  }
	transform(key: any, langCode?: LanguageCodes): any {
    // Return the argument if it's not valid
		if (!key?.length) {
			return key;
		}

    // Return cached value if arguments don't change
		if (key === this.lastKey && langCode === this.lastLangCode) {
			return this.value;
		}
    
    // Remember arguments, get new value
		this.lastKey = key;
		this.lastLangCode = langCode;
		this.value = this.translate.get(key, langCode);
    // Watch for language changes, trigger change detection when needed
    this.subscribeToLangChange(key, langCode);
		return this.value;
	}

	ngOnDestroy(): void {
    // Cleanup
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
      this.langChangeSub = undefined;
    }
	}
}
