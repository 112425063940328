import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'max-width-wrapper',
  template: '<ng-content></ng-content>',
  styleUrls: ['./max-width-wrapper.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxWidthWrapperComponent {}
