<ul *ngIf="data && data.length">
	<li *ngFor="let item of data">
		<a *ngIf="icons" [href]="item.url.path || item.path"
			[attr.data-test-id]="'sidebar-actions-icon-link-' + (item?.url?.path || item?.path | slugify)">
			<div class="row">
				<div class="col-1 padding center-flex">
					<icon
						aria-hidden="true"
						size="medium"
						[glyph]="item.icon ? item.icon : 'file-text'"
					></icon>
				</div>
				<span class="col-11 padding text" role="text"
					>{{ item.title | translate }}</span
				>
			</div>
		</a>
		<button
			*ngIf="!icons && (item.url?.routed || item.routed) && !item.login"
			role="link"
			theme="{{ item.color && item.color === 'white' ? 'outline' : 'default' }}"
			[routerLink]="[item.url?.path || item.path]"
			htm-button
			[attr.data-test-id]="'sidebar-actions-button-' + (item?.url?.path || item?.path | slugify)"
		>
			{{ item.title | translate }}
		</button>
		<button
			*ngIf="!icons && (item.url?.routed || item.routed) && item.login && isLoggedIn"
			htm-button
			role="link"
			theme="{{ item.color && item.color === 'white' ? 'outline' : 'default' }}"
			[routerLink]="[item.url?.path || item.path]"
			[attr.data-test-id]="'sidebar-actions-button-' + (item?.url?.path || item?.path | slugify)"
		>
			{{ item.title | translate }}
		</button>
		<button
			*ngIf="!icons && (item.url?.routed || item.routed) && item.login && !isLoggedIn"
			htm-button
			role="link"
			theme="{{ item.color && item.color === 'white' ? 'outline' : 'default' }}"
			(click)="logIn(item.url?.path || item.path)"
			[attr.data-test-id]="'sidebar-actions-button-' + (item?.url?.path || item?.path | slugify)"
		>
			{{ item.title | translate }}
		</button>
		<a
			*ngIf="!icons && !(item.url?.routed || item.routed)"
			[href]="[item.url?.path || item.path]"
			[attr.data-test-id]="'sidebar-actions-link-' + (item?.url?.path || item?.path | slugify)"
			><button role="link" htm-button>{{ item.title | translate }}</button></a
		>
	</li>
</ul>
