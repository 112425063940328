<htm-swiper
	[config]="config"
	*ngIf="images?.length && config"
	[class.swiper-single-item]="images?.length === 1"
>
	<a
		href="javascript:void(0)"
		attr.aria-label="{{ 'gallery.link-title' | translate }}"
		*ngIf="images.length > 1 && firstImageLoaded"
		class="expand-gallery"
		(click)="openGallery()"
		data-test-id="gallery-open-link"
	>
		{{ 'gallery.link-title' | translate }} ({{ images.length }})
	</a>

	<button
		htm-button
		theme="icon"
		class="slides__arrow slides__arrow--left"
		aria-label="Previous slide"
		data-test-id="gallery-previous-slide-button"
	>
		<icon
			glyph="chevron-left"
			size="large"
			[bg]="true"
			aria-hidden="true"
		></icon>
	</button>

	<button
		htm-button
		theme="icon"
		class="slides__arrow slides__arrow--right"
		aria-label="Next slide"
		data-test-id="gallery-next-slide-button"
	>
		<icon
			glyph="chevron-right"
			size="large"
			[bg]="true"
			aria-hidden="true"
		></icon>
	</button>

	<div
		class="swiper-wrapper"
		aria-roledescription="carousel"
		aria-live="polite"
	>
		<ng-container *ngFor="let image of images; let i = index; let l = count">
			<div
				class="swiper-slide slides__instance"
				role="group"
				aria-roledescription="slide"
				[attr.aria-label]="'slide ' + (i + 1) + ' of ' + l"
			>
				<img
					*ngIf="!image.videoId"
					[src]="image.derivative?.url || activeImage.url"
					[title]="image.title"
					[alt]="image.alt"
					(load)="coverImageLoaded()"
				/>
				<htm-video
					*ngIf="image.videoId"
					[untabbable]="activeIndex !== i"
					[videos]="image"
					(onLoad)="coverImageLoaded()"
				></htm-video>
				<div
					class="image__title"
					*ngIf="image?.videoDescription || image?.title"
				>
					{{ image.videoDescription || image.title }}
				</div>
			</div>
		</ng-container>
	</div>
	<div class="swiper-pagination"></div>
</htm-swiper>

<htm-modal [id]="_id" [bottomAction]="false" [blockModal]="false">
	<ng-template>
		<div
			class="gallery-container"
			attr.aria-label="{{ 'gallery.title' | translate }}"
		>
			<div class="navigation-prev flex">
				<button
					attr.aria-label="{{ 'gallery.prev' | translate }}"
					htm-button
					theme="icon"
					(click)="handlePrev(activeImage)"
					data-test-id="gallery-modal-previous-slide-button"
				>
					<icon glyph="chevron-left" size="large" [bg]="true"></icon>
				</button>
			</div>
			<div class="gallery__items">
				<div class="gallery__items__index">
					{{ activeImage?.index + 1 }} / {{ images?.length }}
				</div>
				<div class="gallery__items__picture-container">
					<div class="gallery__items__active-image">
						<div class="navigation-prev flex">
							<button
								attr.aria-label="{{ 'gallery.prev' | translate }}"
								htm-button
								theme="icon"
								(click)="handlePrev(activeImage)"
								data-test-id="gallery-modal-active-image-previous-slide-button"
							>
								<icon glyph="chevron-left" size="small" [bg]="true"></icon>
							</button>
						</div>
						<ng-container *ngIf="!activeImage?.videoId">
							<div
								class="gallery__items__active-image__title"
								*ngIf="activeImage?.title"
							>
								{{ activeImage?.title }}
							</div>
							<img
								[src]="activeImage?.derivative?.url || activeImage.url"
								[title]="activeImage?.title ? activeImage.title : ''"
								[alt]="activeImage?.alt"
							/>
						</ng-container>
						<htm-video
							*ngIf="activeImage?.videoId"
							[videos]="activeImage"
							[responsive]="true"
						></htm-video>
						<div class="navigation-next flex">
							<button
								attr.aria-label="{{ 'gallery.next' | translate }}"
								htm-button
								theme="icon"
								(click)="handleNext(activeImage)"
								data-test-id="gallery-modal-active-image-next-slide-button"
							>
								<icon glyph="chevron-right" size="small" [bg]="true"></icon>
							</button>
						</div>
					</div>
					<div class="gallery__items__navigation">
						<ng-container *ngFor="let image of images">
							<a
								href="javascript:void(0)"
								(keyup)="handlePreviewEnter($event, image)"
								attr.aria-current="{{ (!image.videoId && activeImage.derivative?.url === image.derivative?.url)
                  || (image.videoId && activeImage.input === image.input) ? true : false }}"
								[attr.data-test-id]="'gallery-modal-image-preview-' + (image?.title || image?.videoId || image?.url)"
							>
								<img
									*ngIf="!image.videoId"
									class="{{
										(activeImage.derivative
											? activeImage.derivative?.url === image.derivative?.url
											: image.url === activeImage.url) ? 'active' : '' }}"
									[src]="image.derivative?.url || image.url"
									[alt]="image.alt"
									[title]="image.title || ''"
									(click)="activeImage = image"
								/>
								<img
									*ngIf="image.videoId"
									class="{{ activeImage.input === image.input ? 'active' : '' }}"
									[src]="image.videoThumbnail"
									[alt]="image.videoDescription || ''"
									[title]="image.title || ''"
									(click)="activeImage = image"
								/>
							</a>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="navigation-next flex">
				<button
					attr.aria-label="{{ 'gallery.next' | translate }}"
					htm-button
					theme="icon"
					(click)="handleNext(activeImage)"
					data-test-id="gallery-modal-next-slide-button"
				>
					<icon glyph="chevron-right" size="large" [bg]="true"></icon>
				</button>
			</div>
		</div>
	</ng-template>
</htm-modal>
