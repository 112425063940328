import { HttpClient } from '@angular/common/http';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	Notification,
	NotificationsResponse,
} from '@app/components/header/header-notifications/notifications.model';
import {
	BehaviorSubject,
	Observable,
	catchError,
	combineLatestWith,
	distinctUntilChanged,
	map,
	of,
	tap
} from 'rxjs';
import { AuthService } from './AuthService';
import { SettingsService } from './SettingsService';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
	private readonly unreadUrl = `${this.settingsService.ehisUrl}/messages/messages/receiver/unreadmessagecount`;
	private readonly notificationsUrl = `${this.settingsService.ehisUrl}/messages/messages/receiver?orderby=sentAt&sort=DESC`;
	private readonly notificationUrl = `${this.settingsService.ehisUrl}/messages/messages/receiver/`;
	private readonly notificationDeleteUrl = `${this.settingsService.ehisUrl}/messages/messages/receiver/messageAddressee/`;
	public unreadMessagesCount = new BehaviorSubject(0);
	public notifications = new BehaviorSubject<NotificationsResponse>({
		messages: [],
	} as NotificationsResponse);

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private settingsService: SettingsService,
		private destroyRef: DestroyRef
	) {
		// Fetch only if authed and has ehis token, clear if not authed
		this.authService.isAuthenticated
			.pipe(
				combineLatestWith(this.authService.hasEhisToken),
				// Custom comparison due to it being an array
				distinctUntilChanged(
					(prev, curr) => prev?.[0] === curr?.[0] && prev?.[1] === curr?.[1]
				),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(([isAuthed, hasEhisToken]) => {
				if (isAuthed) {
					if (hasEhisToken) {
							this.fetchUnread();
							this.fetchNotifications();
					}
				} else {
					this.clearData();
				}
			});
	}

	private clearData(): void {
		this.unreadMessagesCount.next(0);
		this.notifications.next({ messages: [] } as NotificationsResponse);
	}

	/**
	 * @returns void
	 * @description Fetch and set unread count, used for init / updates
	 */
	public fetchUnread(): void {
		this.getUnread().subscribe((number) => {
			this.unreadMessagesCount.next(number ?? 0);
		});
	}

	/**
	 * @returns void
	 * @description Fetch and set notifications without params, used for init / updates
	 */
	public fetchNotifications(): void {
		this.getNotifications({}).subscribe((val: NotificationsResponse) => {
			if (val.messages) {
				this.notifications.next(val);
			}
		});
	}

	/**
	 *
	 * @returns Observable that resolves once into a number, 0 if something went wrong
	 */
	public getUnread(): Observable<number> {
		return this.http.get(this.unreadUrl).pipe(
			catchError(() => of({ UnreadMessagesQty: 0 })),
			map(
				(response: { UnreadMessagesQty?: number }) =>
					response?.UnreadMessagesQty
			),
			tap((number) => {
				if (number !== undefined && this.unreadMessagesCount.value === 0) {
					this.unreadMessagesCount.next(number);
				}
			})
		);
	}

	/**
	 * @param params object of key-value pairs of any params you'd like to include in the request
	 * @returns Observable that resolves once into notifications response, empty object if something went wrong
	 */
	public getNotifications(
		params: {
			[key: string]: string;
		} = {}
	): Observable<NotificationsResponse> {
		const queryParamsString = Object.entries(params ?? {})
			?.map(([key, val]) => `${key}=${val}`)
			?.join('&')
			?.trim();

		return this.http
			.get(
				`${this.notificationsUrl}${
					queryParamsString ? '&' + queryParamsString : ''
				}`
			)
			.pipe(
				catchError(() => of({})),
				map((response: NotificationsResponse) => response),
				tap((response) => {
					if (
						!queryParamsString &&
						!this.notifications.value?.messages?.length
					) {
						this.notifications.next(response);
					}
				})
			);
	}

	/**
	 *
	 * @param id Notification id
	 * @returns Observable that resolves into the notification data once, empty object if something went wrong
	 */
	public getNotification(id: string): Observable<Notification> {
		return this.http.get(`${this.notificationUrl}${id}`).pipe(
			catchError(() => of({})),
			map((response: Notification) => response)
		);
	}

	public deleteNotification(id: string): Observable<string> {
		return this.http
			.delete(`${this.notificationDeleteUrl}${id}`, { responseType: 'text' })
			.pipe(
				catchError(() => of()),
				map((response) => response)
			);
	}
}
