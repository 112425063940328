import { Pipe, PipeTransform } from '@angular/core';
import { monthsToYears } from '@app/_core/utility';
import { TranslateService } from '@app/_modules/translate/translate.service';

@Pipe({
  name: 'monthsToYears',
})

export class MonthsToYearsPipe implements PipeTransform {

  constructor(
    private translate: TranslateService,
  ) {}

  transform(value: number): any {
    return monthsToYears(value, this.translate);
  }
}
