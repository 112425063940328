<div class="formItem__title"
  [class.formItem__title--top]="filledField || focused"
  [class.focused]="focused"
  *ngIf="!titleDisabled && title !== '' && type !== 'radio'">
  <span *ngIf="type !== 'select' && type !== 'multi-select'">{{ title | titleCase }}</span>
  <span role="text" [attr.aria-label]="ariaLabel + ' list box, click to open list of options'"
    *ngIf="type === 'select' || type === 'multi-select'">
    {{ title | titleCase }}
  </span> 
</div>

<div class="formItem__staticTitle" *ngIf="type == 'radio' && title !== ''">
  {{ title | titleCase }}
</div>

<div class="formItem__wrapper"
  [class.showPlaceholder]="(type == 'select' || type == 'multi-select') && placeholder && (field == '' || field == null)"
  [class.title--top]="filledField || focused"
  >

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'text'">
      <input
        [class.hideText]="!filledField && !focused"
        [attr.placeholder]="titleDisabled ? placeholder : ''"
        [class.focused]="focused"
        #inputField
        pattern="{{ pattern ? patterns[pattern] : '' }}"
        type="text"
        [autocomplete]="browserAutocomplete"
        (change)="update()"
        (focus)="update('focus')"
        (blur)="update('blur')"
        (keyup)="triggerOnUpdate()"
        (keydown)="keyDownFn($event)"
        [attr.disabled]="disabled"
        [attr.aria-label]="ariaLabel"
        [id]="domID"
        [attr.name]="domID"
        [attr.data-test-id]="testId || domID"
        [(ngModel)]="field" />
    </ng-container>

    <ng-container *ngSwitchCase="'password'">
      <input
        [class.hideText]="!filledField && !focused"
        [attr.placeholder]="titleDisabled ? placeholder : ''"
        [class.focused]="focused"
        #inputField
        pattern="{{ pattern ? patterns[pattern] : '' }}"
        type="password"
        autocomplete="current-password"
        (change)="update()"
        (focus)="update('focus')"
        (blur)="update('blur')"
        (keyup)="triggerOnUpdate()"
        [attr.aria-label]="ariaLabel"
        [attr.disabled]="disabled"
        [attr.data-test-id]="testId || domID"
        [(ngModel)]="field" />
    </ng-container>
    <ng-container *ngSwitchCase="'number'">
      <input
        [class.hideText]="!filledField"
        [attr.placeholder]="titleDisabled ? placeholder : ''"
        [class.focused]="focused"
        #inputField
        pattern="{{ pattern ? patterns[pattern] : '' }}"
        type="number"
        (change)="update()"
        (focus)="update('focus')"
        (blur)="update('blur')"
        (keyup)="triggerOnUpdate()"
				(keydown)="keyDownFn($event)"
        [attr.disabled]="disabled"
        [attr.aria-label]="ariaLabel"
        [attr.data-test-id]="testId || domID"
        [(ngModel)]="field" />
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <textarea
        [class.hideText]="!filledField"
        [attr.placeholder]="titleDisabled ? placeholder : ''"
        [class.focused]="focused"
        #inputField
        type="text"
        (change)="update()"
        (focus)="update('focus')"
        (blur)="update('blur')"
        (keyup)="triggerOnUpdate()"
        [style.height]="height ? height+'px' : null"
        [attr.disabled]="disabled"
        [attr.data-test-id]="testId || domID"
        [(ngModel)]="field"></textarea>
    </ng-container>

    <ng-container *ngSwitchCase="'multi-select'">
      <ng-select
        [items]="sortOptions ? (options | localeOrderBy:'key') : options"
        [multiple]="true"
        bindLabel="key"
        [closeOnSelect]="false"
        [placeholder]="placeholder"
        bindValue="value"
        (change)="update()"
        (open)="update('focus')"
        (close)="update('blur')"
        [searchable]="false"
        [appendTo]="appendTo"
        [disabled]="disabled"
        (keydown)="keydownEvent($event)"
        [keyDownFn]="keydownEvent"
        *ngIf="options"
        [(ngModel)]="field"
        [attr.aria-label]="ariaLabel"
        [attr.aria-multiselectable]="true"
        [attr.data-test-id]="testId || domID"
        >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <formItem type="checkbox" [label]="item.key" [ngModel]="item$.selected" [testId]="(testId || domID) + '-' + item.key"></formItem>
        </ng-template>
      </ng-select>

    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <ng-select
        [items]="sortOptions ? (options | localeOrderBy:'key') : options"
        bindLabel="key"
        bindValue="value"
        (change)="update()"
        (open)="update('focus')"
        (close)="update('blur')"
        [searchable]="false"
        [appendTo]="appendTo"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [(ngModel)]="field"
        [attr.aria-label]="ariaLabel"
        [attr.data-test-id]="testId || domID"
      >
      </ng-select>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <label class="formItem__check">
        <div class="formItem__check__wrapper">
          <input
          type="checkbox"
          [(ngModel)]="field"
          (change)="update()"
          (focus)="update('focus')"
          [attr.disabled]="disabled"
          (blur)="update('blur')"
          [attr.data-test-id]="testId || domID"
          />
          <div class="formItem__check__indicator" aria-hidden="true">
            <icon glyph="check"></icon>
          </div><!--/formItem__check__indicator-->
        </div><!--/formItem__check__wrapper-->
        <div class="formItem__check__title">
          {{ label }}
        </div><!--/formItem__check__title-->
      </label><!--/formItem__check-->
    </ng-container>

    <ng-container *ngSwitchCase="'radio'">
      <ng-container *ngFor="let item of options">
        <label class="formItem__radio">
          <div class="formItem__radio__wrapper">
            <input
            type="radio"
            [name]="name"
            [value]="item.value"
            [(ngModel)]="field"
            (change)="update()"
            (focus)="update('focus')"
            [attr.disabled]="disabled"
            (blur)="update('blur')"
            [attr.aria-label]="ariaLabel"
            [attr.data-test-id]="(testId || domID || 'input-radio') + '-' + item?.value"
            />
            <div class="formItem__radio__indicator"></div>
          </div><!--/formItem__check__wrapper-->
          <div class="formItem__radio__title"  [class.selected]="item.value === field">
            {{ item?.key || (item?.title | getByCurrentLang) }}
            <tooltip *ngIf="item.info" [content]="item?.info"></tooltip>
          </div><!--/formItem__check__title-->
        </label><!--/formItem__check-->
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'autocomplete'">
      <input
        [class.hideText]="!filledField && !focused"
        [attr.placeholder]="titleDisabled ? placeholder : ''"
        [class.focused]="focused"
        #inputField
        [attr.pattern]="pattern ? patterns?.[pattern] : undefined"
        type="text"
				autocomplete="off"
				[id]="domID"
        (change)="update()"
        (focus)="update('focus')"
        (blur)="update('blur', autocompleteElem);"
        (keyup)="autocompleteElem.search(field, $event); triggerOnUpdate();"
        (ngModelChange)="field = $event"
        [ngModel]="field | parseInAdds"
        [attr.aria-label]="ariaLabel"
        [attr.aria-activedescendant]="autocompleteElem.activeItem > -1 ? 'option-' + autocompleteElem.activeItem : undefined"
        [attr.aria-expanded]="autocompleteElem.active"
        aria-autocomplete="list"
        aria-haspopup="listbox"
        aria-owns="autocompleteResults"
        [attr.data-test-id]="testId || domID"
        role="combobox"
        />
      <autocomplete
        [valueType]="valueType"
        [queryType]="queryType"
        [queryField]="queryField"
        #autocompleteElem
        [type]="query"
        [inaadressFeatures]="inaadressFeatures"
        (onValueSelected)="autocompleteUpdate($event)">
      </autocomplete>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <select
        [class.hideText]="!filledField"
        [class.focused]="focused"
        #inputField
        type="text"
        (change)="update()"
        (focus)="update('focus')"
        (blur)="update('blur')"
        [attr.disabled]="disabled"
        [(ngModel)]="field"
        [attr.aria-label]="ariaLabel">
        <option value="" *ngIf="placeholder">{{ placeholder }}</option>
        <option value="0">Yki</option>
        <option value="1">Kaki</option>
      </select>
    </ng-container>

  </ng-container>
</div><!--/formItem__wrapper-->
<div class="formItem__error" *ngIf="error && errorMessage" role="alert" [attr.aria-label]="errorMessage">
  {{ errorMessage }}
</div>
