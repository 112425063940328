<div [ngClass]="containerClasses">

  <div
  class="input-title"
  [class.input-title--top]="filledField || isFocused"
  [class.input-title--focused]="isFocused"
  *ngIf="title">
  <span>{{ title }}</span>
</div>

<div class="input-wrapper">
  <ng-content></ng-content>
</div>

<div
class="input-wrapper__error"
*ngIf="error && errorMessage"
role="alert"
[attr.aria-label]="errorMessage"
>
{{ errorMessage }}
</div>

</div>