import { Injectable, signal } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FooterService {
	public logoTemplate = signal(null);
	public additionalFooterLinks = signal<
		{
			link: string;
			routed: string;
			title: string;
		}[]
	>([]);

	public clearLogoTemplate(): void {
		this.logoTemplate.set(null);
	}
}
