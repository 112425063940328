import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	Attribute,
	ChangeDetectionStrategy,
	Component,
	Input,
	HostBinding,
	input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'icon',
	templateUrl: 'icon.template.html',
	styleUrls: ['./icon.styles.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatIconModule, NgClass, NgTemplateOutlet],
})
export class IconComponent {
	glyph = input('star');
	size = input('default');
	ariaLabel = input('');
	bg = input<boolean>();
  noFill = input<boolean>();

	@HostBinding('class') get hostClass(): string {
		/**
		 * Add background and size classes based on need
		 */
		const extraClasses = [
			this.bg() && 'icon__wrapper--bg',
			this.size() && `icon__wrapper--${this.size()}`,
		]
			?.filter(Boolean)
			?.join(' ');
		return `icon__wrapper ${extraClasses}`;
	}
	constructor(@Attribute('aria-hidden') protected ariaHidden: string) {}
}
