import { Injectable } from '@angular/core';
import { OlMapComponent } from '@app/modules/olmap/components/ol-map/ol-map.component';
import conf from '@app/_core/conf';
import { EuroCurrencyPipe } from '@app/_pipes/euroCurrency.pipe';
import { LocaleNumberPipe } from '@app/_pipes/localeNumber';
import { Feature } from 'ol';
import { boundingExtent } from 'ol/extent';
import { toLonLat } from 'ol/proj';
import { Fill, Stroke, Style } from 'ol/style';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MapService {
  public activeFontSize: string = '';
  public fontSizes: Object = {
    sm: '13px',
    md: '18px',
    lg: '22px',
  };
  public activeMap: OlMapComponent;
  public latlngBounds: any;
  public polygonLayer: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public previousPolygonLayer: number;
  public infoLayer: {
    latitude?: number;
    longitude?: number;
    status?: boolean;
    title?: string;
    currency?: string;
    value?: string;
  } = {};
  public polygonValueLabels: {} = {};
  public polygonColors: {} = {};
  public fieldMaxRanges: Object = {};
  private labelOptions = {
    lightColor: 'white',
    color: 'black',
    fontSize: '13px',
    fontWeight: 'normal',
  };

  /**
   * Generates heat map
   * @param type - type of map: investment, oskaFields
   * @param data - Map data from API
   * @returns - parsed map data
   */
  generateHeatMap (type, data) {
    let maxSum = 0;
    const sumArray = [];
    const fieldSums = {};
    switch (type) {
      case 'investment':
        data.forEach((item) => {
          if (item.investmentAmountSum > maxSum) {
            maxSum = item.investmentAmountSum;
          }
        });
        if (maxSum === 0) { maxSum = 1000000; }
        const sumPartial = maxSum / conf.defaultPolygonColors.length;
        conf.defaultPolygonColors.forEach((item, index) => {
          const multiplier:number = index + 1;
          const tmpArray = {
            maxAmount: multiplier * sumPartial,
          };
          if (multiplier !== 1) {
            tmpArray['minAmount'] = index * sumPartial;
          } else {
            tmpArray['minAmount'] = 1000;
          }
          tmpArray['color'] = item;
          sumArray.push(tmpArray);
        });
        return sumArray;
      case 'oskaFields':
        data.forEach((item) => {
          if (item['division'] > maxSum) {
            maxSum = item['division'];
          }
          if (item['division'] > fieldSums[item.mapIndicator] || (!fieldSums[item.mapIndicator])) {
            fieldSums[item.mapIndicator] = item['division'];
          }
        });
        conf.defaultPolygonColors.forEach((item, index) => {
          const tmpArray = {
            amount: index + 1,
            color: item,
          };
          sumArray.push(tmpArray);
        });
        this.fieldMaxRanges = fieldSums;
        return sumArray;
      default:
        return null;
    }
  }

  /**
   * Maps polygon data
   * @param type - polygon map type: investment, oskaFields
   * @param polygonData - data from API
   * @param requestData - data from API
   * @param heatmap - heatmap from MapService
   * @returns - parsed data
   */
  mapPolygonData (type, polygonData, requestData, heatmap) {
    if (type === 'investment') {
			this.polygonValueLabels = {};
			this.polygonColors = {};
			for (const current of polygonData['features']) {
				const properties = current['properties'];
				const name = properties['NIMI'].toLowerCase();
				const shortName = properties['NIMI_LUHIKE']
					? properties['NIMI_LUHIKE'].toLowerCase()
					: '';
				let match: any = false;
				for (const dataField of requestData) {
					if (
						name === dataField.investmentLocation.toLowerCase() ||
						shortName === dataField.investmentLocation.toLowerCase()
					) {
						match = dataField;
					}
				}
				let color = heatmap[0];
				for (const o in heatmap) {
					if (!match.investmentAmountSum) {
						color = '#cfcfcf';
					} else if (
						match.investmentAmountSum >= heatmap[o]['minAmount'] &&
						match.investmentAmountSum <= heatmap[o]['maxAmount']
					) {
						color = heatmap[o]['color'];
						properties['investmentAmountSum'] = this.polygonValueLabels[
							properties['NIMI_LUHIKE']
						] = match.investmentAmountSum;
						this.polygonColors[properties['NIMI_LUHIKE']] = parseInt(o, 10) + 1;
						break;
					}
				}
				properties['color'] = color;
			}
		} else if (type === 'oskaFields') {
			this.polygonValueLabels = {};
			this.polygonColors = {};
			for (const current of polygonData['features']) {
				const properties = current['properties'];
				const shortName = properties['NIMI_LUHIKE']
					? properties['NIMI_LUHIKE'].toLowerCase()
					: '';
				let match: any = false;
				for (const requestDataField of requestData) {
					if (shortName === requestDataField.county.toLowerCase()) {
						match = requestDataField;
						this.polygonValueLabels[properties['NIMI_LUHIKE']] =
							match.investmentAmountSum;
					}
				}
				let color = heatmap[0];
				for (const o in heatmap) {
					if (!match.division) {
						color = '#cfcfcf';
						properties['value'] = 'Puudub';
					} else if (match.division === heatmap[o]['amount']) {
						color = heatmap[o]['color'];
						properties['value'] = this.polygonValueLabels[
							properties['NIMI_LUHIKE']
						] = match.value;
						this.polygonColors[properties['NIMI_LUHIKE']] = parseInt(o, 10) + 1;
						break;
					}
				}
				properties['color'] = color;
			}
		}
    return polygonData;
  }

  /**
   * Maps polygon labels
   * @param polygons - polygon data from MapService
   * @param extraLabels - should add extra labels?
   * @param polygonType - investment or empty
   * @returns - parsed polygon data
   */
  mapPolygonLabels (polygons, extraLabels, polygonType) {
    if (polygons?.['features']) {
      const extraLabelArr = [];
      const polygonMarkers = polygons['features'].map((elem) => {
        const current = elem.properties['NIMI_LUHIKE'];
        const color = this.polygonColors[current] === 7
          ? this.labelOptions.lightColor : this.labelOptions.color;
        const fontSize = this.activeFontSize || this.labelOptions.fontSize;
        const fontWeight = this.labelOptions.fontWeight;
        if (extraLabels && elem.geometry.center) {
          let text =
            this.polygonValueLabels[current] && !((typeof this.polygonValueLabels[current] === 'string') && this.polygonValueLabels[current].includes('%'))
            ? `${new LocaleNumberPipe().transform(this.polygonValueLabels[current])}`
            : this.polygonValueLabels[current];
          if (polygonType === 'investment') {
            text = this.polygonValueLabels[current] ?
              new EuroCurrencyPipe().transform(this.polygonValueLabels[current]) : '';
          }
          let latitude = elem.geometry.center.latitudeSm;
          if (this.activeFontSize === this.fontSizes['md']) {
            latitude = elem.geometry.center.latitudeMd;
          } else if (this.activeFontSize === this.fontSizes['lg']) {
            latitude = elem.geometry.center.latitudeLg;
          }
          extraLabelArr.push({
            latitude,
            longitude: elem.geometry.center ? elem.geometry.center.longitude : '',
            labelOptions: { color, fontSize, fontWeight, text },
          });
        }
        return {
          latitude: elem.geometry.center ? elem.geometry.center.latitude : '',
          longitude: elem.geometry.center ? elem.geometry.center.longitude : '',
          labelOptions: { color, fontSize, fontWeight, text: current || elem.properties['NIMI'] },
        };
      });
      return [...polygonMarkers, ...extraLabelArr];
    }
  }

  /**
   * Polygons styles
   * @param feature - polygon style data from API
   * @returns  - changed colors and values
   */
  polygonStyles(feature: Feature) {
    let color = '#cfcfcf';

    if (feature.get('color')) {
      color = feature.get('color');
    }

    return new Style({
      stroke: new Stroke({
        color: '#ffffff',
        width: 1,
      }),
      fill: new Fill({
        color: color,
      }),
    });
  }
  /**
   * Layers click event
   * @param $event - event handler
   * @param type - investment
   */
  layerClick($event, type) {
    if (!$event.feature) {
      this.infoLayer.status = false;
      return;
    };

    const pos = this.activeMap.map.getCoordinateFromPixel($event.mapBrowserEvent.pixel);
    const coordinate = toLonLat(pos, 'EPSG:3301');
    switch (type) {
      case 'investment':
        this.infoLayer = {
          latitude: coordinate[1],
          longitude: coordinate[0],
          status: true,
          title: $event.feature.get('NIMI_LUHIKE') || $event.feature.get('NIMI'),
          currency: $event.feature.get('investmentAmountSum') || '',
          value: $event.feature.get('value'),
        };
    }
  }

  /**
   * Sets map viewport bounds
   * @param markers - Array of markers objects
   */
  setBounds(markers: Object[]) {
    let hasBounds: boolean = false;
    if (markers) {
      let boundingCoordinates = [];

      markers.filter(elem => elem['Lat']).forEach((elem) => {
        hasBounds = true;
        boundingCoordinates.push([parseFloat(elem['Lon']), parseFloat(elem['Lat'])])
      });

      if (hasBounds) {
        const box = boundingExtent(boundingCoordinates);
        this.activeMap.fitBounds(box);
      }
    }
  }

  /**
   * Resets map center position
   */
  resetCenter() {
    if (this.activeMap) {
      this.activeMap.setCenter(conf.defaultMapOptions.center);
      this.activeMap.setZoom(conf.defaultMapOptions.zoom);
    }
  }

  /**
   * Groups investmend deadline years
   * @param data - data from API
   * @returns - grouped data as Array
   */
  groupYears(data) {
    const output = [];

    for (const i in data) {
      const unix = data[i].investmentDeadline;
      const date = new Date(unix);
      const year = date.getFullYear();

      if (output.indexOf(year) === -1) {
        output.push(year);
      }
    }
    return output;
  }

  /**
   * Parses info window marker data
   * @param data - API data array
   * @returns - parsed info window marker data
   */
  parseInfoWindowMarkerData(data: any): any {
    for (const i in data) {
      const current = data[i];
      const unix = new Date(current.investmentDeadline);
      current.year = unix.getFullYear();
    }
    return data;
  }
}
