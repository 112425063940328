import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageCodes, SettingsService } from '@app/_services';
import { BehaviorSubject, Subject } from 'rxjs';
import { findFromObj } from '@app/_core/utility';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) {
    this.settings.activeLang$.subscribe((lang: LanguageCodes) => this.handleLangChange(lang))
  }

  error: boolean = false;
  data: Object = {};
  // Exists purely to let subscribers update when needed
  translationsUpdated$ = new Subject<void>();
  translationsLoaded$ = new BehaviorSubject<boolean>(false);

  get(key:string = '', langCode: LanguageCodes = this.settings.currentAppLanguage) {
    let value = '';
    if (!this.data) {
      value = `${key}`;
    } else {
      value = findFromObj(this.data?.[langCode], key) || `${key}`;
    }
    return value;
  }

  load(langCode = this.settings.currentAppLanguage) {
    return new Promise((resolve) => {
      const langSuffix = langCode === LanguageCodes.ESTONIAN ? '' : `/${langCode}`
			this.http.get(`${this.settings.baseUrl}${langSuffix}/translations?_format=json`).subscribe({
        next: (response) => {
          this.data[langCode] = response;
          this.translationsLoaded$.next(true);
          resolve(true);
        },
        error: () => resolve(true),
      });
    });
  }

  handleLangChange(newLang:LanguageCodes) {
    const langSuffix = newLang === LanguageCodes.ESTONIAN ? '' : `/${newLang}`
    this.http.get(`${this.settings.baseUrl}${langSuffix}/translations?_format=json`)
      .subscribe({
        next: (response) => {
          this.data[newLang] = response;
        },
        complete: () => {
          this.translationsUpdated$.next();
        }
      });
  }

}
