<div *ngFor="let item of data.entities" class="article">
	<a *ngIf="item.path" [routerLink]="item.path" class="sidebar-article"
		[attr.data-test-id]="'sidebar-articles-link-' + (item?.path | slugify)"
		>
		<img
			*ngIf="(item.fieldIntroductionImage | arrayFirst) as Image"
			src="{{ Image.url }}"
			alt="{{ Image.alt }}"
			width="100%"
		/>
		<h2>{{ item.title }}</h2>
	</a>
	<time
		>{{ item.fieldAuthor }} {{ item.created*1000 | date:"dd.MM.yyyy" }}</time
	>
</div>
