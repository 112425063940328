import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'snakeCase',
})
export class SnakeCasePipe implements PipeTransform {
	/**
	 * Convert uppercase letters to lower case separated via underscore
	 */
	transform(input: string): string {
		if (!input) {
			return;
		}
		return input.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
	}
}
