export const dateFormat = 'DD.MM.YYYY';
export const isoDateFormat = 'YYYY-MM-DD';
export const isoDateFormatWithTime = 'YYYY-MM-DD HH:mm';
export const unpaddedDateFormat = 'D.M.YYYY';
export const monthYearFormat = 'MM.YYYY';
export const longMonthYearFormat = 'MMMM YYYY';
export const unpaddedDayLongMonthFormat = 'D.MMMM';
export const yearFormat = 'YYYY';

export const timeFormat = 'HH:mm';
export const emptyTimeString = '00:00';
