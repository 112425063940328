/**
 * https://api.haridusportaal.twn.zone/en/admin/config/search/path/patterns - siin saab tõlkeid vaadata
 */
export const routerDictionary = [
	['ametialad', 'professions'],																							// , 'sfery-dejatelnosti'
	['andmed', 'data'],																												// , 'dannye'
	['artiklid', 'articles'],																									// , 'statji'
	['digitempel', 'digital-tempel'],																					// , 'digitalnaja-podpis'
	['erialad', 'study-programmes'],																					// , 'uchebnye-programmy'
	['ettepanekute-elluviimine', 'proposals-implementation'], 								// , 'realizacija-predlozenij'
	['gdpr', 'gdpr'],																													// , 'gdpr'
	['digitempel', 'digital-temple'],																					// , 'digitalnaja-pechat'
	['haldusüksused', 'administrative-units'],																// , 'administrativnye-edinitsy'
	['infosüsteemid', 'infosystems'],																					// , 'infosistemy'
	['kaart', 'map'],																													// , 'karta'
	['kalender', 'calendar'], 																								// , 'kalendar'
	['karjäär', 'career'],																										// , 'karjera'
	['kool', 'school'],																												// , 'shkola'
	['koolide-rahastus', 'money-to-school'],																	// , 'finansirovanije-shkol'
	['kvalifikatsioonid', 'qualifications'],																	// , 'kvalifikatsii'
	['lõpudokumendid', 'final-documents'],																		// , 'dokumenty-ob-okonchanii'
	['lõpudokumentide-kehtivuse-kontroll', 'finishing-docs-validity-check'],	// , 'proverka-dejstvitelnosti-dokumentov-ob-okonchanii'
	['lõputunnistused' , 'finishing-certificates'], 													// , 'svidetelstva-ob-okonchanii'
	['noored', 'youth'], 																											// , 'molodjoz'
	['noorteseire', 'youth-monitoring'],																			// , 'monitoring-molodjozi'
	['noortevaldkond', 'youth'],																							// , 'molodjoznaja-sfera'
	['oska-tulemused', 'oska-results'],																				// , 'oska-rezultaty'
	['sündmused', 'events'],																									// , 'sobytija'
	['taotlused', 'applications'], 																						// , 'hodotajstva'
	['teavitused', 'notification'], 																					// , 'opovewenije'
	['tunnistuse-kehtivuse-kontroll', 'certificate-validity-check'],					// , 'proverka-dejstvitelnosti-svidetelstva'
	['tunnistused', 'certificates'],																					// , 'svidetelstva'
	['täiendkoolitus', 'in-service-training'],																// , 'dopolnitelnye-kursy'
	['tööjõuprognoos', 'survey-pages'],					 															// , 'prognoz-rabo4ej-sily'
	['töölaud', 'dashboard'], 																								// , 'rabo4ij-stol'
	['töötamine', 'working'],																									// , 'rabota'
	['uudised', 'news'],																											// , 'novosti'
	['uuringud', 'studies'],																									// , 'issledovanija'
	['valdkonnad', 'sectors'],																								// , 'sfery-dejatelnosti'
	['võrdlus', 'comparison'], 																								// , 'sravnenije'
	['õpetaja', 'teacher'],																										// , 'uchitel'
	['õpetamine', 'teaching'],																								// , 'obuchenije'
	['õpetan', 'educating'], 																									// , 'obuchenije'
	['õpingud', 'classes'], 																									// , 'zanjatija'
	['õppimine', 'learning'],																									// , 'uchjoba',
	['minu-andmed', 'my-data'],
	['vaata', 'view'],
	['minu-andmete-kasutajad', 'my-data-users'],
	['otsing', 'home-search-list'],
	['tasuta-kursused', 'free-courses'],
	['koolitused', 'trainings']
];
